import React from 'react'
import { styled } from 'styled-components'
import deleteIcon from '../../assets/buttonIcon/delete-button.svg'
import editIcon from '../../assets/buttonIcon/edit-button.svg'

const Button = ({ variant, children, onClick, disabled }) => {
  return (
    <div>
      {variant === 'edit-button' && (
        <IconButton onClick={onClick} src={editIcon} alt='none' />
      )}
      {variant === 'delete-button-icon' && (
        <IconButton onClick={onClick} src={deleteIcon} alt='none' />
      )}
      <Button1
        onClick={onClick}
        disabled={disabled}
        style={{ border: 'none', background: disabled && 'gray' }}
        variant={variant}
      >
        {children}
      </Button1>
    </div>
  )
}

export default Button

const IconButton = styled.img`
  width: 23px;
  cursor: pointer;
`

const buttonHandler = ({ variant }) => {
  return (
    variant === 'button1' &&
    'font-style: italic; width: 170px; height: 60px; border-radius: 20px; border: 1px solid #000; background: #0A0082; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); color: white;font-family: Inter;font-size: 20px;font-weight: 300;   '
  )
}

const button2Handler = ({ variant }) => {
  return (
    variant === 'show-button' &&
    'font-style: italic; width: 230px; height: 55px;border-radius: 20px;border: 1px solid #000;background: #0A0082;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);font-weight: 300;color: #FFF;font-family: Inter;font-size: 20px; margin-top:10px;'
  )
}

const button3Handler = ({ variant }) => {
  return (
    variant === 'add-faq' &&
    'font-style: italic; width: 295px; height: 60px;border-radius: 20px;border: 0.5px solid #000;background: #0A0082;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);font-weight: 300; color: #FFF; font-family: Inter; font-size: 20px;'
  )
}
const button4Handler = ({ variant }) => {
  return (
    variant === 'delete-button' &&
    'font-style: italic; width: 170px; height: 60px; border-radius: 20px; border: 1px solid #000;background: #F00;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);font-weight: 300; color: #FFF; font-family: Inter; font-size: 20px; margin-top:10px;'
  )
}
const button5Handler = ({ variant }) => {
  return (
    variant === 'button2' &&
    'font-style: italic; width: 210px; height: 45px; border-radius: 15px; border: 1px solid #000;background: #0A0082;box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);  color: #FFF; font-family: Inter; font-size: 20px; margin-top:-5px;'
  )
}
const button6Handler = ({ variant }) => {
  return (
    variant === 'login-button' &&
    ' width: 150px; height: 50px; font-style: italic; font-weight: 300; border-radius: 16px; border: 1px solid #000; background: #1400FF;box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);color: #FFF;font-size: 28px;font-style: normal;font-weight: 700;line-height: normal;  margin-top:10px; text-align:center;'
  )
}
// media
const button1MediaHandler = ({ variant }) => {
  return (
    variant === 'button1' &&
    'width: 125px; height: 42px; border-radius: 12px; font-size: 18px;'
  )
}
const button2MediaHandler = ({ variant }) => {
  return (
    variant === 'show-button' &&
    'width: 135px; height: 42px; border-radius: 12px; font-size: 18px;'
  )
}
const button3MediaHandler = ({ variant }) => {
  return (
    variant === 'add-faq' &&
    'width: 195px; height: 42px; border-radius: 12px; font-size: 14px;'
  )
}
const button4MediaHandler = ({ variant }) => {
  return (
    variant === 'delete-button' &&
    'width: 140px; height: 42px; border-radius: 12px; font-size: 18px;'
  )
}
const button5MediaHandler = ({ variant }) => {
  return (
    variant === 'button2' &&
    'width: 160px; height: 42px; font-size: 16px; border-radius: 12px'
  )
}
const button6MediaHandler = ({ variant }) => {
  return (
    variant === 'login-button' &&
    'width: 170px; height: 42px; border-radius: 16px; font-size: 24px;'
  )
}

const Button1 = styled.button`
  ${buttonHandler}
  ${button2Handler}
  ${button3Handler}
  ${button4Handler}
  ${button5Handler}
  ${button6Handler}
  cursor: pointer;
  @media (max-width: 1280px) {
    ${button1MediaHandler}
    ${button2MediaHandler}
    ${button3MediaHandler}
    ${button4MediaHandler}
    ${button5MediaHandler}
    ${button6MediaHandler}
  }
  @media (max-width: 1024px) {
    ${button1MediaHandler}
    ${button2MediaHandler}
    ${button3MediaHandler}
    ${button4MediaHandler}
    ${button5MediaHandler}
    ${button6MediaHandler}
  }
  @media (max-width: 912px) {
    ${button1MediaHandler}
    ${button2MediaHandler}
    ${button3MediaHandler}
    ${button4MediaHandler}
    ${button5MediaHandler}
    ${button6MediaHandler}
  }
  @media (max-width: 820px) {
    ${button1MediaHandler}
    ${button2MediaHandler}
    ${button3MediaHandler}
    ${button4MediaHandler}
    ${button5MediaHandler}
    ${button6MediaHandler}
  }
  @media (max-width: 768px) {
    ${button1MediaHandler}
    ${button2MediaHandler}
    ${button3MediaHandler}
    ${button4MediaHandler}
    ${button5MediaHandler}
    ${button6MediaHandler}
  }
  @media (max-width: 414px) {
    ${button1MediaHandler}
    ${button2MediaHandler}
    ${button3MediaHandler}
    ${button4MediaHandler}
    ${button5MediaHandler}
    ${button6MediaHandler}
  }
`
