export const kyrgyz = {
  header: {
    title: 'Кочкор-Ата шаардык мэриясынын расмий сайты',
  },
  sidebar: {
    main: 'Башкы бет',
    news: 'Жаңылыктар жана жарыя',
    faq: 'Көп берилүүчү суроолор',
    about: 'Мэрия жөнүндө',
    appeals: 'Жарандардын кайрылуулары',
    tourists: 'Туристтер үчүн',
    contacts: 'Байланыштар',
    calendar: 'Календар',
    municipal: 'Муниципалдык программалар',
  },
  buttons: {
    add: 'Кошуу',
    more: 'Дагы',
    appeals: 'Кайрылуулар',
    send: 'Жөнөтүү',
  },
  textcard: {
    hotels: 'Мейманканалар',
    attractions: 'Аттракциондор',
    cafes: 'Ресторандар жана кафелер',
  },
  table: {
    fullname: 'Аты жөнү',
    number: 'Номер',
    email: 'Почта',
    post: 'Кызмат',
  },
  errorpage: {
    title: 'Барак табылган жок',
    back: 'Мурунку бетке өтүү',
  },
  snackBar: {
    messageSaccess: 'Маалымат ийгиликтүү кошулду!',
    dataSentSaccess: 'Маалыматтар ийгиликтүү жөнөтүлдү!',
    photoAddSaccess: 'Сүрөт ийгиликтүү кошулду!',
    messageEditSaccess: 'Маалымат ийгиликтүү өзгөртүлдү!',
    photoEditSaccess: 'Cүрөт ийгиликтүү өзгөртүлдү!',
    messageDeleteSaccess: 'Маалымат ийгиликтүү өчүрүлдү!',
    photoDeleteSaccess: 'Cүрөт ийгиликтүү өчүрүлдү!',
    messageError: 'Ката кетти. Сураныч, кийинчерээк кайра аракет кылыңыз!',
  },
  dataNotFound: {
    dataNotFound: 'Учурда бул жерде эч нерсе жок.',
    newsNotFound: 'Бул жерде азырынча жаңылыктар жок.',
    contactsNotFound: 'Байланыш тизмеси бош.',
    touristsPhotoNotFound: 'Бул жерде азырынча сүрөттөр жок.',
  },
  modalContent: {
    deletedText: 'Чын эле жок кыласызбы?',
    deletedPhoto: 'Чын эле бул сүрөттү жок кылганы жатасызбы?',
    deletedFAQ: 'Бул суроону чын эле жок кылгыңыз келеби?',
    deletedDatas: 'Бул маалыматтарды чын эле жок кылгыңыз келеби?',
    deletedContact: 'Бул байланышты чын эле жок кылгыңыз келеби?',
    deletedEvent: 'Бул окуяны чын эле жок кылгыңыз келеби?',
    deleted: 'Жок кылуу',
    cancellation: 'Токтотуу',
  },
  footer: {
    info: 'Мамлекеттик сайттар',
    cityHall: 'Мэрия Жөнүндө',
    republic: 'Мамлекеттик сайт',
    contacts: 'Байланыштар',
    job: 'Жумуш орундары',
    network: 'Социалдык тармактар',
    security: '© 2023 Бардык укуктар корголгон',
  },
  descriptionDatas: {
    title: 'Аты:',
    description: 'Сүрөттөө:',
    date: 'Чыгарылган күнү көрсөтүлсүнбү?',
    save: 'Сактоо',
    add: 'Кошуу',
    more: 'Дагы',
    show: 'Көрсөтүү',
    price: 'күнүнө',
    places: 'Жайлар',
    wifi: {
      yes: 'Бар',
      no: 'Жок',
      both: 'Бар / Жок',
    },
    appeals: 'Кайрылуу',
    numberWhatsApp: 'WhatsApp Номери',
    complaint: 'Арызыңыздын сүрөттөлүшү',
    job_title: 'Кызматы:',
    name: 'Аты-жөнү',
    question: 'Суроо',
    answer: 'Сиздин жообуңуз',
    upload: {
      video: 'Видеону жүктөө',
      photo: 'Сүрөт Жүктөө',
      button: 'Жөнөтүү',
    },
    citizenSend: 'Жөнөтүү жана кошуу',
    citizenIgnor: 'Четке кагуу',
    archive: {
      title: 'Суроолор архиви',
      clear: 'Тазалоо',
    },
    userquestion: 'Кайрылуулар',
  },
}
