import { createSlice } from '@reduxjs/toolkit'
import {
  deleteAdminAbout,
  deleteAdminFaq,
  deleteAdminMain,
  deleteAdminTouristsMain,
  getAdminAbout,
  getAdminAppeals,
  getAdminFaq,
  getAdminMain,
  getAdminTouristsMain,
  getAdminNews,
  postAdminAbout,
  postAdminMain,
  postResponseAdminAppeals,
  postResponseAndAddToFaqAdminAppeals,
  putAdminAbout,
  putIgnoreAdminAppeals,
  postAdminTouristsMain,
  putAdminTouristsMain,
  getAdminTouristsAttractions,
  getAdminTouristsCafes,
  postAdminTouristsCafes,
  putAdminTouristsCafes,
  deleteAdminTouristsCafes,
  getAdminTouristsHotels,
  postAdminTouristsHotels,
  putAdminTouristsHotels,
  deleteAdminTouristsHotels,
  getAdminTouristsCafesById,
  getAdminTouristsHotelsById,
  getAdminTouristsAttractionsById,
  postAdminTouristsAttractions,
  putAdminTouristsAttractions,
  deleteAdminTouristsAttractions,
  getAdminMunicipalPrograms,
  postAdminMunicipalPrograms,
  putAdminMunicipalPrograms,
  getAdminContacts,
  getAdminCurrentContact,
  postAdminContacts,
  putAdminContacts,
  deleteAdminContacts,
  getAdminCalendar,
  getAdminCalendarEvent,
  postAdminCalendar,
  putAdminCalendar,
  deleteAdminCalendar,
  getAdminCurrentAppeal,
  getAdminAppealsArchive,
  deleteAdminAppealsArchive,
  putAdminMain,
  postAdminNews,
  putAdminNews,
  deleteAdminNews,
  deleteAdminMunicipalPrograms,
} from './AdminAction'

const initialState = {
  // MAIN
  mainStatus: null,
  mainAddStatus: null,
  mainEditStatus: null,
  MainDeleteStatus: null,
  SnackState: false,
  main: [],
  // MAIN

  // NEWS
  newsStatus: null,
  newsAddStatus: null,
  newsEditStatus: null,
  newsDeleteStatus: null,
  newsSnackState: false,
  news: [],
  // NEWS

  // FAQ
  faqStatus: null,
  FAQDeleteStatus: null,
  FAQSnackState: false,
  faq: [],
  // FAQ

  // ABOUT OF CITY HALL
  aboutStatus: null,
  AboutAddStatus: null,
  AboutEditStatus: null,
  AboutDeleteStatus: null,
  AboutSnackState: false,
  about: [],
  // ABOUT OF CITY HALL

  // APPEALS OF CITIZENS
  appealsStatus: null,
  appealsSendStatus: null,
  appealsIgnoreStatus: null,
  appealsSendFAQStatus: null,
  appealsState: false,
  appeals: null,
  currentAppeal: null,
  appealsArchive: null,
  // APPEALS OF CITIZENS

  // FOR TOURISTS
  touristsStatus: null,
  touristsAddStatus: null,
  touristsEditStatus: null,
  touristsDeleteStatus: null,
  touristsSnackState: false,
  tourists: {
    vertical: [],
    horizontal: [],
  },

  // cafes ----------
  touristsCafesStatus: null,
  CafesAddStatus: null,
  CafesEditStatus: null,
  CafesDeleteStatus: null,
  CafesSnackState: false,
  touristsCafes: [],
  touristsCurrentCafeStatus: null,
  touristsCurrentCafe: [],
  // cafes ----------

  // hotels ---------
  touristsHotelsStatus: null,
  HotelAddStatus: null,
  HotelEditStatus: null,
  HotelDeleteStatus: null,
  HotelSnackState: false,
  touristsHotels: [],
  // current hotels
  touristsCurrentHotelStatus: null,
  touristsCurrentHotel: [],
  // hotels ---------

  // attractions ----
  touristsAttarctionsStatus: null,
  AttractionsAddStatus: null,
  AttractionsEditStatus: null,
  AttractionsDeleteStatus: null,
  AttractionsSnackState: false,
  touristsAttractions: [],
  touristsCurrentAttractionStatus: null,
  touristsCurrentAttraction: [],
  // attractions ----

  // FOR TOURISTS

  // Contacts
  contactsStatus: null,
  ContactsAddStatus: null,
  ContactsEditStatus: null,
  ContactsDeleteStatus: null,
  ContactsSnackState: false,
  contacts: [],
  curentContact: [],
  // Contacts

  // Calendar
  calendarStatus: null,
  CalendarAddStatus: null,
  CalendarEditStatus: null,
  CalendarDeleteStatus: null,
  CalendarSnackState: false,
  calendar: [],
  calendarEvent: [],
  // Calendar

  // Municipal programs
  municipalProgramsStatus: null,
  municipalProgramsAddStatus: null,
  municipalProgramsEditStatus: null,
  municipalProgramsDeleteStatus: null,
  municipalProgramsSnackState: false,
  municipalPrograms: [],
  // Municipal programs
}
export const AdminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // MainPage
    mainPageAddSnackBar(state, action) {
      state.SnackState = action.payload.SnackState
      state.mainAddStatus = action.payload.mainAddStatus
    },
    mainPageEditSnackBar(state, action) {
      state.SnackState = action.payload.SnackState
      state.mainEditStatus = action.payload.mainEditStatus
    },
    mainPageDeleteSnackBar(state, action) {
      state.SnackState = action.payload.SnackState
      state.MainDeleteStatus = action.payload.MainDeleteStatus
    },
    // MainPage
    // News
    newsAddSnackBar(state, action) {
      state.newsSnackState = action.payload.newsSnackState
      state.newsAddStatus = action.payload.newsAddStatus
    },
    newsEditSnackBar(state, action) {
      state.newsSnackState = action.payload.newsSnackState
      state.newsEditStatus = action.payload.newsEditStatus
    },
    newsDeleteSnackBar(state, action) {
      state.newsSnackState = action.payload.SnackState
      state.newsDeleteStatus = action.payload.newsDeleteStatus
    },
    // News
    // FAQ
    FAQDeleteSnackBar(state, action) {
      state.FAQSnackState = action.payload.FAQSnackState
      state.FAQDeleteStatus = action.payload.FAQDeleteStatus
    },
    // FAQ
    // about
    AboutAddSnackBar(state, action) {
      state.AboutSnackState = action.payload.AboutSnackState
      state.AboutAddStatus = action.payload.AboutAddStatus
    },
    AboutEditSnackBar(state, action) {
      state.AboutSnackState = action.payload.AboutSnackState
      state.AboutEditStatus = action.payload.AboutEditStatus
    },
    AboutDeleteSnackBar(state, action) {
      state.AboutSnackState = action.payload.AboutSnackState
      state.AboutDeleteStatus = action.payload.AboutDeleteStatus
    },
    // about
    // tourists
    touristsAddSnackBar(state, action) {
      state.touristsSnackState = action.payload.touristsSnackState
      state.touristsAddStatus = action.payload.touristsAddStatus
    },
    touristsEditSnackBar(state, action) {
      state.touristsSnackState = action.payload.touristsSnackState
      state.touristsEditStatus = action.payload.touristsEditStatus
    },
    touristsDeleteSnackBar(state, action) {
      state.touristsSnackState = action.payload.touristsSnackState
      state.touristsDeleteStatus = action.payload.touristsDeleteStatus
    },
    // tourist hotels
    HotelsAddSnackBar(state, action) {
      state.HotelSnackState = action.payload.HotelSnackState
      state.HotelAddStatus = action.payload.HotelAddStatus
    },
    HotelsEditSnackBar(state, action) {
      state.HotelSnackState = action.payload.HotelSnackState
      state.HotelEditStatus = action.payload.HotelEditStatus
    },
    HotelsDeleteSnackBar(state, action) {
      state.HotelSnackState = action.payload.HotelSnackState
      state.HotelDeleteStatus = action.payload.HotelDeleteStatus
    },
    // tourist hotels
    // tourist cafes
    CafesAddSnackBar(state, action) {
      state.CafesSnackState = action.payload.CafesSnackState
      state.CafesAddStatus = action.payload.CafesAddStatus
    },
    CafesEditSnackBar(state, action) {
      state.CafesSnackState = action.payload.CafesSnackState
      state.CafesEditStatus = action.payload.CafesEditStatus
    },
    CafesDeleteSnackBar(state, action) {
      state.CafesSnackState = action.payload.CafesSnackState
      state.CafesDeleteStatus = action.payload.CafesDeleteStatus
    },
    // tourist cafes
    // tourist attraction
    AttractionsAddSnackBar(state, action) {
      state.AttractionsSnackState = action.payload.AttractionsSnackState
      state.AttractionsAddStatus = action.payload.AttractionsAddStatus
    },
    AttractionsEditSnackBar(state, action) {
      state.AttractionsSnackState = action.payload.CAttractionsSnackState
      state.AttractionsEditStatus = action.payload.CAttractionsEditStatus
    },
    AttractionsDeleteSnackBar(state, action) {
      state.AttractionsSnackState = action.payload.CAttractionsSnackState
      state.AttractionsDeleteStatus = action.payload.CAttractionsDeleteStatus
    },
    // tourist attraction
    // tourists
    // contacts
    ContactsAddSnackBar(state, action) {
      state.ContactsSnackState = action.payload.ContactsSnackState
      state.ContactsAddStatus = action.payload.ContactsAddStatus
    },
    ContactsEditSnackBar(state, action) {
      state.ContactsSnackState = action.payload.ContactsSnackState
      state.ContactsEditStatus = action.payload.ContactsEditStatus
    },
    ContactsDeleteSnackBar(state, action) {
      state.ContactsSnackState = action.payload.ContactsSnackState
      state.ContactsDeleteStatus = action.payload.ContactsDeleteStatus
    },
    // contacts
    // Calendar
    CalendarAddSnackBar(state, action) {
      state.CalendarSnackState = action.payload.CalendarSnackState
      state.CalendarAddStatus = action.payload.CalendarAddStatus
    },
    CalendarEditSnackBar(state, action) {
      state.CalendarSnackState = action.payload.CalendarSnackState
      state.CalendarEditStatus = action.payload.CalendarEditStatus
    },
    CalendarDeleteSnackBar(state, action) {
      state.CalendarSnackState = action.payload.CalendarSnackState
      state.CalendarDeleteStatus = action.payload.CalendarDeleteStatus
    },
    // Calendar
    // programs
    ProgramsAddSnackBar(state, action) {
      state.municipalProgramsSnackState =
        action.payload.municipalProgramsSnackState
      state.municipalProgramsAddStatus =
        action.payload.municipalProgramsAddStatus
    },
    ProgramsEditSnackBar(state, action) {
      state.municipalProgramsSnackState =
        action.payload.municipalProgramsSnackState
      state.municipalProgramsEditStatus =
        action.payload.municipalProgramsEditStatus
    },
    ProgramsDeleteSnackBar(state, action) {
      state.municipalProgramsSnackState =
        action.payload.municipalProgramsSnackState
      state.municipalProgramsDeleteStatus =
        action.payload.municipalProgramsDeleteStatus
    },
    // programs
    // appeals
    AppealsSendSnackBar(state, action) {
      state.appealsState = action.payload.appealsState
      state.appealsSendStatus = action.payload.appealsSendStatus
    },
    AppealsIgnoreSnackBar(state, action) {
      state.appealsState = action.payload.appealsState
      state.appealsIgnoreStatus = action.payload.appealsIgnoreStatus
    },
    AppealsSendFAQSnackBar(state, action) {
      state.appealsState = action.payload.appealsState
      state.appealsSendFAQStatus = action.payload.appealsSendFAQStatus
    },
    // appeals
  },
  extraReducers: {
    // MAIN
    [getAdminMain.pending]: (state) => {
      state.mainStatus = 'pending'
    },
    [getAdminMain.fulfilled]: (state, action) => {
      state.mainStatus = 'success'
      state.main = action.payload?.mainPage
    },
    [getAdminMain.rejected]: (state) => {
      state.mainStatus = 'error'
    },
    // main Add
    [postAdminMain.pending]: (state) => {
      state.mainAddStatus = 'pending'
    },
    [postAdminMain.fulfilled]: (state) => {
      state.mainAddStatus = 'success'
      state.SnackState = true
    },
    [postAdminMain.rejected]: (state) => {
      state.mainAddStatus = 'error'
      state.SnackState = true
    },
    // main Add
    // main Edit
    [putAdminMain.pending]: (state) => {
      state.mainEditStatus = 'pending'
    },
    [putAdminMain.fulfilled]: (state) => {
      state.mainEditStatus = 'success'
      state.SnackState = true
    },
    [putAdminMain.rejected]: (state) => {
      state.mainEditStatus = 'error'
      state.SnackState = true
    },
    // main Edit
    // main delete
    [deleteAdminMain.pending]: (state) => {
      state.MainDeleteStatus = 'pending'
    },
    [deleteAdminMain.fulfilled]: (state) => {
      state.MainDeleteStatus = 'success'
      state.SnackState = true
    },
    [deleteAdminMain.rejected]: (state) => {
      state.MainDeleteStatus = 'error'
      state.SnackState = true
    },
    // main delete
    // MAIN

    // NEWS
    [getAdminNews.rejected]: (state) => {
      state.newsStatus = 'error'
    },
    [getAdminNews.pending]: (state) => {
      state.newsStatus = 'pending'
    },
    [getAdminNews.fulfilled]: (state, action) => {
      state.newsStatus = 'success'
      state.news = action.payload?.adminNews
    },
    // news Add
    [postAdminNews.pending]: (state) => {
      state.newsAddStatus = 'pending'
    },
    [postAdminNews.fulfilled]: (state) => {
      state.newsAddStatus = 'success'
      state.newsSnackState = true
    },
    [postAdminNews.rejected]: (state) => {
      state.newsAddStatus = 'error'
      state.newsSnackState = true
    },
    // news Add
    // news Edit
    [putAdminNews.pending]: (state) => {
      state.newsEditStatus = 'pending'
    },
    [putAdminNews.fulfilled]: (state) => {
      state.newsEditStatus = 'success'
      state.newsSnackState = true
    },
    [putAdminNews.rejected]: (state) => {
      state.newsEditStatus = 'error'
      state.newsSnackState = true
    },
    // news Edit
    // news delete
    [deleteAdminNews.pending]: (state) => {
      state.newsDeleteStatus = 'pending'
    },
    [deleteAdminNews.fulfilled]: (state) => {
      state.newsDeleteStatus = 'success'
      state.newsSnackState = true
    },
    [deleteAdminNews.rejected]: (state) => {
      state.newsDeleteStatus = 'error'
      state.newsSnackState = true
    },
    // news delete
    // NEWS

    // FAQ
    [getAdminFaq.pending]: (state) => {
      state.faqStatus = 'pending'
    },
    [getAdminFaq.fulfilled]: (state, action) => {
      state.faqStatus = 'success'
      state.faq = action.payload?.faq
    },
    [getAdminFaq.rejected]: (state) => {
      state.faqStatus = 'error'
    },
    // FAQ delete
    [deleteAdminFaq.pending]: (state) => {
      state.newsDeleteStatus = 'pending'
    },
    [deleteAdminFaq.fulfilled]: (state) => {
      state.FAQDeleteStatus = 'success'
      state.FAQSnackState = true
    },
    [deleteAdminFaq.rejected]: (state) => {
      state.newsDeleteStatus = 'error'
      state.newsSnackState = true
    },
    // FAQ

    // ABOUT OF CITY HALL
    [getAdminAbout.pending]: (state) => {
      state.aboutStatus = 'pending'
    },
    [getAdminAbout.fulfilled]: (state, action) => {
      state.aboutStatus = 'success'
      state.about = action.payload?.about
    },
    [getAdminAbout.rejected]: (state) => {
      state.aboutStatus = 'error'
    },
    // about add
    [postAdminAbout.pending]: (state) => {
      state.AboutAddStatus = 'pending'
    },
    [postAdminAbout.fulfilled]: (state) => {
      state.AboutAddStatus = 'success'
      state.AboutSnackState = true
    },
    [postAdminAbout.rejected]: (state) => {
      state.AboutAddStatus = 'error'
      state.AboutSnackState = true
    },
    // about add
    // about Edit
    [putAdminAbout.pending]: (state) => {
      state.aboutEditStatus = 'pending'
    },
    [putAdminAbout.fulfilled]: (state) => {
      state.AboutEditStatus = 'success'
      state.AboutSnackState = true
    },
    [putAdminAbout.rejected]: (state) => {
      state.AboutEditStatus = 'error'
      state.AboutSnackState = true
    },
    // about Edit
    // about Delete
    [deleteAdminAbout.pending]: (state) => {
      state.AboutDeleteStatus = 'pending'
    },
    [deleteAdminAbout.fulfilled]: (state) => {
      state.AboutDeleteStatus = 'success'
      state.AboutSnackState = true
    },
    [deleteAdminAbout.rejected]: (state) => {
      state.AboutDeleteStatus = 'error'
      state.AboutSnackState = true
    },
    // About Delete
    // ABOUT OF CITY HALL

    // APPEALS OF CITIZENS
    [getAdminAppeals.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [getAdminAppeals.fulfilled]: (state, action) => {
      state.appealsStatus = 'success'
      state.appeals = action.payload?.appeals
    },
    [getAdminAppeals.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    [getAdminCurrentAppeal.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [getAdminCurrentAppeal.fulfilled]: (state, action) => {
      state.appealsStatus = 'success'
      state.currentAppeal = action.payload?.currentAppeal
    },
    [getAdminCurrentAppeal.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    [postResponseAdminAppeals.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [postResponseAdminAppeals.fulfilled]: (state) => {
      state.appealsStatus = 'success'
    },
    [postResponseAdminAppeals.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    [postResponseAndAddToFaqAdminAppeals.pending]: (state) => {
      state.appealsSendFAQStatus = 'pending'
    },
    [postResponseAndAddToFaqAdminAppeals.fulfilled]: (state) => {
      state.appealsSendFAQStatus = 'success'
      state.appealsState = true
    },
    [postResponseAndAddToFaqAdminAppeals.rejected]: (state) => {
      state.appealsSendFAQStatus = 'error'
      state.appealsState = true
    },
    [putIgnoreAdminAppeals.pending]: (state) => {
      state.appealsIgnoreStatus = 'pending'
    },
    [putIgnoreAdminAppeals.fulfilled]: (state) => {
      state.appealsIgnoreStatus = 'success'
      state.appealsState = true
    },
    [putIgnoreAdminAppeals.rejected]: (state) => {
      state.appealsIgnoreStatus = 'error'
      state.appealsState = true
    },
    [getAdminAppealsArchive.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [getAdminAppealsArchive.fulfilled]: (state, action) => {
      state.appealsStatus = 'success'
      state.appealsArchive = action.payload?.appealsArchive
    },
    [getAdminAppealsArchive.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    [deleteAdminAppealsArchive.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [deleteAdminAppealsArchive.fulfilled]: (state) => {
      state.appealsStatus = 'success'
    },
    [deleteAdminAppealsArchive.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    [getAdminAppealsArchive.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [getAdminAppealsArchive.fulfilled]: (state, action) => {
      state.appealsStatus = 'success'
      state.appealsArchive = action.payload?.appealsArchive
    },
    [getAdminAppealsArchive.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    [deleteAdminAppealsArchive.pending]: (state) => {
      state.appealsStatus = 'pending'
    },
    [deleteAdminAppealsArchive.fulfilled]: (state) => {
      state.appealsStatus = 'success'
    },
    [deleteAdminAppealsArchive.rejected]: (state) => {
      state.appealsStatus = 'error'
    },
    // APPEALS OF CITIZENS

    // FOR TOURISTS
    [getAdminTouristsMain.pending]: (state) => {
      state.touristsStatus = 'pending'
    },
    [getAdminTouristsMain.fulfilled]: (state, action) => {
      state.touristsStatus = 'success'
      state.tourists.vertical = action.payload?.vertical
      state.tourists.horizontal = action.payload?.horizontal
    },
    [getAdminTouristsMain.rejected]: (state) => {
      state.touristsStatus = 'error'
    },
    // tourists Add
    [postAdminTouristsMain.pending]: (state) => {
      state.touristsAddStatus = 'pending'
    },
    [postAdminTouristsMain.fulfilled]: (state) => {
      state.touristsAddStatus = 'success'
      state.touristsSnackState = true
    },
    [postAdminTouristsMain.rejected]: (state) => {
      state.touristsAddStatus = 'error'
      state.touristsSnackState = true
    },
    // tourists Add
    // tourists Edit
    [putAdminTouristsMain.pending]: (state) => {
      state.touristsEditStatus = 'pending'
    },
    [putAdminTouristsMain.fulfilled]: (state) => {
      state.touristsEditStatus = 'success'
      state.touristsSnackState = true
    },
    [putAdminTouristsMain.rejected]: (state) => {
      state.touristsEditStatus = 'error'
      state.touristsSnackState = true
    },
    // tourists Edit
    // tourists Delete
    [deleteAdminTouristsMain.pending]: (state) => {
      state.touristsDeleteStatus = 'pending'
    },
    [deleteAdminTouristsMain.fulfilled]: (state) => {
      state.touristsDeleteStatus = 'success'
      state.touristsSnackState = true
    },
    [deleteAdminTouristsMain.rejected]: (state) => {
      state.touristsDeleteStatus = 'error'
      state.touristsSnackState = true
    },
    // tourists Delete

    // cafes------------------------------------------
    // tourist cafes
    [getAdminTouristsCafes.pending]: (state) => {
      state.touristsCafesStatus = 'pending'
    },
    [getAdminTouristsCafes.fulfilled]: (state, action) => {
      state.touristsCafesStatus = 'success'
      state.touristsCafes = action.payload?.touristsCafes
    },
    [getAdminTouristsCafes.rejected]: (state) => {
      state.touristsCafesStatus = 'error'
    },
    // tourist cafes
    // tourist current cafes
    [getAdminTouristsCafesById.pending]: (state) => {
      state.touristsCurrentCafeStatus = 'pedning'
    },
    [getAdminTouristsCafesById.fulfilled]: (state, action) => {
      state.touristsCurrentCafeStatus = 'success'
      state.touristsCurrentCafe = action.payload?.touristsCurrentCafe
    },
    [getAdminTouristsCafesById.pending]: (state) => {
      state.touristsCurrentCafeStatus = 'pending'
    },
    // tourist current cafes
    // tourist cafes Add
    [postAdminTouristsCafes.pending]: (state) => {
      state.CafesAddStatus = 'pending'
    },
    [postAdminTouristsCafes.fulfilled]: (state) => {
      state.CafesAddStatus = 'success'
      state.CafesSnackState = true
    },
    [postAdminTouristsCafes.rejected]: (state) => {
      state.CafesAddStatus = 'error'
      state.CafesSnackState = true
    },
    // tourist cafes Add
    // tourist cafes Edit
    [putAdminTouristsCafes.pending]: (state) => {
      state.CafesEditStatus = 'pending'
    },
    [putAdminTouristsCafes.fulfilled]: (state) => {
      state.CafesEditStatus = 'success'
      state.CafesSnackState = true
    },
    [putAdminTouristsCafes.rejected]: (state) => {
      state.CafesEditStatus = 'error'
      state.CafesSnackState = true
    },
    // tourist cafes Delete
    [deleteAdminTouristsCafes.pending]: (state) => {
      state.CafesDeleteStatus = 'pending'
    },
    [deleteAdminTouristsCafes.fulfilled]: (state) => {
      state.CafesDeleteStatus = 'success'
      state.CafesSnackState = true
    },
    [deleteAdminTouristsCafes.rejected]: (state) => {
      state.CafesDeleteStatus = 'error'
      state.CafesSnackState = true
    },
    // tourist cafes Delete
    // cafes------------------------------------------

    // hotels-----------------------------------------
    // tourist hotels
    [getAdminTouristsHotels.pending]: (state) => {
      state.touristsHotelsStatus = 'pending'
    },
    [getAdminTouristsHotels.fulfilled]: (state, action) => {
      state.touristsHotelsStatus = 'success'
      state.touristsHotels = action.payload?.touristsHotels
    },
    [getAdminTouristsHotels.rejected]: (state) => {
      state.touristsHotelsStatus = 'error'
    },
    // tourist hotels
    // tourist current hotels
    [getAdminTouristsHotelsById.pending]: (state) => {
      state.touristsCurrentHotelStatus = 'pedning'
    },
    [getAdminTouristsHotelsById.fulfilled]: (state, action) => {
      state.touristsCurrentHotelStatus = 'success'
      state.touristsCurrentHotel = action.payload?.touristsCurrentHotel
    },
    [getAdminTouristsHotelsById.rejected]: (state) => {
      state.touristsCurrentHotelStatus = 'error'
    },
    // tourist current hotels
    // tourist hotels Add
    [postAdminTouristsHotels.pending]: (state) => {
      state.HotelAddStatus = 'pending'
    },
    [postAdminTouristsHotels.fulfilled]: (state) => {
      state.HotelAddStatus = 'success'
      state.HotelSnackState = true
    },
    [postAdminTouristsHotels.rejected]: (state) => {
      state.HotelAddStatus = 'error'
      state.HotelSnackState = true
    },
    // tourist hotels Add
    // tourist hotels Edit
    [putAdminTouristsHotels.pending]: (state) => {
      state.HotelEditStatus = 'pending'
    },
    [putAdminTouristsHotels.fulfilled]: (state) => {
      state.HotelEditStatus = 'success'
      state.HotelSnackState = true
    },
    [putAdminTouristsHotels.rejected]: (state) => {
      state.HotelEditStatus = 'error'
      state.HotelSnackState = true
    },
    // tourist hotels Edit
    // tourist hotels Delete
    [deleteAdminTouristsHotels.pending]: (state) => {
      state.HotelDeleteStatus = 'pending'
    },
    [deleteAdminTouristsHotels.fulfilled]: (state) => {
      state.HotelDeleteStatus = 'success'
      state.HotelSnackState = true
    },
    [deleteAdminTouristsHotels.rejected]: (state) => {
      state.HotelDeleteStatus = 'error'
      state.HotelSnackState = true
    },
    // tourist hotels Delete
    // hotels-----------------------------------------

    // attractions -----------------------------------
    // tourist Attractions
    [getAdminTouristsAttractions.pending]: (state) => {
      state.touristsAttractionsStatus = 'pending'
    },
    [getAdminTouristsAttractions.fulfilled]: (state, action) => {
      state.touristsAttarctionsStatus = 'success'
      state.touristsAttractions = action.payload?.touristsAtrractions
    },
    [getAdminTouristsAttractions.rejected]: (state) => {
      state.touristsAttractionsStatus = 'error'
    },
    // tourist Attractions
    // tourist current Attractions
    [getAdminTouristsAttractionsById.pending]: (state) => {
      state.touristsCurrentAttractionStatus = 'pedning'
    },
    [getAdminTouristsAttractionsById.fulfilled]: (state, action) => {
      state.touristsCurrentAttractionStatus = 'success'
      state.touristsCurrentAttraction =
        action.payload?.touristsCurrentAtrraction
    },
    [getAdminTouristsAttractionsById.rejected]: (state) => {
      state.touristsCurrentAttractionStatus = 'error'
    },
    // tourist current Attractions
    // tourist Attractions Add
    [postAdminTouristsAttractions.pending]: (state) => {
      state.AttractionsAddStatus = 'pending'
    },
    [postAdminTouristsAttractions.fulfilled]: (state) => {
      state.AttractionsAddStatus = 'success'
      state.AttractionsSnackState = true
    },
    [postAdminTouristsAttractions.rejected]: (state) => {
      state.AttractionsAddStatus = 'error'
      state.AttractionsSnackState = true
    },
    // tourist Attractions Add
    // tourist Attractions Edit
    [putAdminTouristsAttractions.pending]: (state) => {
      state.AttractionsEditStatus = 'pending'
    },
    [putAdminTouristsAttractions.fulfilled]: (state) => {
      state.AttractionsEditStatus = 'success'
      state.AttractionsSnackState = true
    },
    [putAdminTouristsAttractions.rejected]: (state) => {
      state.AttractionsEditStatus = 'error'
      state.AttractionsSnackState = true
    },
    // tourist Attractions Edit
    // tourist Attractions Delete
    [deleteAdminTouristsAttractions.pending]: (state) => {
      state.AttractionsDeleteStatus = 'pending'
    },
    [deleteAdminTouristsAttractions.fulfilled]: (state) => {
      state.AttractionsDeleteStatus = 'success'
      state.AttractionsSnackState = true
    },
    [deleteAdminTouristsAttractions.rejected]: (state) => {
      state.AttractionsDeleteStatus = 'error'
      state.AttractionsSnackState = true
    },
    // tourist Attraction Delete
    // attractions -----------------------------------

    // FOR TOURISTS

    // Contacts
    [getAdminContacts.pending]: (state) => {
      state.contactsStatus = 'pending'
    },
    [getAdminContacts.fulfilled]: (state, action) => {
      state.contactsStatus = 'success'
      state.contacts = action.payload?.adminContacts
    },
    [getAdminContacts.rejected]: (state) => {
      state.contactsStatus = 'error'
    },
    // current Contacts
    [getAdminCurrentContact.pending]: (state) => {
      state.contactsStatus = 'pending'
    },
    [getAdminCurrentContact.fulfilled]: (state, action) => {
      state.contactsStatus = 'success'
      state.currentContact = action.payload?.adminCurrentContact
    },
    [getAdminCurrentContact.rejected]: (state) => {
      state.contactsStatus = 'error'
    },
    // current Contacts
    // contacts Add
    [postAdminContacts.pending]: (state) => {
      state.ContactsAddStatus = 'pending'
    },
    [postAdminContacts.fulfilled]: (state) => {
      state.ContactsAddStatus = 'success'
      state.ContactsSnackState = true
    },
    [postAdminContacts.rejected]: (state) => {
      state.ContactsAddStatus = 'error'
      state.ContactsSnackState = true
    },
    // contacts Add
    // contacts Edit
    [putAdminContacts.pending]: (state) => {
      state.ContactsEditStatus = 'pending'
    },
    [putAdminContacts.fulfilled]: (state) => {
      state.ContactsEditStatus = 'success'
      state.ContactsSnackState = true
    },
    [putAdminContacts.rejected]: (state) => {
      state.ContactsEditStatus = 'error'
      state.ContactsSnackState = true
    },
    // contacts Edit
    // contacts Delete
    [deleteAdminContacts.pending]: (state) => {
      state.ContactsDeleteStatus = 'pending'
    },
    [deleteAdminContacts.fulfilled]: (state) => {
      state.ContactsDeleteStatus = 'success'
      state.ContactsSnackState = true
    },
    [deleteAdminContacts.rejected]: (state) => {
      state.ContactsDeleteStatus = 'error'
      state.ContactsSnackState = true
    },
    // contacts Delete
    // Contacts

    // Calendar
    [getAdminCalendar.pending]: (state) => {
      state.calendarStatus = 'pending'
    },
    [getAdminCalendar.fulfilled]: (state, action) => {
      state.calendarStatus = 'success'
      state.calendar = action.payload?.adminCalendar
      state.other = action.payload?.other
    },
    [getAdminCalendar.rejected]: (state) => {
      state.calendarStatus = 'error'
    },
    // current Calendar
    [getAdminCalendarEvent.pending]: (state) => {
      state.calendarStatus = 'pending'
    },
    [getAdminCalendarEvent.fulfilled]: (state, action) => {
      state.calendarStatus = 'success'
      state.calendarEvent = action.payload?.adminCalendarEvent
    },
    [getAdminCalendarEvent.rejected]: (state) => {
      state.calendarStatus = 'error'
    },
    // current Calendar
    // Calendar Add
    [postAdminCalendar.pending]: (state) => {
      state.CalendarAddStatus = 'pending'
    },
    [postAdminCalendar.fulfilled]: (state) => {
      state.CalendarAddStatus = 'success'
      state.CalendarSnackState = true
    },
    [postAdminCalendar.rejected]: (state) => {
      state.CalendarAddStatus = 'error'
      state.CalendarSnackState = true
    },
    // Calendar Add
    // Calendar Edit
    [putAdminCalendar.pending]: (state) => {
      state.CalendarEditStatus = 'pending'
    },
    [putAdminCalendar.fulfilled]: (state) => {
      state.CalendarEditStatus = 'success'
      state.CalendarSnackState = true
    },
    [putAdminCalendar.rejected]: (state) => {
      state.CalendarEditStatus = 'error'
      state.CalendarSnackState = true
    },
    // Calendar Edit
    // Calendar Delete
    [deleteAdminCalendar.pending]: (state) => {
      state.CalendarDeleteStatus = 'pending'
    },
    [deleteAdminCalendar.fulfilled]: (state) => {
      state.CalendarDeleteStatus = 'success'
      state.CalendarSnackState = true
    },
    [deleteAdminCalendar.rejected]: (state) => {
      state.CalendarDeleteStatus = 'error'
      state.CalendarSnackState = true
    },
    // Calendar Delete
    // Calendar

    // Municipal programs
    [getAdminMunicipalPrograms.pending]: (state) => {
      state.municipalProgramsStatus = 'pending'
    },
    [getAdminMunicipalPrograms.fulfilled]: (state, action) => {
      state.municipalProgramsStatus = 'success'
      state.municipalPrograms = action.payload?.municipalPrograms
    },
    [getAdminMunicipalPrograms.rejected]: (state) => {
      state.municipalProgramsStatus = 'error'
    },
    // programs Add
    [postAdminMunicipalPrograms.pending]: (state) => {
      state.municipalProgramsAddStatus = 'pending'
    },
    [postAdminMunicipalPrograms.fulfilled]: (state) => {
      state.municipalProgramsAddStatus = 'success'
      state.municipalProgramsSnackState = true
    },
    [postAdminMunicipalPrograms.rejected]: (state) => {
      state.municipalProgramsAddStatus = 'error'
      state.municipalProgramsSnackState = true
    },
    // programs Add
    // programs Edit
    [putAdminMunicipalPrograms.pending]: (state) => {
      state.municipalProgramsEditStatus = 'pending'
    },
    [putAdminMunicipalPrograms.fulfilled]: (state) => {
      state.municipalProgramsEditStatus = 'success'
      state.municipalProgramsSnackState = true
    },
    [putAdminMunicipalPrograms.rejected]: (state) => {
      state.municipalProgramsEditStatus = 'error'
      state.municipalProgramsSnackState = true
    },
    // programs Edit
    // programs Delete
    [deleteAdminMunicipalPrograms.pending]: (state) => {
      state.municipalProgramsDeleteStatus = 'pending'
    },
    [deleteAdminMunicipalPrograms.fulfilled]: (state) => {
      state.municipalProgramsDeleteStatus = 'success'
      state.municipalProgramsSnackState = true
    },
    [deleteAdminMunicipalPrograms.rejected]: (state) => {
      state.municipalProgramsDeleteStatus = 'error'
      state.municipalProgramsSnackState = true
    },
    // programs Delete
    // Municipal programs
  },
})

export const adminAction = AdminSlice.actions
