import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import Icon1 from '../../../assets/Checkbox/checkIcon.svg'
import Icon2 from '../../../assets/Checkbox/letterIcon.svg'
import { putAdminAbout } from '../../../services/admin/AdminAction'
import Button from '../../UI/Button'
import Input from '../../UI/Inputs'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminAboutCityHallEdit = () => {
  const [showIcon, setShowIcon] = useState(false)

  const showIconHandler = () => {
    setShowIcon((prev) => !prev)
  }
  const [aboutData, setAboutData] = useState({
    title: '',
    description: '',
  })
  const aboutTitleHanlder = (event) => {
    setAboutData({
      title: event.target.value,
      description: aboutData.description,
    })
  }
  const aboutDescriptionHanlder = (event) => {
    setAboutData({
      title: aboutData.title,
      description: event.target.value,
    })
  }
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const editAbout = () => {
    if (aboutData.description.trim() && aboutData.title.trim() !== '') {
      const body = {
        title: aboutData.title,
        description: aboutData.description,
        seeDate: showIcon,
      }
      dispatch(putAdminAbout({ body, id }))
      navigate(-1)
    }
  }
  return (
    <div>
      <StyledDivContainer>
        <StyledDivContainerInput>
          <StyledBackIcon
            src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
            alt='none'
            onClick={() => navigate(-1)}
          />
          <H6>
            <TranslatorDescription variant='title' />
          </H6>
          <Styled1Input>
            <Input
              onChange={aboutTitleHanlder}
              variant='nameAdmin'
              tipe='input'
            />
          </Styled1Input>

          <H6>
            <TranslatorDescription variant='description' />
          </H6>

          <Styled2Input>
            <Input
              onChange={aboutDescriptionHanlder}
              variant='cityHall'
              tipe='textarea'
            />
          </Styled2Input>

          <ContCheckbox>
            <Span>
              <TranslatorDescription variant='date' />
            </Span>
            {showIcon ? (
              <Image onClick={showIconHandler} src={Icon1} alt='' />
            ) : (
              <Image onClick={showIconHandler} src={Icon2} alt='' />
            )}
          </ContCheckbox>

          <StyleButton>
            <Button onClick={editAbout} variant='button1'>
              <TranslatorDescription variant='save' />
            </Button>
          </StyleButton>
        </StyledDivContainerInput>
      </StyledDivContainer>
    </div>
  )
}
export default AdminAboutCityHallEdit
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
`

const StyledDivContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const H6 = styled.div`
  margin-top: 37px;
  margin-left: 35px;
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 431px) {
    margin-left: 20px;
    margin-top: 20px;
    color: #000;
    font-family: Inter;
    font-size: 23px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
`
const Span = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 35px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 431px) {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
`
const StyledDivContainerInput = styled.div`
  width: 900px;
  height: 650px;
  flex-shrink: 0;
  border-radius: 20px;
  padding: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-left: 80px;
  @media (max-width: 1300px) {
    width: 95%;
    margin-top: 15px;
  }
  @media (max-width: 1025px) {
    width: 90%;
  }
  @media (max-width: 913px) {
    width: 90%;
    margin-left: 200px;
  }
  @media (max-width: 821px) {
    width: 100%;
    margin-left: 170px;
  }
  @media (max-width: 769px) {
    width: 100%;
    margin-left: 140px;
  }
  @media (max-width: 541px) {
    margin: 0;
    width: 500px;
    height: 600px;
    margin-left: 19px;
    margin-top: 10px;
  }
  @media (max-width: 431px) {
    width: 90%;
    height: 500px;
  }
  @media (max-width: 415px) {
    margin: 0;
    width: 90%;
    height: 500px;
    margin-top: 15px;
    margin-left: 15px;
  }
  @media (max-width: 413px) {
    margin: 0;
    width: 90%;
    height: 500px;
    margin-top: 15px;
    margin-left: 15px;
  }
  @media (max-width: 394px) {
    width: 90%;
    margin-left: 7px;
  }
  @media (max-width: 391px) {
    width: 90%;
    margin-left: 7px;
  }
  @media (max-width: 361px) {
    margin: 0px;
    width: 90%;
  }
`
const Styled1Input = styled.div`
  margin-left: 37px;
  @media (max-width: 431px) {
    margin-left: 10px;
  }
`
const Styled2Input = styled.div`
  margin-left: 37px;
  @media (max-width: 431px) {
    margin-left: 10px;
    margin-top: 20px;
    margin-right: 35px;
  }
  @media (max-width: 391px) {
    margin-right: 50px;
    margin-left: 15px;
  }
`
const ContCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: italic;
`

const Image = styled.img`
  border: 1px solid black;
  width: 27px;
  height: 27px;
  margin-left: 10px;
`
const StyleButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 80px;
  margin-top: 30px;
`
