/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getAdminContacts } from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import { SnackBar } from '../../UI/SnackBar'
import Table from '../../UI/Table'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminContacts = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminContacts())
  }, [])
  const state = useSelector((state) => state.admin)

  const {
    ContactsSnackState,
    ContactsAddStatus,
    ContactsEditStatus,
    ContactsDeleteStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.ContactsAddSnackBar({
        ContactsSnackState: false,
        ContactsAddStatus: null,
      })
    )
    dispatch(
      adminAction.ContactsEditSnackBar({
        ContactsSnackState: false,
        ContactsEditStatus: null,
      })
    )
    dispatch(
      adminAction.ContactsDeleteSnackBar({
        ContactsSnackState: false,
        ContactsDeleteStatus: null,
      })
    )
  }

  const navToAddPage = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const navToEditPage = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  return (
    <StyledTable>
      {ContactsSnackState && (
        <SnackBar
          message={
            ContactsAddStatus === 'success'
              ? (translator.ru && ru.messageSaccess) ||
                (translator.en && en.messageSaccess) ||
                (translator.kyr && kyr.messageSaccess)
              : ContactsEditStatus === 'success'
              ? (translator.ru && ru.messageEditSaccess) ||
                (translator.en && en.messageEditSaccess) ||
                (translator.kyr && kyr.messageEditSaccess)
              : ContactsDeleteStatus === 'success'
              ? (translator.ru && ru.messageDeleteSaccess) ||
                (translator.en && en.messageDeleteSaccess) ||
                (translator.kyr && kyr.messageDeleteSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={
            ContactsAddStatus || ContactsEditStatus || ContactsDeleteStatus
          }
          open={ContactsSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      {state.contacts?.length > 0 && (
        <Table
          tableDatas={state?.contacts}
          variant='admin'
          onEdit={navToEditPage}
        />
      )}
      <StyledButton>
        {state.contacts?.length <= 0 && (
          <StyledDivText>
            <DataNotFoundTranslator variant='contacts' />
          </StyledDivText>
        )}
        <Button onClick={navToAddPage} variant='button1'>
          <TranslatorDescription variant='add' />
        </Button>
      </StyledButton>
    </StyledTable>
  )
}

export default AdminContacts

const StyledTable = styled.div`
  margin: 20px;
  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`
const StyledButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
