import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { postAdminTouristsAttractions } from '../../../../services/admin/AdminAction'
import Button from '../../../UI/Button'
import CardPhoto from '../../../UI/CardPhoto'
import Input from '../../../UI/Inputs'
import { GoogleMap } from '../../../UI/Map'
import NetWifi from '../../../UI/NetWifi'
import TranslatorDescription from '../../../UI/TranslatorContent/TranslatorDescription'

const AdminAddAttractions = () => {
  const [change, setChange] = useState(false)
  const changeHandler = () => {
    setChange((prev) => !prev)
  }
  const [position, setPosition] = useState(null)
  const [files, setFiles] = useState(null)
  const [format, setFormat] = useState(null)
  const [inputs, setInputs] = useState({
    title: '',
    description: '',
  })
  const titleInputHandler = (event) => {
    setInputs({
      title: event.target.value,
      description: inputs.description,
    })
  }
  const descriptionInputHandler = (event) => {
    setInputs({
      title: inputs.title,
      description: event.target.value,
    })
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const addTouristsAttraction = () => {
    if (
      inputs.title.trim() !== '' &&
      inputs.description.trim() !== '' &&
      format &&
      position
    ) {
      dispatch(
        postAdminTouristsAttractions({
          title: inputs.title,
          description: inputs.description,
          img: format,
          wifi: change ? 'YES' : 'NO',
          location: position,
        })
      )
      navigate(-1)
    }
  }
  return (
    <MainContainer>
      <Container>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />
        <ContMa>
          <CardPhoto
            setFiles={setFiles}
            files={files}
            setFormat={setFormat}
            variant='logo'
          />
          <Maap>
            <GoogleMap
              setPosition={setPosition}
              position={position}
              variant='mini'
            />
          </Maap>
        </ContMa>
        <ContInput>
          <Description>
            <div>
              <Label>
                <TranslatorDescription variant='title' />
              </Label>
              <Input
                onChange={titleInputHandler}
                tipe='input'
                variant='nameAdmin'
              />
            </div>
            <Maap>
              <Label>
                <TranslatorDescription variant='description' />
              </Label>
              <Input
                onChange={descriptionInputHandler}
                tipe='textarea'
                variant='textAdmin'
              />
            </Maap>
          </Description>

          <StyleDiv>
            <NetWifi variant='Choice' />
            <None onClick={changeHandler}>
              {change ? (
                <TranslatorDescription variant='yes' />
              ) : (
                <TranslatorDescription variant='no' />
              )}
            </None>
          </StyleDiv>

          <ShowButton>
            <Button onClick={addTouristsAttraction} variant='button1'>
              <TranslatorDescription variant='save' />
            </Button>
          </ShowButton>
        </ContInput>
      </Container>
    </MainContainer>
  )
}

export default AdminAddAttractions

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
`
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
  position: relative;
  right: 5px;
  top: 5px;
  @media (max-width: 450px) {
    right: 180px;
  }
  @media (max-width: 391px) {
    right: 170px;
  }
  @media (max-width: 376px) {
    right: 160px;
  }
`
const Container = styled.div`
  display: flex;
  align-items: start;
  height: max-content;
  padding: 0px 15px 10px 15px;
  border-radius: 20px;
  background: #fff;
  margin-left: 13px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 20px;
  @media (max-width: 1300px) {
    padding-right: 10px;
  }
  @media (max-width: 1100px) {
    width: 600px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 821px) {
    width: 600px;
  }
  @media (max-width: 550px) {
    width: 95%;
  }
`
const Label = styled.label`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
`
const ContMa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 850px) {
    align-items: center;
  }
`
const ContInput = styled.div``
const None = styled.div`
  border: 1px solid;
  font-family: Inter, sans-serif;
  font-size: 25px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
  border-radius: 10px;
  background: #fff;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`
const StyleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 550px) {
    margin-top: 10px;
    justify-content: center;
  }
`
const ShowButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 175px;
  @media (max-width: 1024px) {
    margin-top: 55px;
  }
`
const Maap = styled.div`
  @media (max-width: 376px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
