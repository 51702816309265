import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Modall from '../Modall'

const ModalDeleteContent = ({ Cancellation, deletedFunction, variant }) => {
  const ru = russian
  const en = english
  const kyr = kyrgyz

  const translator = useSelector((state) => state.translator)

  return (
    <Modall onClose={Cancellation}>
      <StyledModalContainer>
        <StyledModalDivText>
          <StyledModalText>
            {variant === '' &&
              ((translator.ru && ru.modalContent.deletedText) ||
                (translator.en && en.modalContent.deletedText) ||
                (translator.kyr && kyr.modalContent.deletedText))}
            {variant === 'tourist' &&
              ((translator.ru && ru.modalContent.deletedPhoto) ||
                (translator.en && en.modalContent.deletedPhoto) ||
                (translator.kyr && kyr.modalContent.deletedPhoto))}
            {variant === 'FAQ' &&
              ((translator.ru && ru.modalContent.deletedFAQ) ||
                (translator.en && en.modalContent.deletedFAQ) ||
                (translator.kyr && kyr.modalContent.deletedFAQ))}
            {variant === 'About-CityHall' &&
              ((translator.ru && ru.modalContent.deletedDatas) ||
                (translator.en && en.modalContent.deletedDatas) ||
                (translator.kyr && kyr.modalContent.deletedDatas))}
            {variant === 'Contact' &&
              ((translator.ru && ru.modalContent.deletedContact) ||
                (translator.en && en.modalContent.deletedContact) ||
                (translator.kyr && kyr.modalContent.deletedContact))}
            {variant === 'Event' &&
              ((translator.ru && ru.modalContent.deletedEvent) ||
                (translator.en && en.modalContent.deletedEvent) ||
                (translator.kyr && kyr.modalContent.deletedEvent))}
          </StyledModalText>
        </StyledModalDivText>
        <StyledModalDivButton>
          <Сancellation onClick={Cancellation}>
            {(translator.ru && ru.modalContent.cancellation) ||
              (translator.en && en.modalContent.cancellation) ||
              (translator.kyr && kyr.modalContent.cancellation)}
          </Сancellation>
          <Delete onClick={deletedFunction}>
            {(translator.ru && ru.modalContent.deleted) ||
              (translator.en && en.modalContent.deleted) ||
              (translator.kyr && kyr.modalContent.deleted)}
          </Delete>
        </StyledModalDivButton>
      </StyledModalContainer>
    </Modall>
  )
}

export default ModalDeleteContent

const StyledModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
const StyledModalDivText = styled.div`
  width: 90%;
  text-align: center;
  padding: 40px 0 0 0;
  height: 150px;
`
const StyledModalDivButton = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: end;
`
const Сancellation = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(10, 3, 89, 1);
  color: rgba(255, 255, 255, 1);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media (max-width: 541px) {
    width: 150px;
  }
  @media (max-width: 376px) {
    width: 120px;
  }
`
const Delete = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(255, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media (max-width: 541px) {
    width: 150px;
  }
  @media (max-width: 376px) {
    width: 120px;
  }
`
const StyledModalText = styled.h4`
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
