import { Route, Routes } from 'react-router-dom'
import { Authorization } from '../components/Auth/Authorization'
import UserAppealCitizen from '../components/User/UserAppealCitizen'
import UserAttraction from '../components/User/UserAttraction'
import UserCalendarEventPages from '../components/User/UserCalendarEventPages'
import UserCityHall from '../components/User/UserCityHall'
import UserCurrentAttraction from '../components/User/UserCurrentAttraction'
import UserCurrentCafe from '../components/User/UserCurrentCafe'
import UserCurrentHotel from '../components/User/UserCurrentHotel'
import { UserHotelPage } from '../components/User/UserHotelPage'
import UserMainPage from '../components/User/UserMainPage'
import UserMeropriyatii from '../components/User/UserMeropriyatii'
import UserNewsPage from '../components/User/UserNewsPage'
import UserProgram from '../components/User/UserPrograms'
import UserQuestion from '../components/User/UserQuestion'
import UserRestaurantsAndCafes from '../components/User/UserRestaurantsAndCafes'
import UserTable from '../components/User/UserTable'
import UserTurist from '../components/User/UserTurist'
import UserLayout from '../layouts/UserLayout'
import ErrorPage from './ErrorPage'

export const UserRoutes = () => {
  return (
    <Routes>
      <Route path='/auth_$132' element={<Authorization />} />
      <Route path='' element={<UserLayout />}>
        <Route path='/' element={<UserMainPage />} />
        <Route path='/news' element={<UserNewsPage />} />
        <Route path='/appeals-of-citizens' element={<UserAppealCitizen />} />
        <Route path='/faq' element={<UserQuestion />} />
        <Route path='/about-of-city-hall' element={<UserCityHall />} />
        <Route path='/for-tourists' element={<UserTurist />} />
        <Route
          path='/for-tourists/reustaurants-cafes'
          element={<UserRestaurantsAndCafes />}
        />
        <Route
          path='/for-tourists/reustaurants-cafes/:id'
          element={<UserCurrentCafe />}
        />
        <Route path='/for-tourists/hotels' element={<UserHotelPage />} />
        <Route path='/for-tourists/attractions' element={<UserAttraction />} />
        <Route path='/for-tourists/hotels/:id' element={<UserCurrentHotel />} />
        <Route
          path='/for-tourists/attractions/:id'
          element={<UserCurrentAttraction />}
        />
        <Route path='/contacts' element={<UserTable />} />
        <Route path='/calendar' element={<UserMeropriyatii />} />
        <Route path='/calendar/:id' element={<UserCalendarEventPages />} />
        <Route path='/municipal-programs' element={<UserProgram />} />
        <Route path='*' element={<ErrorPage />} />
      </Route>
      <Route
        path='@supersecure'
        element={
          <div>
            <h1>We bring our gratitude</h1>
            <div style={{ display: 'flex', gap: '20px', fontSize: '18px' }}>
              <div>
                <h2>Fronted</h2>
                <p>Kanatbek</p>
                <p>Aziret</p>
                <p>Raatbek</p>
                <p>Jumgal</p>
                <p>Darina</p>
                <p>Aizirek</p>
                <p>Aiturgan</p>
                <p>Salika</p>
                <p>Nazik</p>
              </div>
              <div>
                <h2>Backend</h2>
                <p>Aidar</p>
                <p>Nurbek</p>
                <p>Nursalin</p>
                <p>Nurdolot</p>
                <p>Zarina</p>
              </div>
            </div>
            <h1>thanks everyone for participating!</h1>
          </div>
        }
      />
    </Routes>
  )
}
