import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiFetch } from '../../api/Fetch'
import LocalStorageFunc from '../../utils/helpers/LocalStorageFunc'

export const postLogin = createAsyncThunk(
  'login/admin',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: 'api/v1/admin/login',
        method: 'POST',
        body: props.body,
      })
      LocalStorageFunc({
        method: 'set',
        key: 'authorization',
        body: {
          jwt: response?.token,
          role: response?.role,
          email: response?.email,
        },
      })
      return { login: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
const auth = LocalStorageFunc({
  method: 'get',
  key: 'authorization',
})
const initialState = {
  status: null,
  jwt: auth?.jwt || null,
  email: auth?.email || null,
  role: auth?.role || null,
}
export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [postLogin.pending]: (state) => {
      state.status = 'pending'
    },
    [postLogin.fulfilled]: (state, action) => {
      state.status = 'success'
      state.jwt = action.payload.login?.token
      state.role = action.payload.login?.role
      state.email = action.payload.login?.email
    },
    [postLogin.rejected]: (state) => {
      state.status = 'error'
    },
  },
})
