import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import Icon1 from '../../../assets/Checkbox/checkIcon.svg'
import Icon2 from '../../../assets/Checkbox/letterIcon.svg'
import { putAdminNews } from '../../../services/admin/AdminAction'
import Button from '../../UI/Button'
import CardPhoto from '../../UI/CardPhoto'
import Input from '../../UI/Inputs'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminEditNews = () => {
  const [showIcon, setShowIcon] = useState(false)
  const [format, setFormat] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const showIconHandler = () => {
    setShowIcon((prev) => !prev)
  }
  const titleHandler = (event) => {
    setTitle(event.target.value)
  }
  const descriptionHandler = (event) => {
    setDescription(event.target.value)
  }
  const adminEditNews = () => {
    if (title.trim() !== '' && description.trim() !== '' && format !== null) {
      dispatch(
        putAdminNews({ title, description, date: showIcon, img: format, id })
      )
    }
    navigate(-1)
  }
  return (
    <Container>
      <DivMain>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />
        <DivCard>
          <Photo>
            <CardPhoto
              files={files}
              setFiles={setFiles}
              setFormat={setFormat}
              variant='logo'
            />
          </Photo>
          <StyledDivInput1>
            <div>
              <Label>
                <TranslatorDescription variant='title' />
              </Label>
              <Input onChange={titleHandler} tipe='input' variant='nameAdmin' />
              <StyledInput2>
                <Label>
                  <TranslatorDescription variant='description' />
                </Label>
                <Input
                  onChange={descriptionHandler}
                  tipe='textarea'
                  variant='textAdmin'
                />
              </StyledInput2>
            </div>
          </StyledDivInput1>
        </DivCard>
        <StyledBtnH>
          <DivImg>
            <H3>
              <TranslatorDescription variant='date' />
            </H3>
            <StyledCheckBox>
              {showIcon ? (
                <IMG onClick={showIconHandler} src={Icon1} alt='' />
              ) : (
                <IMG onClick={showIconHandler} src={Icon2} alt='' />
              )}
            </StyledCheckBox>
          </DivImg>
          <StyledButton>
            <Button onClick={adminEditNews} variant='button2'>
              <TranslatorDescription variant='save' />
            </Button>
          </StyledButton>
        </StyledBtnH>
      </DivMain>
    </Container>
  )
}

export default AdminEditNews
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
`
const StyledDivInput1 = styled.div`
  margin-left: 25px;
  display: flex;
`
const StyledInput2 = styled.div`
  margin-top: 9px;
`
const StyledButton = styled.div`
  margin-top: 20px;
  margin-left: 40px;
  @media (max-width: 1281px) {
    margin: 0;
    margin-top: 70px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-left: 100px;
    margin-top: 60px;
  }
  @media (max-width: 376px) {
    margin: 0;
    margin-left: 90px;
    margin-top: 40px;
  }
`
const StyledBtnH = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  @media (max-width: 1281px) {
    margin: 0;
    margin-bottom: 50px;
  }
  @media (max-width: 376px) {
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 80px;
  }
  @media (max-width: 415px) {
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 80px;
  }
`
const Photo = styled.div``
const StyledCheckBox = styled.div``
const IMG = styled.img`
  border: 3px solid black;
  width: 27px;
  height: 27px;
  @media (max-width: 1281px) {
    margin: 0;
    margin-top: 20px;
  }
  @media (max-width: 540px) {
    margin: 0;
    margin-top: 30px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-top: 30px;
  }
  @media (max-width: 376px) {
    margin: 0;
    margin-top: 30px;
  }
`
const H3 = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 26px;
  margin-left: 25px;
  @media (max-width: 1281px) {
    margin: 0;
    margin-top: 20px;
    margin-left: 20px;
  }
  @media (max-width: 540px) {
    margin: 0;
    margin-top: 30px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-right: 10px;
    margin-top: 30px;
  }
  @media (max-width: 376px) {
    margin: 0;
    font-size: 16px;
    margin-right: 10px;
    margin-top: 30px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-right: 10px;
    margin-top: 30px;
  }
`
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const DivMain = styled.div`
  height: 100%;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 80px;
  border-radius: 15px;
  border: 0.5px solid #0a0082;
  background-color: rgba(255, 255, 255, 1);
  @media (max-width: 913px) {
    width: 800px;
    padding-bottom: 10px;
  }
  @media (max-width: 821px) {
    margin-top: 20px;
    height: 100%;
    width: 700px;
  }
  @media (max-width: 540px) {
    width: 500px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-top: 30px;
    width: 400px;
  }
  @media (max-width: 394px) {
    width: 380px;
  }
  @media (max-width: 391px) {
    width: 370px;
  }
  @media (max-width: 376px) {
    margin-top: 20px;
  }
  @media (max-width: 361px) {
    width: 360px;
  }
`

const DivCard = styled.div`
  margin: 30px;
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`
const DivImg = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  @media (max-width: 376px) {
    margin-right: 100px;
  }
`
const Label = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 19px;
`
