import React from 'react'
import { styled } from 'styled-components'

const CalendarOfEvents = ({
  calendarOfEvents,
  name,
  day,
  navToCurrentEvent,
}) => {
  return (
    <Data onClick={navToCurrentEvent}>
      <div>
        <StyledDivName>{name}</StyledDivName>
        {day} {calendarOfEvents}
      </div>
    </Data>
  )
}
export default CalendarOfEvents

const Data = styled.div`
  margin: 0;
  border-radius: 9px;
  border: 1px solid #000;
  background: #fff;
  width: 260px;
  height: 80px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const StyledDivName = styled.div`
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`
