/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminMunicipalPrograms,
  getAdminMunicipalPrograms,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import Card from '../../UI/Card'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../UI/SnackBar'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

// municipalProgramsAddStatus

const AdminProgram = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const dispatch = useDispatch()
  const state = useSelector((state) => state.admin)

  const {
    municipalProgramsSnackState,
    municipalProgramsAddStatus,
    municipalProgramsEditStatus,
    municipalProgramsDeleteStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  const [count, setCount] = useState(6)
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const navigate = useNavigate()
  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getAdminMunicipalPrograms({ count }))
  }, [count])
  const deleteAdminMunicipalProgramsHandler = () => {
    dispatch(deleteAdminMunicipalPrograms({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.ProgramsAddSnackBar({
        municipalProgramsSnackState: false,
        municipalProgramsAddStatus: null,
      })
    )
    dispatch(
      adminAction.ProgramsEditSnackBar({
        municipalProgramsSnackState: false,
        municipalProgramsEditStatus: null,
      })
    )
    dispatch(
      adminAction.ProgramsDeleteSnackBar({
        municipalProgramsSnackState: false,
        municipalProgramsDeleteStatus: null,
      })
    )
  }

  const edit = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  const add = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  return (
    <DivMain>
      {ModalState && (
        <ModalDeleteContent
          variant=''
          Cancellation={Cancellation}
          deletedFunction={deleteAdminMunicipalProgramsHandler}
        />
      )}
      {municipalProgramsSnackState && (
        <SnackBar
          message={
            municipalProgramsAddStatus === 'success'
              ? (translator.ru && ru.messageSaccess) ||
                (translator.en && en.messageSaccess) ||
                (translator.kyr && kyr.messageSaccess)
              : municipalProgramsEditStatus === 'success'
              ? (translator.ru && ru.messageEditSaccess) ||
                (translator.en && en.messageEditSaccess) ||
                (translator.kyr && kyr.messageEditSaccess)
              : municipalProgramsDeleteStatus === 'success'
              ? (translator.ru && ru.messageDeleteSaccess) ||
                (translator.en && en.messageDeleteSaccess) ||
                (translator.kyr && kyr.messageDeleteSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={
            municipalProgramsAddStatus ||
            municipalProgramsEditStatus ||
            municipalProgramsDeleteStatus
          }
          open={municipalProgramsSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      {state.municipalPrograms?.map((element) => (
        <DivCard key={element.id}>
          <Card
            variant='municipal-page-admin'
            img={element.image}
            title={element.title}
            description={element.description}
            date={element.date}
            id={element.id}
            onDelete={() => deletedHandler(element.id)}
            onEdit={() => edit(element.id)}
          />
        </DivCard>
      ))}
      <DivStyledBtn>
        <StyledBtn>
          {state.municipalPrograms?.length <= 0 && (
            <StyledDivText>
              <DataNotFoundTranslator />
            </StyledDivText>
          )}
          {state.municipalPrograms?.length >= 6 && (
            <Button onClick={countHandler} variant='button1'>
              <TranslatorDescription variant='more' />
            </Button>
          )}
          <Button onClick={add} variant='button1'>
            <TranslatorDescription variant='add' />
          </Button>
        </StyledBtn>
      </DivStyledBtn>
    </DivMain>
  )
}
export default AdminProgram

const DivMain = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  @media (max-width: 913px) {
    display: flex;
    justify-content: center;
    margin-right: 40px;
  }
`
const DivCard = styled.div`
  margin-top: 30px;
  margin-left: 50px;
  @media (max-width: 1281px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 5px;
  }
  @media (max-width: 821px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 769px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 15px;
  }
  @media (max-width: 541px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 394px) {
    margin-left: 15px;
  }
  @media (max-width: 391px) {
    margin-left: 15px;
  }
  @media (max-width: 376px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 5px;
  }
  @media (max-width: 361px) {
  }
`
const DivStyledBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
`
const StyledBtn = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 30px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
