import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { english } from '../utils/constants/lang/english'
import { kyrgyz } from '../utils/constants/lang/kyrgyz'
import { russian } from '../utils/constants/lang/russian'

const ErrorPage = () => {
  const navigate = useNavigate()
  const navToMainPage = () => {
    navigate('/')
  }
  const state = useSelector((state) => state.translator)
  const ru = russian.errorpage
  const en = english.errorpage
  const kyr = kyrgyz.errorpage
  return (
    <ErrorContainer>
      <ErrorBlock>
        <Error>404</Error>
        <ErrorTitle>
          {state.ru && ru.title}
          {state.en && en.title}
          {state.kyr && kyr.title}
        </ErrorTitle>
        <ErrorButton onClick={navToMainPage}>
          {state.ru && ru.back}
          {state.en && en.back}
          {state.kyr && kyr.back}
        </ErrorButton>
      </ErrorBlock>
    </ErrorContainer>
  )
}

export default ErrorPage

const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 200px;
`
const ErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  user-select: none;
`
const Error = styled.h1`
  color: #03098e;
  font-family: Jua, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
const ErrorTitle = styled.p`
  color: #000;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center;
`
const ErrorButton = styled.button`
  width: 213px;
  height: 41px;
  border-radius: 5px;
  background: #03098e;
  border: none;
  color: #fff;
  font-family: Jua, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #010668;
  }
`
