import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getUserTurists } from '../../services/User/UserAction'
import TextCard from '../UI/TextCard'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'

const UserTurist = () => {
  const { vertical, horizontal } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserTurists())
  }, [])
  const navigate = useNavigate()

  const attractions = () => {
    navigate('attractions')
  }

  const hotelNavigate = () => {
    navigate('/for-tourists/hotels')
  }
  const cafesNavigate = () => {
    navigate('/for-tourists/reustaurants-cafes')
  }
  return (
    <Container>
      <DivTextCard>
        <TextCard onClick={hotelNavigate} variant='hotels' />
        <TextCard onClick={attractions} variant='attractions' />
        <TextCard onClick={cafesNavigate} variant='cafes' />
      </DivTextCard>
      <StyledDivContainer>
        {vertical > 0 && horizontal > 0 ? (
          <>
            <div>
              {horizontal.map((el) => (
                <DivContainerImg key={el.id}>
                  <StyledImg src={el.img} alt='none' />
                </DivContainerImg>
              ))}
            </div>
            <div>
              {vertical.map((el) => (
                <DivContainerImg key={el.id}>
                  <StyledImg variant='vertical' src={el.img} alt='none' />
                </DivContainerImg>
              ))}
            </div>
          </>
        ) : (
          <StyledDivText>
            <DataNotFoundTranslator variant='tourists' />
          </StyledDivText>
        )}
      </StyledDivContainer>
    </Container>
  )
}

export default UserTurist

const Container = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`

const DivContainerImg = styled.div`
  display: flex;
  flex-direction: column;
`
const DivTextCard = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
`
const StyledDivContainer = styled.div`
  display: flex;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 431px) {
    display: flex;
    flex-direction: column;
  }
`
const StyledImg = styled.img`
  width: ${(props) => (props.variant === 'vertical' ? '400px' : '700px')};
  height: ${(props) => (props.variant === 'vertical' ? '550px' : '350px')};
  padding: 10px 10px;
  margin-top: 20px;
  border-radius: 30px;
  @media (max-width: 1280px) {
    width: 903px;
    height: 300px;
    display: flex;
  }
  @media (max-width: 1024px) {
    width: 648px;
    height: 50vh;
    margin-left: 4px;
  }
  @media (max-width: 912px) {
    width: 910px;
    height: 400px;
    display: flex;
  }
  @media (max-width: 820px) {
    width: 816px;
    height: 350px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 768px) {
    width: 764px;
    height: 350px;
    display: flex;
  }
  @media (max-width: 540px) {
    width: 536px;
    height: 300px;
    display: flex;
  }
  @media (max-width: 415px) {
    width: 410px;
    height: 200px;
    display: flex;
    transform: translateY(100px);
  }
  @media (max-width: 413px) {
    width: 407px;
    height: 200px;
    display: flex;
  }
  @media (max-width: 394px) {
    width: 390px;
    height: 200px;
    display: flex;
  }
  @media (max-width: 391px) {
    width: 387px;
    height: 200px;
    display: flex;
  }
  @media (max-width: 376px) {
    width: 370px;
    height: 200px;
    display: flex;
  }
  @media (max-width: 360px) {
    width: 356px;
    height: 200px;
    display: flex;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
