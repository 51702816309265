export const russian = {
  header: {
    title: 'Официальный сайт мэрии Кочкор-Аты',
  },
  sidebar: {
    main: 'Главная страница',
    news: 'Новости и объявления',
    faq: 'Часто задаваемые вопросы',
    about: 'О Мэрии',
    appeals: 'Обращения граждан',
    tourists: 'Для туристов',
    contacts: 'Контакты',
    calendar: 'Календарь мероприятий',
    municipal: 'Муниципальные программы',
  },
  buttons: {
    add: 'Добавить',
    more: 'Ещё',
    appeals: 'Обращения',
    send: 'Отправить',
  },
  textcard: {
    hotels: 'Гостиницы',
    attractions: 'Достопримечательности',
    cafes: 'Рестораны и кафе',
  },
  table: {
    fullname: 'Имя Фамилия',
    number: 'Номер',
    email: 'Почта',
    post: 'Должность',
  },
  errorpage: {
    title: 'Такой страницы не существует',
    back: 'Вернутся назад',
  },
  snackBar: {
    messageSaccess: 'Данные успешно добавлены!',
    dataSentSaccess: 'Данные успешно отправлены!',
    photoAddSaccess: 'Фотография успешно добавлена!',
    messageEditSaccess: 'Данные успешно изменены!',
    photoEditSaccess: 'Фотография успешно изменена!',
    messageDeleteSaccess: 'Данные успешно удалены!',
    photoDeleteSaccess: 'Фотография успешно удалена!',
    messageError: 'Произошла ошибка. Повторите попытку позже!',
  },
  dataNotFound: {
    dataNotFound: 'На данный момент здесь ничего нет.',
    newsNotFound: 'Здесь пока нет новостей.',
    contactsNotFound: 'Список контактов пуст.',
    touristsPhotoNotFound: 'Здесь пока нет фотографий.',
  },
  modalContent: {
    deletedText: 'Вы уверены в том что хотите удалить?',
    deletedPhoto: 'Вы уверены, что хотите удалить это фото?',
    deletedFAQ: 'Вы уверены, что хотите удалить этот вопрос?',
    deletedDatas: 'Вы уверены, что хотите удалить эти данные?',
    deletedContact: 'Вы уверены, что хотите удалить этот контакт?',
    deletedEvent: 'Вы уверены, что хотите удалить это мероприятие?',
    deleted: 'Удалить',
    cancellation: 'Отмена',
  },
  footer: {
    info: 'Государственные сайты',
    cityHall: 'О Мэрии',
    republic: 'Государственный веб-сайт',
    contacts: 'Контакты',
    job: 'Вакансии',
    network: 'Соц сети',
    security: '© 2023 Все права защищены',
  },
  descriptionDatas: {
    title: 'Название:',
    description: 'Описание:',
    date: 'Показать дату выхода?',
    save: 'Сохранить',
    add: 'Добавить',
    more: 'Ещё',
    show: 'Показать',
    price: 'за день',
    places: 'Места',
    wifi: {
      yes: 'Есть',
      no: 'Нету',
      both: 'Есть / Нету',
    },
    appeals: 'Обращения',
    job_title: 'Должность:',
    mail: 'Почта:',
    number: 'Номер',
    numberWhatsApp: 'Номер WhatsApp',
    complaint: 'Описание вашей жалобы',
    surname: 'Фамилия:',
    name: 'Имя',
    question: 'Вопрос',
    answer: 'Ваш ответ',
    upload: {
      video: 'Загрузить видео',
      photo: 'Загрузить Фото',
      button: 'Отправить',
    },
    citizenSend: 'Отправить и добавить FAQ',
    citizenIgnor: 'Игнорить',
    archive: {
      title: 'Архив вопросов',
      clear: 'Очистить',
    },
    userquestion: 'Обращения',
  },
}
