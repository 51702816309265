import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { getUserNews } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Card from '../UI/Card'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserNewsPage = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.user)
  const [count, setCount] = useState(6)

  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getUserNews({ count }))
  }, [count])
  return (
    <StyledDivNews>
      {state.news.length > 0 ? (
        <>
          {state.news?.map((element) => (
            <StyledNews key={element.id}>
              <Card
                variant='news-page'
                title={element.title}
                date={element.date}
                description={element.description}
                img={element.img && element.img}
              />
            </StyledNews>
          ))}
          {state.news.length >= 6 && (
            <StyledButton>
              <Button variant='button1' onClick={countHandler}>
                <TranslatorDescription variant='more' />
              </Button>
            </StyledButton>
          )}
        </>
      ) : (
        <StyledDivText>
          <DataNotFoundTranslator variant='news' />
        </StyledDivText>
      )}
    </StyledDivNews>
  )
}

export default UserNewsPage

const StyledDivNews = styled.div`
  margin: 0px 0px 0px 25px;
  @media (max-width: 1300px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
`

const StyledNews = styled.div`
  margin-top: 15px;
`
const StyledButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
