/* eslint-disable no-plusplus */
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Icon from '../../assets/Photo/Vector (1).svg'

const Gallery = ({
  filesData,
  setFormat,
  setFiles,
  setFilesData,
  setFormatDataImg,
  formatDataImg,
}) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)
  const fileGroup = useRef(null)
  const changeHandler = (event) => {
    const file = event.target.files[0]
    if (setFormat) {
      setFormat(file)
    }
    if (file) {
      setFormatDataImg([...formatDataImg, file])
      const url = URL.createObjectURL(file)
      setFilesData((prevData) => {
        const updatedData = [...prevData]
        updatedData[0].img = url
        return updatedData
      })
      setFiles(url)
    }
  }
  const handleSwipeLeft = () => {
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1)
    }
  }

  const handleSwipeRight = () => {
    if (currentPhotoIndex < filesData.length - 1) {
      setCurrentPhotoIndex(currentPhotoIndex + 1)
    }
  }

  return (
    <Container>
      {filesData.length > 0 && (
        <StyledCounter>{`${currentPhotoIndex + 1}/${
          filesData.length
        }`}</StyledCounter>
      )}
      <div>
        {currentPhotoIndex > 0 && (
          <ButtonLeft
            onClick={handleSwipeLeft}
            src='https://cdn-icons-png.flaticon.com/512/32/32213.png'
            alt='none'
          />
        )}
      </div>
      {filesData.length < 1 && (
        <Img>
          <StyledLogo src={Icon} alt='error' />
        </Img>
      )}
      {filesData?.map((element, index) => {
        return (
          <div key={element}>
            {currentPhotoIndex === index && (
              <Img>
                {element.img && (
                  <StyledImg
                    src={element.img}
                    alt={`Photo ${currentPhotoIndex + 1}`}
                  />
                )}

                {element.video && <VideoImg src={element.video} controls />}
                <StyledInput
                  ref={fileGroup}
                  onChange={changeHandler}
                  type='file'
                  accept='image/*,.svg,.png,.jpg,.web'
                />
              </Img>
            )}
          </div>
        )
      })}
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {currentPhotoIndex < filesData.length - 1 && (
          <ButtonRight
            onClick={handleSwipeRight}
            src='https://cdn-icons-png.flaticon.com/512/32/32213.png'
            alt='none'
          />
        )}
      </div>
    </Container>
  )
}

export default Gallery

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #0a0082;
  border-radius: 17px;
  margin-top: 10px;
`
const Img = styled.div`
  width: 600px;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1281px) {
    width: 600px;
    height: 330px;
  }
  @media (max-width: 1025px) {
    width: 450px;
    height: 255px;
  }
  @media (max-width: 913px) {
    width: 480px;
    height: 280px;
  }
  @media (max-width: 541px) {
    width: 450px;
    height: 270px;
  }
  @media (max-width: 450px) {
    width: 350px;
    height: 200px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 415px) {
    width: 330px;
    height: 170px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 413px) {
    width: 340px;
    height: 180px;
    margin: none;
  }
  @media (max-width: 394px) {
    width: 330px;
    height: 180px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 391px) {
    width: 320px;
    height: 180px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 376px) {
    width: 310px;
    height: 190px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 361px) {
    width: 300px;
    height: 180px;
    border: 1px solid black;
    border-radius: 15px;
  }
`
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 17px;
  @media (max-width: 415px) {
    border-radius: 12px;
  }
  @media (max-width: 412px) {
    border-radius: 12px;
  }
  @media (max-width: 394px) {
    border-radius: 13px;
  }
  @media (max-width: 376px) {
    border-radius: 14px;
  }
`
const VideoImg = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 17px;
  @media (max-width: 415px) {
    border-radius: 12px;
  }
  @media (max-width: 412px) {
    border-radius: 12px;
  }
  @media (max-width: 394px) {
    border-radius: 13px;
  }
  @media (max-width: 376px) {
    border-radius: 14px;
  }
`

const StyledLogo = styled.img`
  border-radius: 17px;
  margin-top: 70px;
  margin-bottom: 70px;
`
const StyledInput = styled.input`
  display: none;
`

const ButtonLeft = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: transparent;
  border: none;
  padding: 0;
  transition: 0.5s;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transform: rotate(180deg);
  &:hover {
    opacity: 1;
  }
  @media (max-width: 1300px) {
    opacity: 1;
  }
`
const ButtonRight = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 1300px) {
    opacity: 1;
  }
`
const StyledCounter = styled.p`
  position: absolute;
  font-size: 25px;
  mix-blend-mode: difference;
  font-family: Inter, sans-serif;
  font-weight: 600;
  margin-left: 555px;
  margin-bottom: 280px;
  color: white;
  @media (max-width: 1025px) {
    margin-left: 405px;
    margin-bottom: 215px;
  }
  @media (max-width: 913px) {
    margin-left: 430px;
    margin-bottom: 230px;
  }
  @media (max-width: 600px) {
    margin-left: 405px;
    margin-bottom: 220px;
  }
  @media (max-width: 450px) {
    margin-left: 305px;
    margin-bottom: 160px;
  }
  @media (max-width: 415px) {
    margin-left: 290px;
    margin-bottom: 135px;
  }
  @media (max-width: 391px) {
    margin-left: 280px;
    margin-bottom: 135px;
  }
  @media (max-width: 376px) {
    margin-left: 270px;
    margin-bottom: 150px;
  }
`
