import { createSlice } from '@reduxjs/toolkit'
import LocalStorageFunc from '../utils/helpers/LocalStorageFunc'

const initialState = LocalStorageFunc({ method: 'get', key: 'lang' }) || {
  ru: false,
  en: false,
  kyr: true,
}
export const Translator = createSlice({
  name: 'translator',
  initialState,
  reducers: {
    changeToRusssian(state) {
      state.ru = true
      state.en = false
      state.kyr = false
      LocalStorageFunc({ method: 'set', key: 'lang', body: state })
    },
    changeToEnglish(state) {
      state.ru = false
      state.en = true
      state.kyr = false
      LocalStorageFunc({ method: 'set', key: 'lang', body: state })
    },
    changeToKyrzgyz(state) {
      state.ru = false
      state.en = false
      state.kyr = true
      LocalStorageFunc({ method: 'set', key: 'lang', body: state })
    },
  },
})
