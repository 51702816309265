/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  deleteAdminTouristsMain,
  getAdminTouristsMain,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../UI/SnackBar'
import TextCard from '../../UI/TextCard'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminForTourists = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const {
    touristsSnackState,
    touristsAddStatus,
    touristsEditStatus,
    touristsDeleteStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  const state = useSelector((state) => state.admin)

  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminTouristsMain())
  }, [])
  const deleteAdminForToursts = () => {
    dispatch(deleteAdminTouristsMain({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const navigate = useNavigate()
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.touristsAddSnackBar({
        touristsSnackState: false,
        touristsAddStatus: null,
      })
    )
    dispatch(
      adminAction.touristsEditSnackBar({
        touristsSnackState: false,
        touristsEditStatus: null,
      })
    )
    dispatch(
      adminAction.touristsDeleteSnackBar({
        touristsSnackState: false,
        touristsDeleteStatus: null,
      })
    )
  }

  const navToEditPage = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  const navToAddPage = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const navToHotelsPage = () => {
    navigate('hotels')
    closeSnackBarHandler()
  }
  const navToAttractionsPage = () => {
    navigate('attractions')
    closeSnackBarHandler()
  }
  const navToCafesPage = () => {
    navigate('cafes')
    closeSnackBarHandler()
  }
  return (
    <Container>
      {ModalState && (
        <ModalDeleteContent
          variant='tourist'
          Cancellation={Cancellation}
          deletedFunction={deleteAdminForToursts}
        />
      )}
      {touristsSnackState && (
        <StyledSnackBarDiv>
          <SnackBar
            message={
              touristsAddStatus === 'success'
                ? (translator.ru && ru.photoAddSaccess) ||
                  (translator.en && en.photoAddSaccess) ||
                  (translator.kyr && kyr.photoAddSaccess)
                : touristsEditStatus === 'success'
                ? (translator.ru && ru.photoEditSaccess) ||
                  (translator.en && en.photoEditSaccess) ||
                  (translator.kyr && kyr.photoEditSaccess)
                : touristsDeleteStatus === 'success'
                ? (translator.ru && ru.photoDeleteSaccess) ||
                  (translator.en && en.photoDeleteSaccess) ||
                  (translator.kyr && kyr.photoDeleteSaccess)
                : (translator.ru && ru.messageError) ||
                  (translator.en && en.messageError) ||
                  (translator.kyr && en.messageError)
            }
            variant={
              touristsAddStatus || touristsEditStatus || touristsDeleteStatus
            }
            open={touristsSnackState}
            handleClose={closeSnackBarHandler}
          />
        </StyledSnackBarDiv>
      )}
      <DivTextCard>
        <TextCard onClick={navToHotelsPage} variant='hotels' />
        <TextCard onClick={navToAttractionsPage} variant='attractions' />
        <TextCard onClick={navToCafesPage} variant='cafes' />
      </DivTextCard>

      {state.tourists.horizontal && state.tourists.vertical ? (
        <StyledDivContainer>
          <div>
            {state.tourists.horizontal?.map((element) => (
              <DivContainerImg key={element.id}>
                <StyledDivImg bg={element.img}>
                  <Button
                    onClick={() => deletedHandler(element.id)}
                    variant='delete-button-icon'
                  />
                  <StyledEditDivButton>
                    <Button
                      variant='edit-button'
                      onClick={() => navToEditPage(element.id)}
                    />
                  </StyledEditDivButton>
                </StyledDivImg>
              </DivContainerImg>
            ))}
          </div>
          <div>
            {state.tourists.vertical?.map((element) => (
              <DivContainerImg key={element.id}>
                <StyledDivImg variant='vertical' bg={element.img}>
                  <Button
                    onClick={() => deletedHandler(element.id)}
                    variant='delete-button-icon'
                  />
                  <StyledEditDivButton>
                    <Button
                      variant='edit-button'
                      onClick={() => navToEditPage(element.id)}
                    />
                  </StyledEditDivButton>
                </StyledDivImg>
              </DivContainerImg>
            ))}
          </div>
        </StyledDivContainer>
      ) : (
        <StyledDivText>
          <DataNotFoundTranslator variant='tourists' />
        </StyledDivText>
      )}
      <AddButton>
        <Button onClick={navToAddPage} variant='add-faq'>
          <TranslatorDescription variant='add' />
        </Button>
      </AddButton>
    </Container>
  )
}

export default AdminForTourists
const Container = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
`
const StyledEditDivButton = styled.div`
  display: flex;
  padding: 4px 28px;
`
const DivContainerImg = styled.div`
  height: max-content;
`
const DivTextCard = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
`
const StyledDivContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 431px) {
    display: flex;
    flex-direction: column;
  }
`
const StyledDivImg = styled.div`
  width: ${(props) => (props.variant === 'vertical' ? '400px' : '700px')};
  height: ${(props) => (props.variant === 'vertical' ? '475px' : '350px')};
  display: flex;
  padding: 10px 10px;
  margin-left: 10px;
  margin-top: 20px;
  border-radius: 30px;
  background-size: cover;
  background-image: url(${(props) => props.bg});
  @media (max-width: 1280px) {
    width: 850px;
    height: 300px;
    display: flex;
    margin-left: 4px;
  }
  @media (max-width: 1024px) {
    width: 620px;
    height: 50vh;
    margin-left: 4px;
  }
  @media (max-width: 912px) {
    width: 900px;
    height: 400px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 820px) {
    width: 816px;
    height: 350px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 768px) {
    width: 764px;
    height: 350px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 540px) {
    width: 536px;
    height: 300px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 415px) {
    width: 410px;
    height: 200px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 413px) {
    width: 407px;
    height: 200px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 394px) {
    width: 390px;
    height: 200px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 391px) {
    width: 387px;
    height: 200px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 376px) {
    width: 370px;
    height: 200px;
    display: flex;
    margin-left: 2px;
  }
  @media (max-width: 360px) {
    width: 356px;
    height: 200px;
    display: flex;
    margin-left: 2px;
  }
`
const AddButton = styled.div`
  margin: 25px 0 25px 0;
  display: flex;
  justify-content: end;
`
const StyledSnackBarDiv = styled.div`
  position: absolute;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
