import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  deleteAdminContacts,
  putAdminContacts,
} from '../../../services/admin/AdminAction'
import Button from '../../UI/Button'
import Input from '../../UI/Inputs'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminContactsEdit = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [input, setInput] = useState({
    name: '',
    surname: '',
    number: '',
    email: '',
    job: '',
  })
  const [ModalState, setModalState] = useState(false)

  const nameInputHandler = (event) => {
    setInput({
      ...input,
      name: event.target.value,
    })
  }
  const surnameInputHandler = (event) => {
    setInput({
      ...input,
      surname: event.target.value,
    })
  }
  const numberInputHandler = (event) => {
    setInput({
      ...input,
      number: event.target.value,
    })
  }
  const emailInputHandler = (event) => {
    setInput({
      ...input,
      email: event.target.value,
    })
  }
  const jobInputHandler = (event) => {
    setInput({
      ...input,
      job: event.target.value,
    })
  }
  const editContact = () => {
    if (
      input.name !== '' &&
      input.number !== '' &&
      input.email !== '' &&
      input.surname !== '' &&
      input.job !== ''
    ) {
      const body = {
        firstName: input.name,
        lastName: input.surname,
        number: input.number,
        email: input.email,
        jobTitle: input.job,
      }
      dispatch(putAdminContacts({ body, id }))
      navigate('/contacts')
    }
  }
  const deleteContact = () => {
    dispatch(deleteAdminContacts({ id }))
    navigate('/contacts')
    setModalState(false)
  }
  const deletedHandler = () => {
    setModalState(true)
  }
  const Cancellation = () => {
    setModalState(false)
  }

  return (
    <Container>
      {ModalState && (
        <ModalDeleteContent
          variant='Contact'
          Cancellation={Cancellation}
          deletedFunction={deleteContact}
        />
      )}
      <Bg>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />
        <StyledDivContainer>
          <Div1>
            <Inputs>
              <P>
                <TranslatorDescription variant='names' />
              </P>
              <Input
                tipe='input'
                variant='name'
                value={input.name}
                onChange={nameInputHandler}
              />
              <P>
                <TranslatorDescription variant='surname' />
              </P>
              <Input
                tipe='input'
                variant='name'
                value={input.surname}
                onChange={surnameInputHandler}
              />
              <P>
                <TranslatorDescription variant='number' />
              </P>
              <Input
                tipe='input'
                variant='name'
                value={input.number}
                onChange={numberInputHandler}
              />
              <P>
                <TranslatorDescription variant='mail' />
              </P>
              <Input
                tipe='input'
                variant='name'
                value={input.email}
                onChange={emailInputHandler}
              />
              <P>
                <TranslatorDescription variant='job-title' />
              </P>
              <Input
                tipe='input'
                variant='name'
                value={input.job}
                onChange={jobInputHandler}
              />
            </Inputs>
            <ContainerDivButton>
              <Button onClick={deletedHandler} variant='delete-button'>
                <TranslatorDescription variant='delete' />
              </Button>
              <DivButton>
                <Button onClick={editContact} variant='button1'>
                  <TranslatorDescription variant='save' />
                </Button>
              </DivButton>
            </ContainerDivButton>
          </Div1>
        </StyledDivContainer>
      </Bg>
    </Container>
  )
}

export default AdminContactsEdit
const Container = styled.div`
  width: 100%;
  margin-left: 30px;
  margin-top: 30px;
  padding: 0 0 20px 0;
  @media (max-width: 1300px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  margin-bottom: 30px;
`
const Bg = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 5px 20px 5px;
  width: 900px;
  height: 630px;
  @media (max-width: 1300px) {
    width: 95%;
  }
`
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
`
const StyledDivContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;

  @media (max-width: 1281px) {
    margin: 0;
    width: 870px;
    margin-left: 20px;
    margin-top: 35px;
  }
  @media (max-width: 1025px) {
    margin: 0;
    width: 600px;
    margin-left: 20px;
    margin-top: 25px;
  }
  @media (max-width: 913px) {
    margin: 0;
    width: 870px;
    margin-left: 20px;
    margin-top: 25px;
  }
  @media (max-width: 891px) {
    margin: 0;
    width: 770px;
    margin-left: 20px;
    margin-top: 20px;
  }
  @media (max-width: 769px) {
    margin: 0;
    width: 730px;
    margin-left: 19px;
    margin-top: 20px;
  }
  @media (max-width: 541px) {
    margin: 0;
    width: 500px;
    margin-top: 30px;
    margin-left: 20px;
  }
  @media (max-width: 431px) {
    margin: 0;
    width: 390px;
    margin-top: 20px;
  }
  @media (max-width: 416px) {
    margin: 0;
    width: 370px;
    margin-left: 20px;
    margin-top: 30px;
  }
  @media (max-width: 391px) {
    margin: 0;
    width: 370px;
    margin-left: 10px;
    margin-top: 25px;
  }
  @media (max-width: 394px) {
    margin: 0;
    width: 370px;
    margin-left: 10px;
    margin-top: 20px;
  }
  @media (max-width: 376px) {
    margin: 0;
    width: 350px;
    height: 550px;
    margin-left: 12px;
    margin-top: 25px;
  }
  @media (max-width: 361px) {
    margin: 0;
    width: 320px;
    margin-left: 20px;
    margin-top: 35px;
  }
`
const Inputs = styled.div`
  margin-top: 10px;
  @media (max-width: 376px) {
    margin: 0;
  }
  @media (max-width: 415px) {
    margin: 0;
  }
`
const P = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  margin-top: 4px;
`

const ContainerDivButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`
const DivButton = styled.div`
  margin-left: 10px;
  margin-top: 9px;
  @media (max-width: 376px) {
    margin: 0;
    margin-top: 10px;
    margin-left: 10px;
  }
`
const Div1 = styled.div``
