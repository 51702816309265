import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { getUserTouristCafes } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Card from '../UI/Card'
import TextCard from '../UI/TextCard'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserRestaurantsAndCafes = () => {
  const state = useSelector((state) => state.user)
  const navigate = useNavigate()
  const [count, setCount] = useState(6)
  const dispatch = useDispatch()
  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getUserTouristCafes({ count }))
  }, [count])
  const cafes = (el) => {
    navigate(`${el?.id}`)
  }
  return (
    <StyledDivContainer>
      <StyledDivTextCard>
        <TextCard variant='cafes' colorVariant='blue' />
      </StyledDivTextCard>
      {state.touristCafes.length > 0 ? (
        <StyledDivContainerCard>
          {state.touristCafes?.map((element) => (
            <StyledDivCard key={element.id}>
              <Card
                variant='tourists-page'
                img={element.img}
                title={element.title}
                description={element.description}
                date={element.date}
                senderText={element.senderText}
                sender={element.sender}
                onAttractions={() => cafes(element)}
              />
            </StyledDivCard>
          ))}
          {state.touristCafes.length >= 6 && (
            <Mybtn>
              <Button variant='button1' onClick={countHandler}>
                <TranslatorDescription variant='more' />
              </Button>
            </Mybtn>
          )}
        </StyledDivContainerCard>
      ) : (
        <StyledDivText>
          <DataNotFoundTranslator variant='' />
        </StyledDivText>
      )}
    </StyledDivContainer>
  )
}

export default UserRestaurantsAndCafes

const StyledDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1300px) {
    width: 800px;
    margin-left: 30px;
  }
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 1100px) {
    width: 500px;
  }
  @media (max-width: 912px) {
    width: 780px;
  }
  @media (max-width: 800px) {
    width: 680px;
  }
  @media (max-width: 700px) {
    width: 580px;
  }
  @media (max-width: 600px) {
    width: 480px;
  }
  @media (max-width: 500px) {
    width: 350px;
  }
  @media (max-width: 400px) {
    margin: 0;
    width: 100%;
    margin-left: 20px;
  }
  @media (max-width: 376px) {
    width: 100%;
    margin-left: 4px;
  }
`

const StyledDivTextCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`
const StyledDivContainerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledDivCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
`
const Mybtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
