/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import archiveIcon from '../../../assets/archive.png'
import {
  getAdminAppeals,
  getAdminAppealsArchive,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import { SnackBar } from '../../UI/SnackBar'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'

const AdminAppealOfCitizens = () => {
  const ru = russian
  const en = english
  const kyr = kyrgyz

  const dispatch = useDispatch()
  const state = useSelector((state) => state.admin)

  const {
    appealsState,
    appealsSendFAQStatus,
    appealsSendStatus,
    appealsIgnoreStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  useEffect(() => {
    dispatch(getAdminAppeals())
    dispatch(getAdminAppealsArchive())
  }, [])
  const navigate = useNavigate()
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.AppealsSendFAQStatus({
        appealsState: false,
        appealsSendFAQStatus: null,
      })
    )
    dispatch(
      adminAction.AppealsSendSnackBar({
        appealsState: false,
        appealsSendStatus: null,
      })
    )
    dispatch(
      adminAction.AppealsIgnoreSnackBar({
        appealsState: false,
        appealsIgnoreStatus: null,
      })
    )
  }

  const responseToAppeal = (id) => {
    navigate(`response/${id}`)
  }
  const navToArchive = () => {
    navigate('archive')
  }
  return (
    <StyledMain>
      {appealsState && (
        <SnackBar
          message={
            appealsSendFAQStatus === 'success'
              ? (translator.ru && ru.snackBar.dataSentSaccess) ||
                (translator.en && en.snackBar.dataSentSaccess) ||
                (translator.kyr && kyr.snackBar.dataSentSaccess)
              : appealsSendStatus === 'success'
              ? (translator.ru && ru.snackBar.dataSentSaccess) ||
                (translator.en && en.snackBar.dataSentSaccess) ||
                (translator.kyr && kyr.snackBar.dataSentSaccess)
              : appealsIgnoreStatus === 'success'
              ? (translator.ru && ru.snackBar.messageDeleteSaccess) ||
                (translator.en && en.snackBar.messageDeleteSaccess) ||
                (translator.kyr && kyr.snackBar.messageDeleteSaccess)
              : (translator.ru && ru.snackBar.messageError) ||
                (translator.en && en.snackBar.messageError) ||
                (translator.kyr && en.snackBar.messageError)
          }
          variant={
            appealsSendFAQStatus || appealsSendStatus || appealsIgnoreStatus
          }
          open={appealsState}
          handleClose={closeSnackBarHandler}
        />
      )}
      {state.appealsArchive?.length >= 1 && (
        <Archive onClick={navToArchive} src={archiveIcon} alt='none' />
      )}
      {state.appeals?.length >= 1 ? (
        <Bg>
          {state.appeals?.map((element) => (
            <StyledDiv>
              <P onClick={() => responseToAppeal(element.id)}>
                {`${element.id}.`}
                {element.description}
              </P>
            </StyledDiv>
          ))}
        </Bg>
      ) : (
        <StyledDivText>
          <DataNotFoundTranslator variant='' />
        </StyledDivText>
      )}
    </StyledMain>
  )
}
export default AdminAppealOfCitizens

const StyledMain = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 20px;
  font-family: Inter, sans-serif;
`
const Bg = styled.div`
  width: 850px;
  height: max-content;
  background: #fff;
  height: max-content;
  border-radius: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  margin-left: 20px;
  margin-top: 30px;
  padding-bottom: 10px;
  @media (max-width: 1300px) {
    width: 800px;
    margin-left: 50px;
  }
  @media (max-width: 1100px) {
    width: 600px;
    margin-left: 20px;
  }
  @media (max-width: 913px) {
    width: 850px;
    margin-left: 40px;
  }
  @media (max-width: 780px) {
    width: 600px;
  }
  @media (max-width: 600px) {
    width: 500px;
    margin-left: 20px;
  }
  @media (max-width: 450px) {
    width: 400px;
    margin-left: 10px;
  }
  @media (max-width: 391px) {
    width: 360px;
    margin-left: 10px;
  }
`
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  padding: 5px 20px 0px 0px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  @media (max-width: 415px) {
    font-size: 15px;
  }
`
const P = styled.p`
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
`
const Archive = styled.img`
  width: 40px;
  cursor: pointer;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
