import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

export const Backdrop = ({ onClose }) => {
  return <StyledBackdrop onClick={onClose} />
}
export const ModalContent = ({ children }) => {
  return <StyledModalContent>{children}</StyledModalContent>
}

const BackdropRoot = document.getElementById('backdrop')
const ModalRoot = document.getElementById('modal')

const Modall = ({ children, onClose }) => {
  return (
    <>
      {createPortal(<Backdrop onClose={onClose} />, BackdropRoot)}
      {createPortal(<ModalContent>{children}</ModalContent>, ModalRoot)}
    </>
  )
}

export default Modall

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 0, 0, 0.5);
  z-index: 20;
`
const StyledModalContent = styled.div`
  position: fixed;
  top: 16vw;
  left: 30vw;
  width: 600px;
  height: 300px;
  border-radius: 20px;
  background-color: rgba(242, 242, 242, 1);
  z-index: 30;
  @media (max-width: 1025px) {
    top: 16vw;
    left: 20vw;
  }
  @media (max-width: 913px) {
    top: 30vw;
    left: 16vw;
  }
  @media (max-width: 821px) {
    top: 30vw;
    left: 12vw;
  }
  @media (max-width: 541px) {
    top: 30vw;
    left: 13vw;
    width: 400px;
  }
  @media (max-width: 431px) {
    top: 50vw;
    left: 10vw;
    width: 350px;
  }
  @media (max-width: 415px) {
    left: 7vw;
  }
  @media (max-width: 394px) {
    left: 5vw;
  }
  @media (max-width: 376px) {
    top: 40vw;
    left: 10vw;
    width: 300px;
  }
`
