/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiFetch, ApiFile } from '../../api/Fetch'
import {
  calendarEventUrl,
  getUserCurrentHotelsUrl,
  calendarUrl,
  userCityHall,
  userHotelsUrl,
  TouristCurrentCafeUrl,
  getUserContactsUrl,
  getUserTouristCafesUrl,
  userFaq,
  userTurist,
  userMainPage,
  userMunicipal,
  getAttractionUrl,
  userAppealOfCitizenURL,
} from '../../utils/constants/url'

// NEWS
export const getUserNews = createAsyncThunk(
  'userNews/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: `api/v1/news/for/user/find/get/news?count=${props.count}`,
      })
      const news = []
      for (let i = 0; i < response.length; i++) {
        news.push({
          id: response[i].id,
          title: response[i].title,
          img: response[i].image,
          description: response[i].description,
          date: response[i].dateCreate,
        })
      }
      return { userNews: news.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// NEWS
// CALENDAR
export const getUserCalendar = createAsyncThunk(
  'userCalendar/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: calendarUrl,
      })
      const calendar = []
      for (let i = 0; i < response.length; i++) {
        calendar.push({
          id: response[i].id,
          month: response[i].month,
          day: response[i].day,
          year: response[i].year,
        })
      }
      return { calendar }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getUserCalendarEvent = createAsyncThunk(
  'userCalenderEvent/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: calendarEventUrl + props.id,
      })
      return { calendarEvent: response }
    } catch (error) {
      rejectWithValue(error.message)
    }
  }
)
// CALENDAR
// tourist Hotels
export const getUserHotels = createAsyncThunk(
  'userHotels/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: userHotelsUrl + props.count,
      })
      const hotels = []
      for (let i = 0; i < response.length; i++) {
        hotels.push({
          id: response[i].id,
          title: response[i].title,
          img: response[i].image,
          description: response[i].description,
        })
      }
      return { hotels }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getUserCurrentHotels = createAsyncThunk(
  'userCurrentHotels/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getUserCurrentHotelsUrl + props.id,
      })
      return { userCurrentHotel: response }
    } catch (error) {
      rejectWithValue(error.message)
    }
  }
)
// tourist Hotels
// tourist attraction
export const getUserTouristAttractons = createAsyncThunk(
  'userAttractions/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAttractionUrl + props.count,
      })
      const attractions = []
      for (let i = 0; i < response.length; i++) {
        attractions.push({
          id: response[i].id,
          title: response[i].title,
          img: response[i].image,
          description: response[i].description,
        })
      }
      return { attractions }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getUserTouristAttractonsById = createAsyncThunk(
  'userAttractionsById/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: `pride/user/api/find/by/id/pride?prideId=${props.id}`,
      })
      return { currentAttractions: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// tourist attraction
// tourist Cafe
export const getUserTouristCurrentCafe = createAsyncThunk(
  'userTouristCurrentCafe/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: TouristCurrentCafeUrl + props.id,
      })
      return { currentCafes: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getUserTouristCafes = createAsyncThunk(
  'userTouristCafes/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getUserTouristCafesUrl + props.count,
      })
      const cafes = []
      for (let i = 0; i < response.length; i++) {
        cafes.push({
          id: response[i].id,
          title: response[i].title,
          img: response[i].image,
          description: response[i].description,
        })
      }
      return { cafes }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// tourist Cafe
// CONTACTS
export const getUserContacts = createAsyncThunk(
  'userContacts/get',
  async (rejectWithValue) => {
    try {
      const response = await ApiFetch({
        url: getUserContactsUrl,
      })
      const contactsData = []
      for (let i = 0; i < response.length; i++) {
        contactsData.push({
          id: response[i].id,
          fullName: response[i].fullName,
          number: response[i].number,
          email: response[i].email,
          jobTitle: response[i].jobTitle,
        })
      }
      return { contactsData }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// CONTACTS
// QUICK
export const getUserFaqs = createAsyncThunk(
  'userFaqs/get',
  async (rejectWithValue) => {
    try {
      const response = await ApiFetch({
        url: userFaq,
      })
      const faqsData = []
      for (let i = 0; i < response.length; i++) {
        faqsData.push({
          id: response[i].appealId,
          title: response[i].description,
          answer: response[i].answer,
        })
      }
      return { faqsData }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// QUICK
// TURIST
export const getUserTurists = createAsyncThunk(
  'turists/get',
  async (rejectWithValue) => {
    try {
      const response = await ApiFetch({
        url: userTurist,
      })
      const vertical = []
      const horizontal = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].id % 2 !== 0) {
          vertical.push({
            id: response[i].id,
            img: response[i].image,
          })
        }
        if (response[i].id % 2 === 0) {
          horizontal.push({
            id: response[i].id,
            img: response[i].image,
          })
        }
      }
      return { vertical, horizontal }
    } catch (error) {
      rejectWithValue(error.message)
    }
  }
)

// Municipal Programs
export const getUserMunicipal = createAsyncThunk(
  'userMunicipal/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: userMunicipal + props.count,
      })
      const municipalData = []
      for (let i = 0; i < response.length; i++) {
        municipalData.push({
          id: response[i].id,
          title: response[i].title,
          description: response[i].description,
          image: response[i].image,
        })
      }
      return { userMunicipal: municipalData }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// Municipal Programs
// USERMAINPAGE
export const getUserMainPage = createAsyncThunk(
  'userMainPage/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: userMainPage + props.count,
      })
      const mainPageData = []
      for (let i = 0; i < response.length; i++) {
        mainPageData.push({
          id: response[i].id,
          title: response[i].title,
          description: response[i].description,
          image: response[i].photo,
        })
      }
      return { mainPageData }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// USERMAINPAGE
// CITYHALL
export const getUserCityHall = createAsyncThunk(
  'cityHall/get',
  async (rejectWithValue) => {
    try {
      const response = await ApiFetch({
        url: userCityHall,
      })
      const cityHallData = []
      for (let i = 0; i < response.length; i++) {
        cityHallData.push({
          id: response[i].id,
          title: response[i].title,
          description: response[i].description,
          nowDate: response[i].date,
        })
      }
      return { city: cityHallData }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// APPEALOFCITIZEN
export const postUserAppealOfCitizen = createAsyncThunk(
  'userAppealOfCitizen/post',
  async (props, { rejectWithValue }) => {
    try {
      await ApiFile({
        url: `${userAppealOfCitizenURL}name=${props.name}&number=${props.number}&description=${props.description}`,
        method: 'POST',
        body: props.body,
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// TURIST
// CITYHALL
