import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getUserFaqs } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Quick from '../UI/Quick'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserQuestion = () => {
  const state = useSelector((state) => state.user)
  const [selectedQuestions, setSelectedQuestions] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const backToPrevPage = () => {
    navigate('/appeals-of-citizens')
  }
  useEffect(() => {
    dispatch(getUserFaqs())
  }, [])
  return (
    <Container>
      <StyledDivContainer>
        {state.faqs.length > 0 ? (
          <StyledDivQuestion>
            <Quick
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
              quickDatas={state?.faqs}
            />
          </StyledDivQuestion>
        ) : (
          <StyledDivText>
            <DataNotFoundTranslator variant='' />
          </StyledDivText>
        )}
        <StyledDivButton>
          <Button onClick={backToPrevPage} variant='show-button'>
            <TranslatorDescription variant='userquestion' />
          </Button>
        </StyledDivButton>
      </StyledDivContainer>
    </Container>
  )
}

export default UserQuestion

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const StyledDivContainer = styled.div`
  margin-top: 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  height: 100%;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  @media (max-width: 913px) {
    margin-left: 65px;
  }
  @media (max-width: 541px) {
    margin-left: 35px;
  }
  @media (max-width: 431px) {
    margin-left: 35px;
  }
  @media (max-width: 394px) {
    margin-left: 27px;
  }
`
const StyledDivQuestion = styled.div`
  width: 70%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid rgba(10, 0, 130, 1);
  border-radius: 15px;
  padding: 10px 30px 10px 15px;
  @media (max-width: 1300px) {
    width: 90%;
    height: 100%;
    padding: 10px 30px 10px 15px;
  }
`
const StyledDivButton = styled.div`
  margin-left: 50px;
  height: 99%;
  display: flex;
  align-items: end;
  width: 15%;
  justify-content: end;
  @media (max-width: 1300px) {
    margin: 0;
    width: 90%;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
