/* eslint-disable no-nested-ternary */
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { english } from '../../utils/constants/lang/english'
import { kyrgyz } from '../../utils/constants/lang/kyrgyz'
import { russian } from '../../utils/constants/lang/russian'

const Sidebar = ({ closeSidebar }) => {
  const ru = russian.sidebar
  const en = english.sidebar
  const kyr = kyrgyz.sidebar
  const state = useSelector((state) => state.translator)
  return (
    <>
      <Backdrop onClick={closeSidebar} />
      <StyledSidebar>
        <NavLink
          to='/'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.main}
            {state.en && en.main}
            {state.kyr && kyr.main}
          </SideText>
        </NavLink>
        <NavLink
          to='/news'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.news}
            {state.en && en.news}
            {state.kyr && kyr.news}
          </SideText>
        </NavLink>
        <NavLink
          to='/faq'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.faq}
            {state.en && en.faq}
            {state.kyr && kyr.faq}
          </SideText>
        </NavLink>
        <NavLink
          to='/about-of-city-hall'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.about}
            {state.en && en.about}
            {state.kyr && kyr.about}
          </SideText>
        </NavLink>
        <NavLink
          to='/appeals-of-citizens'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.appeals}
            {state.en && en.appeals}
            {state.kyr && kyr.appeals}
          </SideText>
        </NavLink>
        <NavLink
          to='/for-tourists'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.tourists}
            {state.en && en.tourists}
            {state.kyr && kyr.tourists}
          </SideText>
        </NavLink>
        <NavLink
          to='/contacts'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.contacts}
            {state.en && en.contacts}
            {state.kyr && kyr.contacts}
          </SideText>
        </NavLink>
        <NavLink
          to='/calendar'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.calendar}
            {state.en && en.calendar}
            {state.kyr && kyr.calendar}
          </SideText>
        </NavLink>
        <NavLink
          to='/municipal-programs'
          style={({ isActive }) =>
            isActive ? { textDecoration: '' } : { textDecoration: 'none' }
          }
        >
          <SideText>
            {state.ru && ru.municipal}
            {state.en && en.municipal}
            {state.kyr && kyr.municipal}
          </SideText>
        </NavLink>
      </StyledSidebar>
    </>
  )
}

export default Sidebar

const Backdrop = styled.div`
  display: none;
  @media (max-width: 913px) {
    display: flex;
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
`
const StyledSidebar = styled.div`
  width: 370px;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 35px;
  user-select: none;
  @media (max-width: 913px) {
    position: fixed;
    bottom: 1px;
    width: 390px;
    height: max-content;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
  @media (max-width: 541px) {
    width: 320px;
  }
`
const SideText = styled.p`
  width: 340px;
  padding: 15px 0px 15px 5px;
  border-radius: 10px;
  color: #0a0082;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s;
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 132, 255, 0.2);
  }
  @media (max-width: 913px) {
    font-size: 25px;
    width: 360px;
    margin-bottom: 20px;
  }
  @media (max-width: 541px) {
    font-size: 18px;
    width: 290px;
  }
`
