import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import strelka from '../../assets/icons/strelka.svg'
import { getUserCalendarEvent } from '../../services/User/UserAction'
import Card from '../UI/Card'

const UserCalendarEventPages = () => {
  const state = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const backToPrevPage = () => {
    navigate(-1)
  }
  const { id } = useParams()
  useEffect(() => {
    dispatch(getUserCalendarEvent({ id }))
  }, [])
  return (
    <StyledDivContainer>
      <StyledDivTextCard>
        <Img onClick={backToPrevPage} src={strelka} alt='error strelka.svg' />
      </StyledDivTextCard>
      <StyledDivContainerCard>
        <StyledDivCard key={state.calendarEvent?.id}>
          <Card
            variant='calendar-page'
            description={state.calendarEvent?.description}
          />
        </StyledDivCard>
      </StyledDivContainerCard>
    </StyledDivContainer>
  )
}

export default UserCalendarEventPages

const StyledDivContainer = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1300px) {
    width: 800px;
  }
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 1100px) {
    width: 500px;
  }
  @media (max-width: 912px) {
    width: 780px;
  }
  @media (max-width: 800px) {
    width: 680px;
  }
  @media (max-width: 700px) {
    width: 580px;
  }
  @media (max-width: 600px) {
    width: 480px;
  }
  @media (max-width: 500px) {
    width: 380px;
  }
  @media (max-width: 400px) {
    width: 100%;
    margin-left: 20px;
  }
`
const StyledDivTextCard = styled.div`
  margin-left: 40px;
  margin-top: 25px;
`
const Img = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`

const StyledDivContainerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledDivCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 55px;
  margin-top: 18px;
`
