import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../components/UI/Footer'
import Header from '../components/UI/Header'
import ScrollUp from '../components/UI/ScrollUp'
import Sidebar from '../components/UI/Sidebar'

const UserLayout = () => {
  const navigate = useNavigate()
  const [burger, setBurger] = useState(false)
  const onBurger = () => {
    setBurger((prev) => !prev)
  }
  const closeSideBar = () => {
    setBurger(false)
  }
  const navAuth = () => {
    navigate('/auth_$132')
  }
  return (
    <Container>
      <Header onClickBurger={onBurger} navAuth={navAuth} />
      <StyledMain>
        <SideBlock>
          <Sidebar />
        </SideBlock>
        {burger && <Sidebar closeSidebar={closeSideBar} />}
        <StyledOutlet>
          <Outlet />
        </StyledOutlet>
      </StyledMain>
      <ScrollUp />
      <Footer />
    </Container>
  )
}

export default UserLayout

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const StyledMain = styled.div`
  display: flex;
`
const StyledOutlet = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const SideBlock = styled.div`
  display: flex;
  @media (max-width: 913px) {
    display: none;
  }
`
