/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { postUserAppealOfCitizen } from '../../services/User/UserAction'
import { userAction } from '../../services/User/UserSlice'
import { english } from '../../utils/constants/lang/english'
import { kyrgyz } from '../../utils/constants/lang/kyrgyz'
import { russian } from '../../utils/constants/lang/russian'
import Button from '../UI/Button'
import Gallery from '../UI/Gallery'
import Input from '../UI/Inputs'
import { SnackBar } from '../UI/SnackBar'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserAppealCitizen = () => {
  const ru = russian
  const en = english
  const kyr = kyrgyz

  const { AppealSnackState, AppealUploadStatus } = useSelector(
    (state) => state.user
  )

  const translator = useSelector((state) => state.translator)

  const [files, setFiles] = useState(null)
  const [, setFormatImg] = useState(null)

  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [description, setDescription] = useState('')

  const [filesData, setFilesData] = useState([])

  const [formatDataImg, setFormatDataImg] = useState([])
  const [formatDataVideo, setFormatDataVideo] = useState([])

  const fileGroup = useRef(null)
  const fileVideo = useRef(null)

  const dispatch = useDispatch()
  const userAppealOfCitizenHandler = () => {
    if (
      name.trim() !== '' &&
      number.trim() !== '' &&
      description.trim() !== '' &&
      files !== null
    ) {
      const formData = new FormData()
      if (formatDataImg.length >= 1) {
        for (let i = 0; i < formatDataImg.length; i++) {
          formData.append('photo', formatDataImg[i])
        }
      }
      if (formatDataVideo.length >= 1) {
        for (let i = 0; i < formatDataVideo.length; i++) {
          formData.append('video', formatDataVideo[i])
        }
      }
      dispatch(
        postUserAppealOfCitizen({ body: formData, name, number, description })
      )
    }
  }
  const changeHandler = (event) => {
    const file = event.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setFilesData([...filesData, { img: url, format: file }])
      setFiles(url)
      setFormatDataImg([...formatDataImg, file])
    }
  }
  const changeVideoHandler = (event) => {
    const file = event.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setFilesData([...filesData, { video: url, formatVideo: file }])
      setFiles(url)
      setFormatDataVideo([...formatDataVideo, file])
    }
  }
  const handleFile = () => {
    fileGroup.current.click()
  }
  const videoClick = () => {
    fileVideo.current.click()
  }
  const nameChangeHandler = (event) => {
    setName(event.target.value)
  }
  const numChangeHandler = (event) => {
    setNumber(event.target.value)
  }
  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value)
  }
  const closeSnackBarHandler = () => {
    dispatch(
      userAction.AppealUploadSnackBar({
        AppealSnackState: false,
        AppealAddStatus: null,
      })
    )
  }
  return (
    <Block>
      {AppealSnackState && (
        <SnackBar
          message={
            AppealUploadStatus === 'success'
              ? (translator.ru && ru.snackBar.dataSentSaccess) ||
                (translator.en && en.snackBar.dataSentSaccess) ||
                (translator.kyr && kyr.snackBar.dataSentSaccess)
              : (translator.ru && ru.snackBar.messageError) ||
                (translator.en && en.snackBar.messageError) ||
                (translator.kyr && en.snackBar.messageError)
          }
          variant={AppealUploadStatus}
          open={AppealSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      <Container>
        <Card>
          <Gallery
            files={files}
            setFiles={setFiles}
            setFormat={setFormatImg}
            setFilesData={setFilesData}
            filesData={filesData}
            setFormatDataImg={setFormatDataImg}
            formatDataImg={formatDataImg}
          />
        </Card>
        <DIV>
          <DivBtn>
            <Button
              disabled={!filesData.length}
              onClick={videoClick}
              variant='show-button'
            >
              <TranslatorDescription variant='upload-video' />
            </Button>
            <StyledFile
              ref={fileVideo}
              onChange={changeVideoHandler}
              type='file'
              accept='video/*'
            />
          </DivBtn>
          <DivButton>
            <Button onClick={handleFile} variant='show-button'>
              <TranslatorDescription variant='upload-photo' />
            </Button>
            <StyledFile
              ref={fileGroup}
              onChange={changeHandler}
              type='file'
              name='file'
              accept='image/*,.svg,.png,.jpg,.web'
            />
          </DivButton>
        </DIV>
        <DivInput>
          <div>
            <Label1>
              <TranslatorDescription variant='names' />
            </Label1>
            <Input
              onChange={nameChangeHandler}
              tipe='input'
              variant='appeals'
            />
          </div>
          <Email>
            <Label2>
              <TranslatorDescription variant='number-WhatsApp' />
            </Label2>
            <Input
              onChange={numChangeHandler}
              tipe='input'
              variant='appeals'
              type='number'
            />
          </Email>
          <TextDiv>
            <Label3>
              <TranslatorDescription variant='complaint' />
            </Label3>
            <Input
              onChange={descriptionChangeHandler}
              tipe='textarea'
              variant='text'
            />
          </TextDiv>
        </DivInput>
        <Btn>
          <Button
            disabled={
              formatDataImg.length < 1 ||
              formatDataVideo.length < 1 ||
              name === '' ||
              number === '' ||
              description === ''
            }
            onClick={userAppealOfCitizenHandler}
            variant='button2'
          >
            <TranslatorDescription variant='upload-button' />
          </Button>
        </Btn>
      </Container>
    </Block>
  )
}
export default UserAppealCitizen

const Block = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Email = styled.div`
  margin: 20px 0 10px 0;
`
const TextDiv = styled.div`
  margin: 20px 0 10px 0;
`
const Label1 = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 18px;
`
const Label2 = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 18px;
`
const Label3 = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 18px;
`
const Btn = styled.div`
  margin-left: 290px;
  margin-top: 30px;
  margin-bottom: 20px;
  @media (max-width: 1281px) {
    display: flex;
    justify-content: end;
    margin-right: 170px;
  }
`
const Container = styled.div`
  width: 750px;
  margin-top: 30px;
  border: 0.5px solid #0a0082;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 1300px) {
    width: 90%;
  }
`
const DivInput = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
`
const Card = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
`
const DIV = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 1281px) {
    display: flex;
    justify-content: center;
  }
`
const DivBtn = styled.div``
const DivButton = styled.div`
  margin-left: 20px;
  @media (max-width: 821px) {
    margin-left: 40px;
  }
`

const StyledFile = styled.input`
  display: none;
`
