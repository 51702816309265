import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import Logo from '../../assets/headerIcon/Ellipse 13.svg'
import Image from '../../assets/headerIcon/headerIcon.svg'
import { Translator } from '../../services/Translator'
import { english } from '../../utils/constants/lang/english'
import { kyrgyz } from '../../utils/constants/lang/kyrgyz'
import { russian } from '../../utils/constants/lang/russian'

const Header = ({ onClickBurger, navAuth }) => {
  const ru = russian.header
  const en = english.header
  const kyr = kyrgyz.header
  const state = useSelector((state) => state.translator)
  const [lang, setLang] = useState(
    (state.ru && 'ru') || (state.kyr && 'kyr') || (state.en && 'en') || 'kyr'
  )
  const dispatch = useDispatch()
  const langChange = (event) => {
    setLang(event.target.value)
    if (event.target.value === 'ru') {
      dispatch(Translator.actions.changeToRusssian())
    }
    if (event.target.value === 'en') {
      dispatch(Translator.actions.changeToEnglish())
    }
    if (event.target.value === 'kyr') {
      dispatch(Translator.actions.changeToKyrzgyz())
    }
  }
  return (
    <MainHeader>
      <DivImg>
        <StyledImage onClick={onClickBurger} src={Image} alt='Image' />
        <Loggo onDoubleClick={navAuth} src={Logo} alt='Logo' />
        <Text>
          {state?.ru && ru.title}
          {state?.en && en.title}
          {state?.kyr && kyr.title}
        </Text>
      </DivImg>
      <div>
        <Select name='select' defaultValue={lang} onChange={langChange}>
          <StyledOption value='ru'>Русский</StyledOption>
          <StyledOption value='kyr'>Кыргызча</StyledOption>
          <StyledOption value='en'>English</StyledOption>
        </Select>
      </div>
    </MainHeader>
  )
}

export default Header

const DivImg = styled.div`
  display: flex;
  user-select: none;
  gap: 20px;
`
const MainHeader = styled.header`
  display: flex;
  background-color: #0a0359;
  align-items: center;
  justify-content: space-between;
  padding: 5px 80px 5px 30px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  @media (max-width: 1281px) {
    background-color: #0a0359;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 15px;
  }
  @media (max-width: 821px) {
    height: 90px;
  }
  @media (max-width: 415px) {
    height: 65px;
  }
  @media (max-width: 391px) {
    height: 60px;
  }
  @media (max-width: 376px) {
    height: 60px;
  }
`
const Loggo = styled.img`
  width: 70px;
  @media (max-width: 913px) {
    display: none;
  }
  @media (max-width: 821px) {
    display: none;
  }
  @media (max-width: 415px) {
    display: none;
  }
  @media (max-width: 391px) {
    display: none;
  }
  @media (max-width: 376px) {
    display: none;
  }
`
const Text = styled.p`
  color: white;
  font-size: 30px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin-top: 20px;
  @media (max-width: 913px) {
    display: none;
  }
`

const StyledImage = styled.img`
  display: none;
  @media (max-width: 913px) {
    display: flex;
    height: 60px;
  }
  @media (max-width: 821px) {
    height: 65px;
  }
  @media (max-width: 431px) {
    height: 35px;
  }
`

const Select = styled.select`
  background-color: #0a0359;
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  @media (max-width: 1281px) {
    background-color: #0a0359;
    font-size: 30px;
    margin-right: 10px;
  }
  @media (max-width: 1025px) {
    background-color: #0a0359;
    font-size: 30px;
    margin-right: 30px;
  }
  @media (max-width: 913px) {
    background-color: #0a0359;
    font-size: 35px;
    margin-right: 35px;
  }
  @media (max-width: 821px) {
    margin-right: 25px;
  }
  @media (max-width: 769px) {
    margin-right: 25px;
  }
  @media (max-width: 541px) {
    margin-right: 25px;
  }
  @media (max-width: 431px) {
    font-size: 22px;
  }
  @media (max-width: 376px) {
    padding: 0;
  }
  @media (max-width: 281px) {
    margin-right: 18px;
  }
`
const StyledOption = styled.option`
  font-size: 20px;
`
