import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../components/UI/Footer'
import Header from '../components/UI/Header'
import ScrollUp from '../components/UI/ScrollUp'
import Sidebar from '../components/UI/Sidebar'

const AdminLayout = () => {
  const [burger, setBurger] = useState(false)
  const onBurger = () => {
    setBurger((prev) => !prev)
  }
  const closeSideBar = () => {
    setBurger(false)
  }
  const logout = () => {
    localStorage.clear('authorization')
    window.location.reload()
  }
  return (
    <Container>
      <Header onClickBurger={onBurger} navAuth={logout} />
      <StyledMain>
        <SideBlock>
          <Sidebar closeSidebar={closeSideBar} />
        </SideBlock>
        {burger && <Sidebar closeSidebar={closeSideBar} />}
        <StyledOutlet>
          <Outlet />
        </StyledOutlet>
      </StyledMain>
      <ScrollUp />
      <Footer />
    </Container>
  )
}

export default AdminLayout

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const StyledMain = styled.div`
  display: flex;
`
const StyledOutlet = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
const SideBlock = styled.div`
  display: flex;
  @media (max-width: 913px) {
    display: none;
  }
`
