import { Route, Routes } from 'react-router-dom'
import AdminAboutCityHallAdd from '../components/Admin/AboutOfCityHall/Add'
import AdminAboutCityHallEdit from '../components/Admin/AboutOfCityHall/Edit'
import AdminCityHall from '../components/Admin/AboutOfCityHall/Main'
import AppealsFormCitizensArchive from '../components/Admin/AppealsOfCitizens/Archive'
import AdminAppealOfCitizens from '../components/Admin/AppealsOfCitizens/Main'
import AdminAppealOfCitizenAdd from '../components/Admin/AppealsOfCitizens/Response'
import AdminCalendarAdd from '../components/Admin/Calendar/Add'
import AdminCalendarEdit from '../components/Admin/Calendar/Edit'
import AdminCalendarEvent from '../components/Admin/Calendar/Event'
import AdminCalendar from '../components/Admin/Calendar/Main'
import AdminContactsAdd from '../components/Admin/Contacts/Add'
import AdminContactsEdit from '../components/Admin/Contacts/Edit'
import AdminContacts from '../components/Admin/Contacts/Main'
import AdminFaq from '../components/Admin/FAQ/Main'
import AdminForTouristsAdd from '../components/Admin/ForTourists/Add'
import AdminAddAttractions from '../components/Admin/ForTourists/Attractions/Add'
import AdminAttractionsMap from '../components/Admin/ForTourists/Attractions/Attraction'
import AdminEditAttractions from '../components/Admin/ForTourists/Attractions/Edit'
import AdminAttractions from '../components/Admin/ForTourists/Attractions/Main'
import AdminAddCafe from '../components/Admin/ForTourists/Cafes/Add'
import AdminCurrentCafe from '../components/Admin/ForTourists/Cafes/Cafe'
import AdminCafeEdit from '../components/Admin/ForTourists/Cafes/Edit'
import AdminCafe from '../components/Admin/ForTourists/Cafes/Main'
import AdminForTouristsEdit from '../components/Admin/ForTourists/Edit'
import AdminAddHotels from '../components/Admin/ForTourists/Hotels/Add'
import AdminEditHotel from '../components/Admin/ForTourists/Hotels/Edit'
import AdminCurrentHotel from '../components/Admin/ForTourists/Hotels/Hotel'
import AdminHotels from '../components/Admin/ForTourists/Hotels/Main'
import AdminForTourists from '../components/Admin/ForTourists/Main'
import AdminMainPageAdd from '../components/Admin/Main/Add'
import AdminMainPageEdit from '../components/Admin/Main/Edit'
import AdminMainPage from '../components/Admin/Main/Main'
import AdminMunicipalAdd from '../components/Admin/MunicipalPrograms/Add'
import AdminMunicipalEdit from '../components/Admin/MunicipalPrograms/Edit'
import AdminProgram from '../components/Admin/MunicipalPrograms/Main'
import AdminAddNews from '../components/Admin/News/Add'
import AdminEditNews from '../components/Admin/News/Edit'
import AdminNewsPage from '../components/Admin/News/Main'
import AdminLayout from '../layouts/AdminLayout'
import ErrorPage from './ErrorPage'

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<AdminLayout />}>
        <Route path='/' element={<AdminMainPage />} />
        <Route path='/add' element={<AdminMainPageAdd />} />
        <Route path='/edit/:id' element={<AdminMainPageEdit />} />
        <Route path='/news' element={<AdminNewsPage />} />
        <Route path='/news/add' element={<AdminAddNews />} />
        <Route path='/news/edit/:id' element={<AdminEditNews />} />
        <Route path='/faq' element={<AdminFaq />} />
        <Route path='/about-of-city-hall' element={<AdminCityHall />} />
        <Route
          path='/about-of-city-hall/add'
          element={<AdminAboutCityHallAdd />}
        />
        <Route
          path='/about-of-city-hall/:id/edit'
          element={<AdminAboutCityHallEdit />}
        />
        <Route
          path='/appeals-of-citizens'
          element={<AdminAppealOfCitizens />}
        />
        <Route
          path='/appeals-of-citizens/archive'
          element={<AppealsFormCitizensArchive />}
        />
        <Route
          path='/appeals-of-citizens/response/:id'
          element={<AdminAppealOfCitizenAdd />}
        />
        <Route path='/for-tourists' element={<AdminForTourists />} />
        <Route path='/for-tourists/add' element={<AdminForTouristsAdd />} />
        <Route
          path='/for-tourists/edit/:id'
          element={<AdminForTouristsEdit />}
        />
        <Route path='/for-tourists/cafes' element={<AdminCafe />} />
        <Route path='/for-tourists/cafes/add' element={<AdminAddCafe />} />
        <Route
          path='/for-tourists/cafes/edit/:id'
          element={<AdminCafeEdit />}
        />
        <Route
          path='/for-tourists/cafes/:cafeName/:cafeId'
          element={<AdminCurrentCafe />}
        />
        <Route path='/for-tourists/hotels' element={<AdminHotels />} />
        <Route
          path='/for-tourists/hotels/edit/:hotelId'
          element={<AdminEditHotel />}
        />
        <Route
          path='/for-tourists/hotels/:hotelName/:hotelId'
          element={<AdminCurrentHotel />}
        />
        <Route path='/for-tourists/hotels/add' element={<AdminAddHotels />} />
        <Route
          path='/for-tourists/attractions/add'
          element={<AdminAddAttractions />}
        />
        <Route
          path='/for-tourists/attractions'
          element={<AdminAttractions />}
        />
        <Route
          path='/for-tourists/attractions/edit/:attractionId'
          element={<AdminEditAttractions />}
        />
        <Route
          path='/for-tourists/attractions/:attractionName/:attractionId'
          element={<AdminAttractionsMap />}
        />
        <Route path='/contacts' element={<AdminContacts />} />
        <Route path='/contacts/add' element={<AdminContactsAdd />} />
        <Route path='/contacts/edit/:id' element={<AdminContactsEdit />} />
        <Route path='/calendar' element={<AdminCalendar />} />
        <Route path='/calendar/:event/:id' element={<AdminCalendarEvent />} />
        <Route
          path='/calendar/:event/:id/edit'
          element={<AdminCalendarEdit />}
        />
        <Route path='/calendar/add' element={<AdminCalendarAdd />} />
        <Route path='/municipal-programs' element={<AdminProgram />} />
        <Route path='/municipal-programs/add' element={<AdminMunicipalAdd />} />
        <Route
          path='/municipal-programs/edit/:id'
          element={<AdminMunicipalEdit />}
        />
        <Route path='*' element={<ErrorPage />} />
      </Route>
    </Routes>
  )
}
