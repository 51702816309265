/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminAbout,
  getAdminAbout,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import Card from '../../UI/Card'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../UI/SnackBar'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminCityHall = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const dispatch = useDispatch()
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const state = useSelector((state) => state.admin)

  const {
    AboutSnackState,
    AboutAddStatus,
    AboutEditStatus,
    AboutDeleteStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  useEffect(() => {
    dispatch(getAdminAbout())
  }, [])
  const deleteAbout = () => {
    dispatch(deleteAdminAbout({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const navigate = useNavigate()
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.AboutAddSnackBar({
        AboutSnackState: false,
        AboutAddStatus: null,
      })
    )
    dispatch(
      adminAction.AboutEditSnackBar({
        AboutSnackState: false,
        AboutEditStatus: null,
      })
    )
    dispatch(
      adminAction.AboutDeleteSnackBar({
        AboutSnackState: false,
        AboutDeleteStatus: null,
      })
    )
  }

  const navToAddAboutPage = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const navToEditAboutPage = (id) => {
    navigate(`${id}/edit`)
    closeSnackBarHandler()
  }
  return (
    <>
      {ModalState && (
        <ModalDeleteContent
          variant='About-CityHall'
          Cancellation={Cancellation}
          deletedFunction={deleteAbout}
        />
      )}
      {AboutSnackState && (
        <StyledSnackBarDiv>
          <SnackBar
            message={
              AboutAddStatus === 'success'
                ? (translator.ru && ru.messageSaccess) ||
                  (translator.en && en.messageSaccess) ||
                  (translator.kyr && kyr.messageSaccess)
                : AboutEditStatus === 'success'
                ? (translator.ru && ru.messageEditSaccess) ||
                  (translator.en && en.messageEditSaccess) ||
                  (translator.kyr && kyr.messageEditSaccess)
                : AboutDeleteStatus === 'success'
                ? (translator.ru && ru.messageDeleteSaccess) ||
                  (translator.en && en.messageDeleteSaccess) ||
                  (translator.kyr && kyr.messageDeleteSaccess)
                : (translator.ru && ru.messageError) ||
                  (translator.en && en.messageError) ||
                  (translator.kyr && en.messageError)
            }
            variant={AboutAddStatus || AboutEditStatus || AboutDeleteStatus}
            open={AboutSnackState}
            handleClose={closeSnackBarHandler}
          />
        </StyledSnackBarDiv>
      )}
      {state.about?.map((element) => (
        <StyledDivContainer>
          <StyledDivContainerCard>
            <StyledDivCard key={element.id}>
              <StyledButtons>
                <Button
                  onClick={() => deletedHandler(element.id)}
                  variant='delete-button-icon'
                />
                <Button
                  onClick={() => navToEditAboutPage(element.id)}
                  variant='edit-button'
                />
              </StyledButtons>
              <Card
                variant='about-page'
                title={element.title}
                description={element.description}
              />
            </StyledDivCard>
          </StyledDivContainerCard>
        </StyledDivContainer>
      ))}
      <AddButtonStyled>
        {state.about?.length <= 0 && (
          <StyledDivText>
            <DataNotFoundTranslator variant='' />
          </StyledDivText>
        )}
        <Button variant='add-faq' onClick={navToAddAboutPage}>
          <TranslatorDescription variant='add' />
        </Button>
      </AddButtonStyled>
    </>
  )
}

export default AdminCityHall

const StyledDivContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledDivContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledDivCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`

const StyledButtons = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 20px;
  align-items: baseline;
`

const AddButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
const StyledSnackBarDiv = styled.div`
  position: absolute;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 5px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
