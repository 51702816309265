// Initial Url
export const initialUrl = 'https://back.kochkor-ata-meria.kg/'

// ADMIN url's
// main
export const getAdminMainPageUrl =
  'api/v1/main/page/for/admin/find/get/findAllMainPage?count='
export const postAdminMainPageUrl = 'api/v1/main/page/for/admin/save/main/page?' // title=&description=&seeDate=
export const putAdminMainPageUrl =
  'api/v1/main/page/for/admin/update/main/page?' // title=&description=&seeDate=&id=
export const deleteAdminMainPageUrl =
  'api/v1/main/page/for/admin/delete/main/page?id='
// main

// news
export const getAdminNewsPageUrl = ''
export const postAdminNewsPageUrl = 'api/v1/news/for/admin/save/news?'
export const putAdminNewsPageUrl = 'api/v1/news/for/admin/update/news?' // title=&description=&seeDate=&id=
export const deleteAdminNewsPageUrl = 'api/v1/news/for/admin/delete/news?id='
// news

// faq
export const getAdminFaqPageUrl = 'api/v1/faq/admin/find/all/faq'
export const deleteAdminFaqPageUrl = 'api/v1/faq/admin/delete/faq?appealId='
// faq

// about of city hall
export const getAdminAboutPageUrl =
  'api/v1/about/city/hall/admin/find/all/aboutCityHall'
export const postAdminAboutPageUrl =
  'api/v1/about/city/hall/admin/save/about/city/hall'
export const putAdminAboutPageUrl =
  'api/v1/about/city/hall/admin/update/about/city/hall?id='
export const deleteAdminAboutPageUrl =
  'api/v1/about/city/hall/admin/delete/about/city/hall?id='
// about of city hall

// appeals of citizens
export const getAdminAppealsPageUrl =
  'api/v1/appeal/of/citizens/for/admin/find/all'
export const getAdminCurrentAppealsPageUrl =
  'api/v1/appeal/of/citizens/for/admin/find/by/id?id='
export const postAdminAppealsPageUrl =
  'api/v1/appeal/of/citizens/for/admin/answer?' // appealId=&answer=
export const postAdminAppealsFaqPageUrl =
  'api/v1/appeal/of/citizens/for/admin/answer/faq?' // appealId=&answer=
export const putAdminAppealsPageUrl =
  'api/v1/appeal/of/citizens/for/admin/ignore?appealId='
export const getAdminAppealsArchivePageUrl =
  'api/v1/appeal/of/citizens/for/admin/find/all/archive'
export const deleteAdminAppealsArchivePageUrl =
  'api/v1/appeal/of/citizens/for/admin/clear'
// appeals of citizens

// For tourists
export const getAdminTouristsPageUrl =
  'api/v1/for/tourist/admin/find/all/tourist'
export const postAdminTouristsPageUrl = 'api/v1/for/tourist/admin/save/tourist'
export const putAdminTouristsPageUrl =
  'api/v1/for/tourist/admin/update/tourist?id='
export const deleteAdminTouristsPageUrl =
  'api/v1/for/tourist/admin/delete/tourist?id='

// cafes ------
export const getAdminTouristsCafesPageUrl =
  'api/v1/for/tourist/admin/find/all/cafe?count='
export const getAdminTouristsCurrentCafePageUrl =
  'api/v1/for/tourist/admin/find/by/id/cafe?cafeId='
export const postAdminTouristsCafesPageUrl =
  'api/v1/for/tourist/admin/save/cafe?' // name=&description=&wiFi=&lat=&lng='
export const putAdminTouristsCafesPageUrl =
  'api/v1/for/tourist/admin/update/cafe?' // cafeId=&name=&description=&wiFi=&lat=&lng=
export const deleteAdminTouristsCafesPageUrl =
  'api/v1/for/tourist/admin/delete/cafe/by/id?cafeId='
// cafes ------

// hotels -----
export const getAdminTouristsHotelsPageUrl =
  'api/v1/for/tourist/admin/find/all/hotel?count='
export const getAdminTouristsCurrentHotelPageUrl =
  'api/v1/for/tourist/admin/find/by/id/hotel?hotelId='
export const postAdminTouristsHotelsPageUrl =
  'api/v1/for/tourist/admin/save/hotel?' // name=&description=&wiFi=&lat=&lng=&countPlaces=&price=
export const putAdminTouristsHotelsPageUrl =
  'api/v1/for/tourist/admin/update/hotel?' // hotelId=&name=&description=&wiFi=&lat=&lng=&countPlaces=&price=
export const deleteAdminTouristsHotelsPageUrl =
  'api/v1/for/tourist/admin/delete/hotel/by/id?hotelId='
// hotels -----

// attarctions -----
export const getAdminTouristsAttractionsPageUrl =
  'api/v1/for/tourist/admin/find/all/pride?count='
export const getAdminTouristsCurrentAttractionPageUrl =
  'api/v1/for/tourist/admin/find/by/id/pride?prideId='
export const postAdminTouristsAttractionsPageUrl =
  'api/v1/for/tourist/admin/save/pride?' // name=&description=&wiFi=&lat=&lng=
export const putAdminTouristsAttractionsPageUrl =
  'api/v1/for/tourist/admin/update/pride?' // hotelId=&name=&description=&wiFi=&lat=&lng=
export const deleteAdminTouristsAttractionsPageUrl =
  'api/v1/for/tourist/admin/delete/pride/by/id?prideId='
// attarctions -----

// For tourists

// contacts
export const getAdminContactsPageUrl = 'api/v1/contact/admin/find/all/contact'
export const getAdminCurrentContactPageUrl =
  'api/v1/contact/admin/get/by/contact?contactId='
export const postAdminContactsPageUrl = 'api/v1/contact/admin/save/contact'
export const putAdminContactsPageUrl =
  'api/v1/contact/admin/update/contact?contactId='
export const deleteAdminContactsPageUrl =
  'api/v1/contact/admin/delete/by/contact?contactId='
// contacts

// calendar
export const getAdminCalendarPageUrl = 'api/v1/calendar/admin/find/all/day'
export const getAdminCalendarEventPageUrl =
  'api/v1/calendar/admin/find/by/id/day?calendarId='
export const postAdminCalendarPageUrl = 'api/v1/calendar/admin/save/calendar?' // description=
export const putAdminCalendarPageUrl = 'api/v1/calendar/admin/update/calendar?' // description=&calendarId=
export const deleteAdminCalendarPageUrl =
  'api/v1/calendar/admin/delete/calendar?calendarId='
// calendar

// municipal programs
export const getAdminMunicipalPageUrl =
  'api/v1/program/admin/find/get/program?count='
export const postAdminMunicipalPageUrl = 'api/v1/program/admin/save/program?' // title=&description=&seeDate=
export const putAdminMunicipalPageUrl = 'api/v1/program/admin/update/program?' // title=&description=&seeDate=&id=
export const deleteAdminMunicipalPageUrl =
  'api/v1/program/admin/delete/program?id='
// municipal programs
// ADMIN url's

// USER url's
// calendar
export const calendarUrl = 'calendar/api/user/get/all/calendar'
export const calendarEventUrl = 'calendar/api/user/find/by/id/day?calendarId='
// calendar
// hotel
export const userHotelsUrl = 'hotel/user/api/get/all/hotel?count='
export const getUserCurrentHotelsUrl = 'hotel/user/api/get/hotel?id='
// hotel
// cafes
export const getUserTouristCafesUrl = 'cafe/user/api/find/all/cafe?count='
export const TouristCurrentCafeUrl = 'cafe/user/api/find/by/id/cafe?cafeId='
// cafes
// attraction
export const getAttractionUrl = 'pride/user/api/find/all/pride?count='
// attraction
// contacts
export const getUserContactsUrl = 'api/v1/contact/user/find/all'
// contacts
// faq
export const userFaq = 'api/v1/faq/user/find/all/faq'
// faq
// turist
export const userTurist = 'api/mainPage/user/find/all/tourist'
// turist
// MUNICIPAL
export const userMunicipal = 'program/user/api/get/programs?count='
// MUNICIPAL
// USERMAINPAGE
export const userMainPage = 'menu/user/api/find/get/findAllMainPage?count='
// USERMAINPAGE
// CITYHALL
export const userCityHall = 'api/v1/about/city/hall/user/find/all'
// CITYHALL
// USERAPPEALOFCITIZEN
export const userAppealOfCitizenURL =
  'api/v1/appeal/of/citizens/for/user/save/appeal/of/citizent/user?' // name=&number=&description=
// USERAPPEALOFCITIZEN
