import { configureStore } from '@reduxjs/toolkit'
import { AdminSlice } from './admin/AdminSlice'
import { AuthSlice } from './auth/AuthSlice'
import { Translator } from './Translator'
import { userSlice } from './User/UserSlice'

export const store = configureStore({
  reducer: {
    authorization: AuthSlice.reducer,
    admin: AdminSlice.reducer,
    user: userSlice.reducer,
    translator: Translator.reducer,
  },
})
