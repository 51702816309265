import React, { useRef } from 'react'
import { styled } from 'styled-components'
import Icon from '../../assets/Photo/Vector (1).svg'

const CardPhoto = ({ files, setFiles, variant, setFormat }) => {
  const fileGroup = useRef(null)

  const changeHandler = (event) => {
    const file = event.target.files[0]
    if (setFormat) {
      setFormat(file)
    }
    if (file) {
      const url = URL.createObjectURL(file)
      setFiles(url)
    }
  }

  const handleFile = () => {
    fileGroup.current.click()
  }

  return (
    <div>
      {variant === 'logo' && (
        <Container>
          <Mix>
            {!files ? (
              <StyledImg onClick={handleFile} src={Icon} alt='error' />
            ) : (
              <StyledImage onClick={handleFile} src={files} alt='error' />
            )}

            <StyledFile
              ref={fileGroup}
              onChange={changeHandler}
              type='file'
              accept='image/*,.svg,.png,.jpg,.web'
            />
          </Mix>
        </Container>
      )}
      {variant === 'video' && (
        <Container>
          <Div>
            {!files ? (
              <StyledVideoIcon
                onClick={handleFile}
                src='https://iconape.com/wp-content/png_logo_vector/video-camera.png'
                alt='error'
              />
            ) : (
              <StyledVideo onClick={handleFile} src={files} controls />
            )}

            <StyledFile
              ref={fileGroup}
              onChange={changeHandler}
              type='file'
              accept='video/*'
            />
          </Div>
        </Container>
      )}
      {variant === 'photo' && (
        <Container>
          <Div>
            <LogoBlock>
              {!files ? (
                <StyledLogo onClick={handleFile} src={Icon} alt='error' />
              ) : (
                <StyledLogos onClick={handleFile} src={files} alt='error' />
              )}
            </LogoBlock>

            <StyledInput
              ref={fileGroup}
              onChange={changeHandler}
              type='file'
              accept='image/*,.svg,.png,.jpg,.web'
            />
          </Div>
        </Container>
      )}
    </div>
  )
}

export default CardPhoto

const Div = styled.div`
  width: 600px;
  height: 300px;
  border: 1px solid #0a0082;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1025px) {
    width: 500px;
  }
  @media (max-width: 541px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    width: 310px;
  }
  @media (max-width: 416px) {
    width: 310px;
  }
  @media (max-width: 413px) {
    width: 300px;
  }
  @media (max-width: 376px) {
    width: 300px;
  }
`
const LogoBlock = styled.div`
  display: flex;
  justify-content: center;
`
const StyledLogo = styled.img`
  border-radius: 17px;
  margin-top: 70px;
  margin-bottom: 70px;
`

const StyledLogos = styled.img`
  width: 600px;
  height: 299px;
  object-fit: cover;
  border-radius: 17px;
  @media (max-width: 1025px) {
    width: 500px;
  }
  @media (max-width: 913px) {
    width: 500px;
  }
  @media (max-width: 826px) {
    width: 500px;
  }
  @media (max-width: 541px) {
    width: 400px;
  }
  @media (max-width: 450px) {
    width: 310px;
  }
  @media (max-width: 415px) {
    width: 310px;
  }
  @media (max-width: 413px) {
    width: 300px;
  }
  /* @media (max-width: 391px) {
    width: 500px;
  }
  @media (max-width: 376px) {
    width: 500px;
  }
  @media (max-width: 361px) {
    width: 300px;
  } */
`

const StyledInput = styled.input`
  display: none;
`

const Container = styled.div``

const Mix = styled.div`
  width: 300px;
  height: 300px;
  border: 1px solid #0a0082;
  border-radius: 17px;
  margin-top: 10px;
  @media (max-width: 1281px) {
    width: 355px;
    height: 260px;
  }
  @media (max-width: 1025px) {
    width: 450px;
    height: 255px;
  }
  @media (max-width: 913px) {
    width: 430px;
    height: 250px;
  }
  @media (max-width: 821px) {
    width: 390px;
    height: 220px;
  }
  @media (max-width: 415px) {
    width: 330px;
    height: 170px;
    margin-top: 30px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 413px) {
    width: 340px;
    height: 180px;
    margin: none;
  }
  @media (max-width: 394px) {
    width: 330px;
    height: 180px;
    margin-top: 30px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 391px) {
    width: 320px;
    height: 180px;
    margin-top: 30px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 376px) {
    width: 310px;
    height: 190px;
    margin-top: 30px;
    border: 1px solid black;
    border-radius: 15px;
  }
  @media (max-width: 361px) {
    width: 300px;
    height: 180px;
    margin-top: 30px;
    border: 1px solid black;
    border-radius: 15px;
  }
`
const StyledImg = styled.img`
  width: 100px;
  transform: translate(96px);
  border-radius: 17px;
  margin-top: 80px;
  @media (max-width: 1281px) {
    height: 80px;
    margin-left: 25px;
  }
  @media (max-width: 1054px) {
    height: 80px;
    margin-left: 65px;
  }
  @media (max-width: 913px) {
    height: 70px;
    margin-left: 65px;
  }
  @media (max-width: 821px) {
    height: 65px;
    margin-left: 35px;
  }
  @media (max-width: 415px) {
    height: 55px;
    margin-top: 57px;
    margin-left: 25px;
  }
  @media (max-width: 394px) {
    height: 55px;
    margin-right: 30px;
  }
  @media (max-width: 391px) {
    height: 55px;
    margin-left: 23px;
    margin-top: 60px;
  }
  @media (max-width: 376px) {
    height: 60px;
    margin-left: 20px;
    margin-top: 65px;
  }
  @media (max-width: 361px) {
    height: 55px;
  }
`

const StyledFile = styled.input`
  display: none;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 17px;
  @media (max-width: 415px) {
    border-radius: 12px;
  }
  @media (max-width: 412px) {
    border-radius: 12px;
  }
  @media (max-width: 394px) {
    border-radius: 13px;
  }
  @media (max-width: 376px) {
    border-radius: 14px;
  }
`
const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 17px;
  @media (max-width: 415px) {
    border-radius: 12px;
  }
  @media (max-width: 412px) {
    border-radius: 12px;
  }
  @media (max-width: 394px) {
    border-radius: 13px;
  }
  @media (max-width: 376px) {
    border-radius: 14px;
  }
`
const StyledVideoIcon = styled.img`
  width: 200px;
`
