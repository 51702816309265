/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminTouristsHotels,
  getAdminTouristsHotels,
} from '../../../../services/admin/AdminAction'
import { adminAction } from '../../../../services/admin/AdminSlice'
import { english } from '../../../../utils/constants/lang/english'
import { kyrgyz } from '../../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../../utils/constants/lang/russian'
import Button from '../../../UI/Button'
import Card from '../../../UI/Card'
import ModalDeleteContent from '../../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../../UI/SnackBar'
import TextCard from '../../../UI/TextCard'
import DataNotFoundTranslator from '../../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../../UI/TranslatorContent/TranslatorDescription'

const AdminHotels = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const dispatch = useDispatch()
  const [count, setCount] = useState(6)
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const countHandler = () => {
    setCount((prev) => prev + 6)
  }
  const state = useSelector((state) => state.admin)

  const {
    HotelSnackState,
    HotelAddStatus,
    HotelEditStatus,
    HotelDeleteStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  useEffect(() => {
    dispatch(getAdminTouristsHotels({ count }))
  }, [count])
  const deleteAdminHotels = () => {
    dispatch(deleteAdminTouristsHotels({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.HotelsAddSnackBar({
        HotelSnackState: false,
        HotelAddStatus: null,
      })
    )
    dispatch(
      adminAction.HotelsEditSnackBar({
        HotelSnackState: false,
        HotelEditStatus: null,
      })
    )
    dispatch(
      adminAction.HotelsDeleteSnackBar({
        HotelSnackState: false,
        HotelDeleteStatus: null,
      })
    )
  }

  const editAdminHotels = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  const addAdminHotels = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const navigate = useNavigate()
  const navToCurrentHotel = (el) => {
    navigate(`${el.title}/${el.id}`)
    closeSnackBarHandler()
  }

  return (
    <StyledDiv>
      {ModalState && (
        <ModalDeleteContent
          variant=''
          Cancellation={Cancellation}
          deletedFunction={deleteAdminHotels}
        />
      )}
      {HotelSnackState && (
        <SnackBar
          message={
            HotelAddStatus === 'success'
              ? (translator.ru && ru.messageSaccess) ||
                (translator.en && en.messageSaccess) ||
                (translator.kyr && kyr.messageSaccess)
              : HotelEditStatus === 'success'
              ? (translator.ru && ru.messageEditSaccess) ||
                (translator.en && en.messageEditSaccess) ||
                (translator.kyr && kyr.messageEditSaccess)
              : HotelDeleteStatus === 'success'
              ? (translator.ru && ru.messageDeleteSaccess) ||
                (translator.en && en.messageDeleteSaccess) ||
                (translator.kyr && kyr.messageDeleteSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={HotelAddStatus || HotelEditStatus || HotelDeleteStatus}
          open={HotelSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}

      <StyledText>
        <TextCard variant='hotels' colorVariant='blue' />
      </StyledText>
      {state.touristsHotels?.map((element) => (
        <StyledDivCard key={element.id}>
          <Card
            variant='tourists-page-admin'
            img={element.image}
            title={element.title}
            description={element.description}
            date={element.date}
            senderText={element.senderText}
            sender={element.sender}
            onAttractions={() => navToCurrentHotel(element)}
            onDelete={() => deletedHandler(element.id)}
            onEdit={() => editAdminHotels(element.id)}
          />
        </StyledDivCard>
      ))}
      {state.touristsHotels?.length <= 0 && (
        <StyledDivText>
          <DataNotFoundTranslator variant='' />
        </StyledDivText>
      )}
      <StyledDivButton>
        {state.touristsHotels?.length >= 6 && (
          <Button onClick={countHandler} variant='button1'>
            <TranslatorDescription variant='more' />
          </Button>
        )}
        <Button onClick={addAdminHotels} variant='button1'>
          <TranslatorDescription variant='add' />
        </Button>
      </StyledDivButton>
    </StyledDiv>
  )
}
export default AdminHotels

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
const StyledText = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  @media (max-width: 1025px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 100px;
  }
  @media (max-width: 540px) {
    margin: 0;
    margin-top: 30px;
  }
  @media (max-width: 391px) {
    margin: 0;
    margin-top: 30px;
    margin-left: -3px;
  }
`
const StyledDivCard = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`
const StyledDivButton = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 50px;
  margin-top: 30px;
  gap: 20px;
  @media (max-width: 415px) {
    margin-right: 7px;
  }
  @media (max-width: 394px) {
    margin-right: 15px;
  }
  @media (max-width: 376px) {
    margin-right: 7px;
  }
  @media (max-width: 361px) {
    margin-right: 10px;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
