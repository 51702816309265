import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { putAdminCalendar } from '../../../services/admin/AdminAction'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import Input from '../../UI/Inputs'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminCalendarEdit = () => {
  const ru = russian
  const en = english
  const kyr = kyrgyz

  const translator = useSelector((state) => state.translator)

  const [input, setInput] = useState({
    date: null,
    name: '',
    description: '',
  })
  const dateInputHandler = (event) => {
    setInput({
      ...input,
      date: event.target.value,
    })
  }
  const nameInputHandler = (event) => {
    setInput({
      ...input,
      name: event.target.value,
    })
  }
  const descriptionInputHandler = (event) => {
    setInput({
      ...input,
      description: event.target.value,
    })
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { calendarStatus } = useSelector((state) => state.admin)
  const editEvent = () => {
    if (input.date && input.description !== '') {
      dispatch(
        putAdminCalendar({
          name: input.name,
          description: input.description,
          date: input.date,
          id,
        })
      )
      if (calendarStatus) {
        navigate(-1)
      }
    }
  }
  return (
    <Block>
      <Container>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />
        <div>
          <div>
            <Input
              onChange={nameInputHandler}
              tipe='input'
              variant='appeals'
              placeholder={
                (translator.ru && ru.descriptionDatas.title.toString()) ||
                (translator.en && en.descriptionDatas.title.toString()) ||
                (translator.kyr && kyr.descriptionDatas.title.toString())
              }
            />
          </div>
          <StyleInput>
            <Input
              onChange={descriptionInputHandler}
              tipe='textarea'
              variant='calendarInput'
              placeholder={
                (translator.ru && ru.descriptionDatas.description.toString()) ||
                (translator.en && en.descriptionDatas.description.toString()) ||
                (translator.kyr && kyr.descriptionDatas.description.toString())
              }
            />
          </StyleInput>
          <InputMain>
            <DateInputBlock>
              <InputStyle onChange={dateInputHandler} type='date' />
            </DateInputBlock>
          </InputMain>
          <Buttons>
            <Button onClick={editEvent} variant='button1'>
              <TranslatorDescription variant='save' />
            </Button>
          </Buttons>
        </div>
      </Container>
    </Block>
  )
}

export default AdminCalendarEdit
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
`
const Block = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  width: 590px;
  height: 620px;
  border: 1px solid;
  background-color: white;
  display: flex;
  align-items: start;
  padding-right: 5px;
  padding-top: 5px;
  justify-content: center;
  border-radius: 15px;
  @media (max-width: 1100px) {
    width: 95%;
  }
`
const StyleInput = styled.div`
  margin-top: 35px;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 376px) {
    margin-left: 50px;
  }
`
const InputStyle = styled.input`
  height: 69px;
  width: 230px;
  border-radius: 16px;
  font-size: 25px;
  text-align: center;
`
const InputMain = styled.div`
  display: flex;
  justify-content: center;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 50px;
  gap: 20px;
`
const DateInputBlock = styled.div`
  ::-webkit-calendar-picker-indicator {
    position: relative;
    right: 20px;
  }
`
