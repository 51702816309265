import { styled } from 'styled-components'
import passwordWallpaper from '../../assets/inputIcon/password.svg'
import usernameWallpaper from '../../assets/inputIcon/username.svg'

const Input = function Input(props) {
  return (
    <Div variant={props.variant} width={props.width}>
      {props.iconAdd === 'usernameIcon' ? (
        <IMG src={usernameWallpaper} alt='' />
      ) : (
        ''
      )}
      {props.iconPassword === 'passwordIcon' ? (
        <IMG2 src={passwordWallpaper} alt='' />
      ) : (
        ''
      )}
      {props.tipe === 'input' && (
        <Inputs
          variant={props.variant}
          onChange={props.onChange}
          placeholder={props.placeholder}
          type={props.type}
          maxLength={props.maxLength}
          value={props.value}
          autoComplete={props.autoComplete}
        />
      )}
      {props.tipe === 'textarea' && (
        <Textarea
          variant={props.variant}
          onChange={props.onChange}
          placeholder={props.placeholder}
          value={props.value}
        />
      )}
    </Div>
  )
}
export default Input

const nameHandler = ({ variant }) => {
  return (
    variant === 'name' && 'width: 400px; border-radius: 15px; height: 50px; '
  )
}
const emailHandler = ({ variant }) => {
  return (
    variant === 'email' &&
    'width: 400px; height: 50px; font-size:15px; border-radius: 15px; '
  )
}
const textHandler = ({ variant }) => {
  return (
    variant === 'text' && 'width: 400px; height: 155px; border-radius: 15px; '
  )
}
const adminNameHandler = ({ variant }) => {
  return (
    variant === 'username' &&
    'width: 250px; height: 40px;  border-radius: 15px; background: rgba(240, 237, 255, 0.80);'
  )
}
const passwordHandler = ({ variant }) => {
  return (
    variant === 'password' &&
    'width: 250px; height:40px; border-radius: 15px; background: rgba(240, 237, 255, 0.80);padding-right:20px;'
  )
}
const nameAdminHandler = ({ variant }) => {
  return (
    variant === 'nameAdmin' &&
    'width: 450px; height: 66px; border-radius: 15px; border: 1px solid;'
  )
}
const textAdminHandler = ({ variant }) => {
  return (
    variant === 'textAdmin' &&
    'width: 450px; height: 155px; border-radius: 15px;  '
  )
}
const calendarAdminHandler = ({ variant }) => {
  return (
    variant === 'calendarInput' &&
    'width: 550px; height: 255px; border-radius: 15px;'
  )
}
const userAdminHadler = ({ variant }) => {
  return (
    variant === 'userAdmin' &&
    'width: 220px; height:40px;  border-radius: 15px; '
  )
}
const questionAdminHadnler = ({ variant }) => {
  return (
    variant === 'question' && 'width: 700px; border-radius: 15px; height:50px; '
  )
}
const youranswerAdminHadler = ({ variant }) => {
  return (
    variant === 'youranswer' &&
    'width:700px; height:155px; border-radius: 15px;'
  )
}
const nameAdmin = ({ variant }) => {
  return (
    variant === 'nameAdminHandler' &&
    'width:250px; height:30px;  border-radius: 15px; '
  )
}
const name2Admin = ({ variant }) => {
  return (
    variant === 'name2AdminHandler' &&
    'width:150px; height: 30px; border-radius: 15px;'
  )
}
const name3Admin = ({ variant }) => {
  return (
    variant === 'name3AdminHandler' &&
    'width: 265px; height:30px; border-radius: 15px; '
  )
}
const calendarAdminHandlerMedia = ({ variant }) => {
  return (
    variant === 'calendarInput' &&
    'width: 300px; height: 255px; border-radius: 15px;'
  )
}
const calendarAdminHandlerMediaS8 = ({ variant }) => {
  return (
    variant === 'calendarInput' &&
    'width: 300px; height: 255px; border-radius: 15px;'
  )
}
// input
const nameInputHandler = ({ variant }) => {
  return (
    variant === 'name' &&
    ' width: 300px; height: 48px;   border-radius: 15px;font-size:19px;'
  )
}
// variant name
const divVariantNameInput = ({ variant }) => {
  return (
    variant === 'name' && 'width: 310px; height: 50px; border-radius: 15px;'
  )
}
const emailInputHandler = ({ variant }) => {
  return (
    variant === 'email' &&
    'width: 400px; height: 48px; font-size:19px; border-radius: 15px;'
  )
}
const adminNameInputHandler = ({ variant }) => {
  return (
    variant === 'username' &&
    'width: 200px; height: 27px;   border-radius: 5px;    font-size:20px;background: rgba(240, 237, 255, 0.80);'
  )
}
const passwordInputHandler = ({ variant }) => {
  return (
    variant === 'password' &&
    'width: 200px; height:27px; border-radius: 5px; font-size:20px; background: rgba(240, 237, 255, 0.80);'
  )
}
const nameAdminInputHandler = ({ variant }) => {
  return (
    variant === 'nameAdmin' &&
    'width: 450px; height: 64px; border-radius: 15px;  font-size: 21px;'
  )
}
const userAdminInputHadler = ({ variant }) => {
  return (
    variant === 'userAdmin' &&
    'width: 210px; height:30px; border-radius: 15px; font-size:19px; '
  )
}
const questionAdminInputHadnler = ({ variant }) => {
  return (
    variant === 'question' &&
    'width: 700px; border-radius: 15px; font-size:19px; height:46px; '
  )
}
const youranswerAdminInputHadler = ({ variant }) => {
  return (
    variant === 'youranswer' &&
    'width:700px; height:155px; border-radius: 15px;  '
  )
}
const nameAdminInput = ({ variant }) => {
  return (
    variant === 'nameAdminHandler' &&
    'width:250px; height:26px; border-radius: 15px; '
  )
}
const name2AdminInput = ({ variant }) => {
  return (
    variant === 'name2AdminHandler' &&
    'width:148px; height: 26px;  border-radius: 15px;'
  )
}
const name3AdminInput = ({ variant }) => {
  return (
    variant === 'name3AdminHandler' &&
    'width: 270px; height:26px;  border-radius: 15px; '
  )
}
const citykHallHandler = ({ variant }) => {
  return variant === 'cityHall' && 'width: 750px; height: 200px;'
}
// media input
const nameInputMediaHandler = ({ variant }) => {
  return variant === 'name' && 'width:250px; height:39px;'
}
const emailInputMediaHandler = ({ variant }) => {
  return variant === 'email' && 'width:250px;'
}
const textHandlerMedia = ({ variant }) => {
  return variant === 'text' && 'width:320px; height:155px;'
}
const adminNameInputMediaHandler = ({ variant }) => {
  return (
    variant === 'username' && 'width:300px; height:34px; border-radius:15px;'
  )
}
const passwordInputMediaHandler = ({ variant }) => {
  return (
    variant === 'password' && 'width:300px; height:34px; border-radius:15px;'
  )
}
const nameAdminInputMediaHandler = ({ variant }) => {
  return variant === 'nameAdmin' && 'width:310px; height:40px;'
}
const textAdminMediaHadler = ({ variant }) => {
  return variant === 'textAdmin' && 'width:250px; height:205px;'
}
const userAdminInputMediaHadler = ({ variant }) => {
  return variant === 'userAdmin' && 'width:250px; height:39px;'
}
const questionAdminInputMediaHadnler = ({ variant }) => {
  return variant === 'question' && 'width:320px; height:30px;'
}
const youranswerAdminInputMediaHadler = ({ variant }) => {
  return variant === 'youranswer' && 'width:320px; height:150px;'
}
const nameAdminInputMedia = ({ variant }) => {
  return variant === 'nameAdminHadler' && 'width:190px; height:28px;'
}
const name2AdminInputMedia = ({ variant }) => {
  return variant === 'name2AdminHandler' && 'width:160px; height:26px;'
}
const name3AdminInputMedia = ({ variant }) => {
  return variant === 'name3AdminHandler' && 'width:179px; height:26px;'
}
const nameAdminInputMedia2Handler = ({ variant }) => {
  return variant === 'nameAdmin' && 'width:300px; height:36px;'
}
const textAdminMedia2Hadler = ({ variant }) => {
  return variant === 'textAdmin' && 'width:340px; height:205px;'
}
const questionAdminInputMedia2Hadnler = ({ variant }) => {
  return variant === 'question' && 'width:320px; height:39px;'
}
const youranswerAdminInputMedia2Hadler = ({ variant }) => {
  return variant === 'youranswer' && 'width:320px; height:150px;'
}
const questionAdminInputMedia3Hadnler = ({ variant }) => {
  return variant === 'question' && 'width:300px;'
}
const youranswerAdminInputMedia3Hadler = ({ variant }) => {
  return variant === 'youranswer' && 'width:300px; height:205px;'
}
const nameAdminInputMedia4Handler = ({ variant }) => {
  return variant === 'nameAdmin' && 'width:260px;'
}
const textAdminMedia4Hadler = ({ variant }) => {
  return variant === 'textAdmin' && 'width:270px;'
}
const questionAdminInputMedia4Hadnler = ({ variant }) => {
  return variant === 'question' && 'width:270px;'
}
const youranswerAdminInputMedia4Hadler = ({ variant }) => {
  return variant === 'youranswer' && 'width:270px;'
}
const adminNameInputMedia3Handler = ({ variant }) => {
  return variant === 'username' && 'width:290px; height:39px;'
}
const passwordInputMedia3Handler = ({ variant }) => {
  return variant === 'password' && 'width:290px; height:39px;'
}
const nameInputMedia376px = ({ variant }) => {
  return variant === 'name' && 'height:47px;'
}
// cityHall
const adminCityHallMedia1300px = ({ variant }) => {
  return variant === 'cityHall' && 'width: 650px; height: 180px;'
}
const adminCityHallMedia1025px = ({ variant }) => {
  return variant === 'cityHall' && 'width: 450px; height: 180px;'
}
const adminCityHallMedia431px = ({ variant }) => {
  return variant === 'cityHall' && 'width: 330px; height: 150px;'
}
const adminCityHallMedia391px = ({ variant }) => {
  return variant === 'cityHall' && 'width: 300px; height: 130px;'
}
const questionAdminMedia5Hadnler = ({ variant }) => {
  return variant === 'question' && 'width: 400px;'
}
const youranswerAdminMedia5Handler = ({ variant }) => {
  return variant === 'youranswer' && 'width: 400px;'
}
const questionAdminMedia6Handler = ({ variant }) => {
  return variant === 'question' && 'width: 500px;'
}
const youranswerAdminMedia6Handler = ({ variant }) => {
  return variant === 'youranswer' && 'width: 500px;'
}
// appealsOfCitizen
const appealsName = ({ variant }) => {
  return (
    variant === 'appeals' &&
    'width: 400px; height: 48px; font-size:19px; border-radius: 15px;'
  )
}
const appealsNameMedia = ({ variant }) => {
  return (
    variant === 'appeals' &&
    'width: 350px; height: 48px; font-size:19px; border-radius: 15px;'
  )
}
const appealsNameMedias = ({ variant }) => {
  return (
    variant === 'appeals' &&
    'width: 300px; height: 40px; font-size:19px; border-radius: 15px;'
  )
}
const appealsNameInput = ({ variant }) => {
  return (
    variant === 'appeals' &&
    'width: 400px; height: 45px; font-size:19px; border-radius: 15px; padding: 0 10px 0 10px;'
  )
}
const appealsNameInputMedia = ({ variant }) => {
  return (
    variant === 'appeals' &&
    'width: 330px; height: 45px; font-size:19px; border-radius: 15px; padding: 0 10px 0 0;'
  )
}
const appealsNameInputMedias = ({ variant }) => {
  return (
    variant === 'appeals' &&
    'width: 20em; height: 38px; font-size:19px; border-radius: 15px; padding: 0 10px 0 0;'
  )
}
const Div = styled.div`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 15px;
  font-family: Poppins, sans-serif;
  margin-top: 10px;
  padding: 0 10px 0 0;
  border: 1px solid #0a0082;
  ${nameHandler}
  ${emailHandler}
  ${textHandler}
  ${adminNameHandler}
  ${passwordHandler}
  ${nameAdminHandler}
  ${textAdminHandler}
  ${userAdminHadler}
  ${citykHallHandler}
  ${questionAdminHadnler}
  ${youranswerAdminHadler}
  ${nameAdmin}
  ${name2Admin}
  ${name3Admin}
  ${appealsName}
  @media (max-width: 1025px) {
    ${questionAdminMedia6Handler}
    ${youranswerAdminMedia6Handler}
  }
  @media (max-width: 540px) {
    ${youranswerAdminMedia5Handler}
    ${questionAdminMedia5Hadnler}
    ${citykHallHandler}
    ${calendarAdminHandlerMedia}
  }
  @media (max-width: 1300px) {
    ${adminCityHallMedia1300px}
  }
  @media (max-width: 1025px) {
    ${adminCityHallMedia1025px}
  }
  @media (max-width: 1025px) {
    ${adminCityHallMedia1025px}
  }
  @media (max-width: 431px) {
    ${divVariantNameInput}
    ${textAdminMedia2Hadler}
    ${nameAdminInputMediaHandler}
    ${questionAdminInputMedia2Hadnler}
    ${youranswerAdminInputMedia2Hadler}
    ${adminCityHallMedia431px}
    ${nameAdminInputMediaHandler}
    ${appealsNameMedia}
    ${textHandlerMedia}
  }
  @media (max-width: 391px) {
    ${adminCityHallMedia391px}
    ${appealsNameMedias}
  }
  @media (max-width: 376px) {
    ${nameAdminInputMedia2Handler}
    ${nameInputMediaHandler}
    ${nameInputMedia376px}
    ${emailInputMediaHandler}
    ${textHandlerMedia}
    ${adminNameInputMediaHandler}
    ${passwordInputMediaHandler}
    ${textAdminMediaHadler}
    ${userAdminInputMediaHadler}
    ${questionAdminInputMediaHadnler}
    ${youranswerAdminInputMediaHadler}
    ${nameAdminInputMedia}
    ${name2AdminInputMedia}
    ${name3AdminInputMedia}
  }

  @media (max-width: 361px) {
    ${adminNameInputMedia3Handler}
    ${passwordInputMedia3Handler}
    ${questionAdminInputMedia3Hadnler}
    ${youranswerAdminInputMedia3Hadler}
  }
  @media (max-width: 281px) {
    ${nameAdminInputMedia4Handler}
    ${textAdminMedia4Hadler}
    ${questionAdminInputMedia4Hadnler}
    ${youranswerAdminInputMedia4Hadler}
  }
  padding: 0;
`

const Textarea = styled.textarea`
  width: 550px;
  height: 155px;
  padding: 10px 5px 0 10px;
  border-radius: 15px;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  border: none;
  outline: none;
  border: 1px solid #0a0082;
  background: #fff;
  resize: none;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  ${textAdminHandler}
  ${textHandler}
  ${citykHallHandler}
  ${calendarAdminHandler}
    ${youranswerAdminHadler}
    @media (max-width: 1300px) {
    ${adminCityHallMedia1300px}
  }
  @media (max-width: 415px) {
    ${nameAdminInputMedia2Handler}
    ${textAdminMedia2Hadler}
    ${questionAdminInputMedia2Hadnler}
    ${youranswerAdminInputMedia2Hadler}
    ${appealsNameInputMedia}
  }
  @media (max-width: 391px) {
    ${adminCityHallMedia391px}
  }
  @media (max-width: 376px) {
    ${nameInputMediaHandler}
    ${textHandlerMedia}
    ${adminNameInputMediaHandler}
    ${passwordInputMediaHandler}
    ${nameAdminInputMediaHandler}
    ${textAdminMediaHadler}
    ${userAdminInputMediaHadler}
    ${questionAdminInputMediaHadnler}
    ${youranswerAdminInputMediaHadler}
    ${nameAdminInputMedia}
    ${name2AdminInputMedia}
    ${name3AdminInputMedia}
    ${calendarAdminHandlerMediaS8}
  }

  @media (max-width: 361px) {
    ${questionAdminInputMedia3Hadnler}
    ${youranswerAdminInputMedia3Hadler}
  }
  @media (max-width: 281px) {
    ${textAdminMedia4Hadler}
    ${youranswerAdminInputMedia4Hadler}
  }
`
const IMG = styled.img`
  margin-left: 15px;
  width: 20px;
  margin-right: 10px;
`
const IMG2 = styled.img`
  margin-left: 15px;
  width: 20px;
  margin-right: 10px;
`

const Inputs = styled.input`
  margin: 0;
  padding-left: 10px;
  border: 1px solid #0a0082;
  ${nameInputHandler}
  ${emailInputHandler}
  ${adminNameInputHandler}
  ${passwordInputHandler}
  ${nameAdminInputHandler}
  ${userAdminInputHadler}
  ${questionAdminInputHadnler}
  ${youranswerAdminInputHadler}
  ${nameAdminInput}
  ${name2AdminInput}
  ${name3AdminInput}
  ${appealsNameInput}
  @media (max-width: 431px) {
    ${nameAdminInputMedia2Handler}
    ${textAdminMedia2Hadler}
    ${questionAdminInputMedia2Hadnler}
    ${youranswerAdminInputMedia2Hadler}
    ${appealsNameInputMedias}
  }
  @media (max-width: 376px) {
    ${nameInputMediaHandler}
    ${emailInputMediaHandler}
    ${textHandlerMedia}
    ${adminNameInputMediaHandler}
    ${passwordInputMediaHandler}
    ${nameAdminInputMediaHandler}
    ${textAdminMediaHadler}
    ${userAdminInputMediaHadler}
    ${questionAdminInputMediaHadnler}
    ${youranswerAdminInputMediaHadler}
    ${nameAdminInputMedia}
    ${name2AdminInputMedia}
    ${name3AdminInputMedia}
    height: ${({ variant }) => variant === 'nameAdmin' && '34px'};
  }
  @media (max-width: 361px) {
    ${questionAdminInputMedia3Hadnler}
    ${youranswerAdminInputMedia3Hadler}
  }
  @media (max-width: 281px) {
    ${questionAdminInputMedia4Hadnler}
    ${youranswerAdminInputMedia4Hadler}
  }
  margin-left: 0px;
  outline: none;
  border: none;
`
