/* eslint-disable no-unused-vars */
import {
  FullscreenControl,
  Map,
  Placemark,
  TypeSelector,
  YMaps,
} from '@pbe/react-yandex-maps'

export const GoogleMap = ({ variant, position, setPosition }) => {
  const defaultState = { center: [41.0365446, 72.4781503], zoom: 13 }
  const getPos = (e) => {
    const coords = e.get('coords')

    setPosition({
      lat: coords[0],
      lng: coords[1],
    })
  }
  return (
    <YMaps>
      <Map
        onClick={setPosition ? getPos : ''}
        defaultState={defaultState}
        className={variant === 'mini' ? 'minimap' : 'map'}
      >
        <FullscreenControl />
        <TypeSelector />
        {position && <Placemark geometry={[position.lat, position.lng]} />}
      </Map>
    </YMaps>
  )
}
