import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { postAdminTouristsMain } from '../../../services/admin/AdminAction'
import Button from '../../UI/Button'
import CardPhoto from '../../UI/CardPhoto'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminForTouristsAdd = () => {
  const [files, setFiles] = useState()
  const [format, setFormat] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector((state) => state.admin)
  const editTouristsPage = () => {
    if (format !== null) {
      dispatch(postAdminTouristsMain({ img: format }))
      if (state.touristsStatus) {
        navigate('/for-tourists')
      }
    }
  }
  return (
    <Container>
      <Bg>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />
        <div style={{ padding: '25px' }}>
          <CardPhoto
            variant='photo'
            files={files}
            setFiles={setFiles}
            setFormat={setFormat}
          />
          <AddButton>
            <Button onClick={editTouristsPage} variant='button2'>
              <TranslatorDescription variant='save' />
            </Button>
          </AddButton>
        </div>
      </Bg>
    </Container>
  )
}
export default AdminForTouristsAdd

const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
`
const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
`
const Bg = styled.div`
  background: white;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid;
  box-shadow: 0 0 2px gray;
`
const AddButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20vh;
`
