/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminNews,
  getAdminNews,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import Card from '../../UI/Card'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../UI/SnackBar'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminNewsPage = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const dispatch = useDispatch()
  const state = useSelector((state) => state.admin)

  const { newsSnackState, newsAddStatus, newsEditStatus, newsDeleteStatus } =
    useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  const [count, setCount] = useState(6)
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const navigate = useNavigate()

  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getAdminNews({ count }))
  }, [count])
  const deleteAdminNewsHanlder = () => {
    dispatch(deleteAdminNews({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.newsAddSnackBar({
        newsSnackState: false,
        newsAddStatus,
      })
    )
    dispatch(
      adminAction.newsEditSnackBar({
        newsSnackState: false,
        newsEditStatus,
      })
    )
    dispatch(
      adminAction.newsDeleteSnackBar({
        newsSnackState: false,
        newsDeleteStatus,
      })
    )
  }

  const addNewsPage = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const edit = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  return (
    <StyledDivNews>
      {ModalState && (
        <ModalDeleteContent
          variant=''
          Cancellation={Cancellation}
          deletedFunction={deleteAdminNewsHanlder}
        />
      )}
      {newsSnackState && (
        <SnackBar
          message={
            newsAddStatus === 'success'
              ? (translator.ru && ru.messageSaccess) ||
                (translator.en && en.messageSaccess) ||
                (translator.kyr && kyr.messageSaccess)
              : newsEditStatus === 'success'
              ? (translator.ru && ru.messageEditSaccess) ||
                (translator.en && en.messageEditSaccess) ||
                (translator.kyr && kyr.messageEditSaccess)
              : newsDeleteStatus === 'success'
              ? (translator.ru && ru.messageDeleteSaccess) ||
                (translator.en && en.messageDeleteSaccess) ||
                (translator.kyr && kyr.messageDeleteSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={newsAddStatus || newsEditStatus || newsDeleteStatus}
          open={newsSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      {state.news?.map((element) => (
        <StyledNews key={element.id}>
          <Card
            variant='news-page-admin'
            title={element.title}
            date={element.date?.slice(0, 3)}
            description={element.description}
            img={element.img}
            onDelete={() => deletedHandler(element.id)}
            onEdit={() => edit(element.id)}
          />
        </StyledNews>
      ))}
      <StyledButton>
        {state.news?.length >= 6 && (
          <Button variant='button1' onClick={countHandler}>
            <TranslatorDescription variant='more' />
          </Button>
        )}
        {state.news?.length <= 0 && (
          <StyledDivText>
            <DataNotFoundTranslator variant='news' />
          </StyledDivText>
        )}
        <Button variant='button1' onClick={addNewsPage}>
          <TranslatorDescription variant='add' />
        </Button>
      </StyledButton>
    </StyledDivNews>
  )
}

export default AdminNewsPage

const StyledDivNews = styled.div`
  margin-left: 25px;
  @media (max-width: 1300px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
`

const StyledNews = styled.div`
  margin-top: 15px;
`
const StyledButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
  gap: 90px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
