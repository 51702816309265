import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminFaq,
  getAdminFaq,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import Quick from '../../UI/Quick'
import { SnackBar } from '../../UI/SnackBar'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminFaq = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const [question, setQuestion] = useState()
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const dispatch = useDispatch()
  const state = useSelector((state) => state.admin)

  const { FAQSnackState, FAQDeleteStatus } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  useEffect(() => {
    dispatch(getAdminFaq())
  }, [])
  const deleteAdminFaqHandler = () => {
    dispatch(deleteAdminFaq({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const navigate = useNavigate()
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.FAQDeleteSnackBar({
        FAQSnackState: false,
        FAQDeleteStatus: null,
      })
    )
  }
  const navToAppealsPage = () => {
    navigate('/appeals-of-citizens')
    closeSnackBarHandler()
  }
  return (
    <Container>
      {ModalState && (
        <ModalDeleteContent
          variant='FAQ'
          Cancellation={Cancellation}
          deletedFunction={deleteAdminFaqHandler}
        />
      )}
      {FAQSnackState && (
        <StyledSnackBarDiv>
          <SnackBar
            message={
              FAQDeleteStatus === 'success'
                ? (translator.ru && ru.messageDeleteSaccess) ||
                  (translator.en && en.messageDeleteSaccess) ||
                  (translator.kyr && kyr.messageDeleteSaccess)
                : (translator.ru && ru.messageError) ||
                  (translator.en && en.messageError) ||
                  (translator.kyr && en.messageError)
            }
            variant={FAQDeleteStatus}
            open={FAQSnackState}
            handleClose={closeSnackBarHandler}
          />
        </StyledSnackBarDiv>
      )}
      <StyledDivContainer>
        {state.faq?.length > 0 && (
          <StyledDivQuestion>
            <Quick
              quickDatas={state.faq}
              selectedQuestions={question}
              setSelectedQuestions={setQuestion}
              variant='admin'
              deleteFaq={deletedHandler}
            />
          </StyledDivQuestion>
        )}
        {state.faq?.length <= 0 && (
          <StyledDivText>
            <DataNotFoundTranslator variant='' />
          </StyledDivText>
        )}
        <StyledDivButton>
          <Button onClick={navToAppealsPage} variant='show-button'>
            <TranslatorDescription variant='appeals' />
          </Button>
        </StyledDivButton>
      </StyledDivContainer>
    </Container>
  )
}

export default AdminFaq

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const StyledDivContainer = styled.div`
  margin-top: 40px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  @media (max-width: 913px) {
    margin-left: 65px;
  }
  @media (max-width: 541px) {
    margin-left: 35px;
  }
  @media (max-width: 431px) {
    margin-left: 35px;
  }
  @media (max-width: 394px) {
    margin-left: 27px;
  }
`
const StyledDivQuestion = styled.div`
  width: 80%;
  height: max-content;
  background-color: #ffffff;
  border: 1px solid rgba(10, 0, 130, 1);
  border-radius: 15px;
  padding: 10px 30px 10px 15px;
  @media (max-width: 1300px) {
    width: 90%;
    height: 100%;
    padding: 10px 30px 10px 15px;
  }
`
const StyledDivButton = styled.div`
  margin-left: 50px;
  display: flex;
  align-items: end;
  justify-content: end;
  @media (max-width: 1300px) {
    margin: 0;
    width: 90%;
  }
`
const StyledSnackBarDiv = styled.div`
  position: absolute;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
