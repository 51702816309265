/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { getAdminCalendar } from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import CalendarOfEvents from '../../UI/CalendarOfEvents'
import { SnackBar } from '../../UI/SnackBar'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminCalendar = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminCalendar())
  }, [])
  const state = useSelector((state) => state.admin)

  const { CalendarSnackState, CalendarAddStatus, CalendarDeleteStatus } =
    useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  const navigate = useNavigate()

  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.CalendarAddSnackBar({
        CalendarSnackState: false,
        CalendarAddStatus: null,
      })
    )
    dispatch(
      adminAction.CalendarDeleteSnackBar({
        CalendarSnackState: false,
        CalendarDeleteStatus: null,
      })
    )
  }

  const navToCurrentEvent = ({ id, title }) => {
    navigate(`${title}/${id}`)
    closeSnackBarHandler()
  }
  const addEvent = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const date = new Date()
  return (
    <Container>
      {CalendarSnackState && (
        <SnackBar
          message={
            CalendarAddStatus === 'success'
              ? (translator.ru && ru.messageSaccess) ||
                (translator.en && en.messageSaccess) ||
                (translator.kyr && kyr.messageSaccess)
              : CalendarDeleteStatus === 'success'
              ? (translator.ru && ru.messageDeleteSaccess) ||
                (translator.en && en.messageDeleteSaccess) ||
                (translator.kyr && kyr.messageDeleteSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={CalendarAddStatus || CalendarDeleteStatus}
          open={CalendarSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      <DivInput>
        <div>
          <StyledText>{date.getFullYear()}</StyledText>
        </div>
        <StyledDiv>
          <Div>
            {state.calendar?.map((element) => (
              <StyledDivs key={element.id}>
                <CalendarOfEvents
                  name={element.name}
                  calendarOfEvents={element.month}
                  day={element.day}
                  navToCurrentEvent={() =>
                    navToCurrentEvent({
                      id: element.id,
                      title: element.day + element.month,
                    })
                  }
                />
              </StyledDivs>
            ))}
            <Button onClick={addEvent} variant='button1'>
              <TranslatorDescription variant='add' />
            </Button>
          </Div>
        </StyledDiv>
      </DivInput>
    </Container>
  )
}
export default AdminCalendar
const StyledDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  height: max-content;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 20px 40px 20px;
  @media (max-width: 1025px) {
    margin: 0;
    width: 95%;
  }
  @media (max-width: 913px) {
    margin: 20px;
    width: 100%;
  }
  @media (max-width: 865px) {
    margin: 0px;
    width: 95%;
  }
`

const Container = styled.div`
  @media (max-width: 865px) {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
`
const StyledText = styled.p`
  font-family: Inter, sans-serif;
  user-select: none;
  font-size: 70px;
  margin-top: 25px;
  margin-left: 40px;
  @media (max-width: 375px) {
    font-size: 50px;
  }
  @media (max-width: 215px) {
    font-size: 50px;
  }
`
const StyledDivs = styled.div`
  width: 250px;
  height: 50px;
  margin-bottom: 20px;
`
const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
  }
`
const Div = styled.div`
  height: max-content;
  user-select: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
  @media (max-width: 1281px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 913px) {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  @media (max-width: 820px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 613px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 541px) {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  @media (max-width: 415px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 375px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 325px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 281px) {
    margin: 0;
    padding: 0;
  }
`
