import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { postAdminContacts } from '../../../services/admin/AdminAction'
import Button from '../../UI/Button'
import Input from '../../UI/Inputs'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminContactsAdd = () => {
  const [input, setInput] = useState({
    name: '',
    surname: '',
    number: '',
    email: '',
    job: '',
  })
  const nameInputHandler = (event) => {
    setInput({
      ...input,
      name: event.target.value,
    })
  }
  const surnameInputHandler = (event) => {
    setInput({
      ...input,
      surname: event.target.value,
    })
  }
  const numberInputHandler = (event) => {
    setInput({
      ...input,
      number: event.target.value,
    })
  }
  const emailInputHandler = (event) => {
    setInput({
      ...input,
      email: event.target.value,
    })
  }
  const jobInputHandler = (event) => {
    setInput({
      ...input,
      job: event.target.value,
    })
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const addContact = () => {
    if (
      input.name !== '' &&
      input.number !== '' &&
      input.email !== '' &&
      input.surname !== '' &&
      input.job !== ''
    ) {
      const body = {
        firstName: input.name,
        lastName: input.surname,
        number: input.number,
        email: input.email,
        jobTitle: input.job,
      }
      dispatch(postAdminContacts({ body }))
      navigate('/contacts')
    }
  }
  return (
    <Container>
      <Bg>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />
        <StyledDivContainer>
          <Div1>
            <Inputs>
              <P>
                <TranslatorDescription variant='names' />
              </P>
              <Input
                onChange={nameInputHandler}
                value={input.name}
                tipe='input'
                variant='name'
              />
              <P>
                <TranslatorDescription variant='surname' />
              </P>
              <Input
                onChange={surnameInputHandler}
                value={input.surname}
                tipe='input'
                variant='name'
              />
              <P>
                <TranslatorDescription variant='number' />
              </P>
              <Input
                onChange={numberInputHandler}
                value={input.number}
                tipe='input'
                variant='name'
              />
              <P>
                <TranslatorDescription variant='mail' />
              </P>
              <Input
                onChange={emailInputHandler}
                value={input.email}
                tipe='input'
                variant='name'
              />
              <P>
                <TranslatorDescription variant='job-title' />
              </P>
              <Input
                onChange={jobInputHandler}
                value={input.job}
                tipe='input'
                variant='name'
              />
            </Inputs>
            <ContainerDivButton>
              <DivButton>
                <Button onClick={addContact} variant='button1'>
                  <TranslatorDescription variant='save' />
                </Button>
              </DivButton>
            </ContainerDivButton>
          </Div1>
        </StyledDivContainer>
      </Bg>
    </Container>
  )
}

export default AdminContactsAdd
const Container = styled.div`
  width: 100%;
  margin-left: 30px;
  margin-top: 30px;
  @media (max-width: 1300px) {
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
  margin-bottom: 30px;
`
const Bg = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  width: 900px;
  height: 600px;
  @media (max-width: 1300px) {
    width: 95%;
  }
`
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
`
const StyledDivContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
`
const Inputs = styled.div`
  margin-top: 10px;
  @media (max-width: 376px) {
    margin: 0;
  }
  @media (max-width: 415px) {
    margin: 0;
  }
`
const P = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  margin-top: 4px;
`

const ContainerDivButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`
const DivButton = styled.div`
  margin-left: 10px;
  margin-top: 9px;
  @media (max-width: 376px) {
    margin: 0;
    margin-top: 10px;
    margin-left: 10px;
  }
`
const Div1 = styled.div``
