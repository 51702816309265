import React from 'react'
import styled from 'styled-components'
import strelkaIcon from '../../assets/quickIcons/Frame 131.svg'
import Button from './Button'

const Quick = ({
  variant,
  quickDatas,
  selectedQuestions,
  setSelectedQuestions,
  deleteFaq,
}) => {
  const handleQuestionClick = (index) => {
    setSelectedQuestions(index)
    if (selectedQuestions === index) {
      setSelectedQuestions(null)
    }
  }

  return (
    <div>
      {quickDatas?.map((element, index) => (
        <div>
          <StyledDivBorder key={element.id}>
            <QuestionContainer onClick={() => handleQuestionClick(index)}>
              <StyledQuestion>
                <StyeldDivQuestion>
                  <Number>{index + 1}.</Number>
                  <Question>{element.title}</Question>
                </StyeldDivQuestion>
                <div>
                  <ArrowIcon
                    onClick={() => handleQuestionClick(index)}
                    src={strelkaIcon}
                    alt='arrow'
                    active={selectedQuestions === index}
                  />
                </div>
              </StyledQuestion>
              {variant === 'admin' && (
                <StyledDiv>
                  <Button
                    onClick={() => deleteFaq(element.id)}
                    variant='delete-button-icon'
                  />
                </StyledDiv>
              )}
            </QuestionContainer>

            {selectedQuestions === index && <Answer>• {element.answer}</Answer>}
          </StyledDivBorder>
        </div>
      ))}
    </div>
  )
}

export default Quick

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    align-items: start;
  }
`

const Question = styled.div`
  max-width: 100%;
  color: #000;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  word-break: break-all;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`

const ArrowIcon = styled.img`
  width: 30px;
  cursor: pointer;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  margin-left: -20px;
`

const Answer = styled.div`
  width: 95.4%;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const StyledDivBorder = styled.h2`
  min-height: 30px;
  border-bottom: 1px solid #0a0082;
  padding-left: 3px;
  width: 100%;
  margin-top: 10px;
`
const StyledDiv = styled.div`
  margin-left: 13px;
  margin-right: 4px;
  z-index: 1;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`
const StyledQuestion = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  @media (max-width: 600px) {
    align-items: start;
  }
`
const StyeldDivQuestion = styled.div`
  display: flex;
  align-items: start;
  margin-right: 33px;
`
const Number = styled.p`
  margin-top: 2px;
  color: #000;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 15px;
  }
`
