import React from 'react'
import { useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { english } from '../../utils/constants/lang/english'
import { kyrgyz } from '../../utils/constants/lang/kyrgyz'
import { russian } from '../../utils/constants/lang/russian'

const TextCard = ({
  clickvariant,
  onClick,
  colorVariant,
  children,
  variant,
}) => {
  const ru = russian.textcard
  const en = english.textcard
  const kyr = kyrgyz.textcard
  const state = useSelector((state) => state.translator)
  return (
    <div>
      <Container>
        <StyledDiv
          onClick={clickvariant === 'disabled' ? '' : onClick}
          colorVariant={colorVariant}
          variant={variant}
        >
          <StyledText colorVariant={colorVariant}>
            <p>
              {(variant === 'hotels'
                ? (state.ru && ru.hotels) ||
                  (state.en && en.hotels) ||
                  (state.kyr && kyr.hotels)
                : '') ||
                (variant === 'cafes'
                  ? (state.ru && ru.cafes) ||
                    (state.en && en.cafes) ||
                    (state.kyr && kyr.cafes)
                  : '') ||
                (variant === 'attractions'
                  ? (state.ru && ru.attractions) ||
                    (state.en && en.attractions) ||
                    (state.kyr && kyr.attractions)
                  : '') ||
                children}
            </p>
          </StyledText>
        </StyledDiv>
      </Container>
    </div>
  )
}
export default TextCard

const Container = styled.div`
  display: flex;
`
const StyledText = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: normal;
  color: ${({ colorVariant }) =>
    colorVariant === 'blue' && 'rgba(255, 255, 255, 1)'};
  @media (max-width: 430px) {
    font-size: 25px;
  }
`
const StyledDiv = styled.div`
  width: 300px;
  height: 70px;
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  border: ${({ colorVariant }) =>
    colorVariant === 'blue' ? 'none' : '2px solid #000'};
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colorVariant }) =>
    colorVariant === 'blue' && 'rgba(10, 3, 89, 1)'};
  @media (max-width: 430px) {
    width: 350px;
  }
`
