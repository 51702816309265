import React, { useState } from 'react'

import styled from 'styled-components'

function ScrollUp() {
  const [scroll, setScroll] = useState(false)
  window.onscroll = () => {
    scrollFunction()
  }

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  const handlerScrollUp = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }
  return (
    scroll && (
      <ScrollIcon
        src='https://cdn-icons-png.flaticon.com/512/9463/9463913.png'
        alt='none'
        onClick={handlerScrollUp}
      />
    )
  )
}

export default ScrollUp

const ScrollIcon = styled.img`
  width: 50px;
  position: fixed;
  cursor: pointer;
  left: 100;
  right: 0;
  top: 100;
  bottom: 0;
  transition: 0.2s;
`
