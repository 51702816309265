import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'

const DataNotFoundTranslator = ({ variant }) => {
  const dataNotFoundrus = russian.dataNotFound
  const dataNotFoundeng = english.dataNotFound
  const dataNotFoundkyr = kyrgyz.dataNotFound

  const translator = useSelector((state) => state.translator)

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {variant === '' && (
        <StyledText>
          {(translator.ru && dataNotFoundrus.dataNotFound) ||
            (translator.en && dataNotFoundeng.dataNotFound) ||
            (translator.kyr && dataNotFoundkyr.dataNotFound)}
        </StyledText>
      )}
      {variant === 'news' && (
        <StyledText>
          {(translator.ru && dataNotFoundrus.newsNotFound) ||
            (translator.en && dataNotFoundeng.newsNotFound) ||
            (translator.kyr && dataNotFoundkyr.newsNotFound)}
        </StyledText>
      )}
      {variant === 'contacts' && (
        <StyledText>
          {(translator.ru && dataNotFoundrus.contactsNotFound) ||
            (translator.en && dataNotFoundeng.contactsNotFound) ||
            (translator.kyr && dataNotFoundkyr.contactsNotFound)}
        </StyledText>
      )}
      {variant === 'tourists' && (
        <StyledText>
          {(translator.ru && dataNotFoundrus.touristsPhotoNotFound) ||
            (translator.en && dataNotFoundeng.touristsPhotoNotFound) ||
            (translator.kyr && dataNotFoundkyr.touristsPhotoNotFound)}
        </StyledText>
      )}
    </>
  )
}

export default DataNotFoundTranslator

const StyledText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 500;
`
