import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { getUserMunicipal } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Card from '../UI/Card'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserProgram = () => {
  const state = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [count, setCount] = useState(6)

  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getUserMunicipal({ count }))
  }, [count])
  return (
    <DivMain municipals={state.municipal}>
      {state.municipal.length > 0 ? (
        <>
          {state.municipal?.map((element) => (
            <DivCard key={element.id}>
              <Card
                key={element.id}
                variant='municipal-page'
                img={element.image}
                title={element.title}
                description={element.description}
                date={element.date}
                senderText={element.senderText}
                sender={element.sender}
              />
            </DivCard>
          ))}
          {state.municipal.length >= 6 && (
            <DivStyledBtn>
              <StyledBtn>
                <Button variant='button1' onClick={countHandler}>
                  <TranslatorDescription variant='more' />
                </Button>
              </StyledBtn>
            </DivStyledBtn>
          )}
        </>
      ) : (
        <StyledDivText>
          <DataNotFoundTranslator variant='' />
        </StyledDivText>
      )}
    </DivMain>
  )
}
export default UserProgram

const DivMain = styled.div`
  ${({ municipals }) => {
    return municipals.length > 0
      ? 'display: flex; flex-flow: wrap; justify-content: flex-start; @media (max-width: 913px) { display: flex; justify-content: center; margin-right: 40px;  }'
      : ''
  }}
`
const DivCard = styled.div`
  margin-top: 30px;
  margin-left: 50px;
  @media (max-width: 1281px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 5px;
  }
  @media (max-width: 821px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 769px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 15px;
  }
  @media (max-width: 541px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 394px) {
    margin-left: 15px;
  }
  @media (max-width: 391px) {
    margin-left: 15px;
  }
  @media (max-width: 376px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 5px;
  }
  @media (max-width: 361px) {
  }
`
const DivStyledBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const StyledBtn = styled.div`
  margin-top: 30px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  padding-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`
