import React from 'react'
import { useSelector } from 'react-redux'
import { styled } from 'styled-components'
import emailIcon from '../../assets/tableIcons/Email.svg'
import jobIcon from '../../assets/tableIcons/PermJob.svg'
import phoneIcon from '../../assets/tableIcons/Phone.svg'
import { english } from '../../utils/constants/lang/english'
import { kyrgyz } from '../../utils/constants/lang/kyrgyz'
import { russian } from '../../utils/constants/lang/russian'

const Table = ({
  tableDatas,
  clickvariant,
  onClickPhone,
  onClickEmail,
  onClickJob,
  variant,
  onEdit,
}) => {
  const state = useSelector((state) => state.translator)
  const ru = russian.table
  const en = english.table
  const kyr = kyrgyz.table
  return (
    <div>
      <Container>
        <Border>
          <StyledTable rules='rows'>
            <thead>
              <StyledHeaderRow>
                <StyledMainNumber> </StyledMainNumber>
                <StyledMainName>
                  {state.ru && ru.fullname}
                  {state.en && en.fullname}
                  {state.kyr && kyr.fullname}
                </StyledMainName>
                <StyledMainIcons>
                  <img src={phoneIcon} alt='none' />
                </StyledMainIcons>
                <StyledMainIcons>
                  <img src={emailIcon} alt='none' />
                </StyledMainIcons>
                <StyledMainIcons>
                  <img src={jobIcon} alt='none' />
                </StyledMainIcons>
                <StyledMain>
                  {state.ru && ru.number}
                  {state.en && en.number}
                  {state.kyr && kyr.number}
                </StyledMain>
                <StyledMain>
                  {state.ru && ru.email}
                  {state.en && en.email}
                  {state.kyr && kyr.email}
                </StyledMain>
                <StyledMain>
                  {state.ru && ru.post}
                  {state.en && en.post}
                  {state.kyr && kyr.post}
                </StyledMain>
              </StyledHeaderRow>
            </thead>
            <tbody>
              {tableDatas?.map((element) => (
                <StyledDataRow key={element.id}>
                  <StyledAll>{element.id}</StyledAll>
                  <StyledCellName>{element.fullName}</StyledCellName>
                  <StyledMainIcons
                    onClick={() =>
                      clickvariant === 'disabled'
                        ? ''
                        : onClickPhone(element.id)
                    }
                  >
                    <img src={phoneIcon} alt='none' />
                  </StyledMainIcons>
                  <StyledMainIcons
                    onClick={() =>
                      clickvariant === 'disabled'
                        ? ''
                        : onClickEmail(element.id)
                    }
                  >
                    <img src={emailIcon} alt='none' />
                  </StyledMainIcons>
                  <StyledMainIcons
                    onClick={() =>
                      clickvariant === 'disabled' ? '' : onClickJob(element.id)
                    }
                  >
                    <img src={jobIcon} alt='none' />
                  </StyledMainIcons>
                  <StyledCell>{element.number}</StyledCell>
                  <StyledCell>{element.email}</StyledCell>
                  <StyledAll>{element.jobTitle}</StyledAll>
                  {variant === 'admin' && (
                    <StyledEdit>
                      <StyledEditIcon
                        onClick={() => onEdit(element.id)}
                        src='https://www.freeiconspng.com/thumbs/edit-icon-png/edit-editor-pen-pencil-write-icon--4.png'
                        alt='none'
                      />
                    </StyledEdit>
                  )}
                </StyledDataRow>
              ))}
            </tbody>
          </StyledTable>
        </Border>
      </Container>
    </div>
  )
}

export default Table

const StyledTable = styled.table`
  height: 50px;
  width: 950px;
  font-size: 20px;
  @media (max-width: 415px) {
    width: 390px;
    height: 50px;
    margin: 0px;
    font-size: 15px;
    overflow: auto;
    border: 1px solid;
  }
`

const StyledHeaderRow = styled.tr`
  border-bottom: 1px solid black;
`

const StyledDataRow = styled.tr`
  height: 40px;
  border-left: 1px solid;
`
const StyledCell = styled.td`
  border: 1px solid black;
  padding-left: 10px;
  @media (max-width: 415px) {
    display: none;
  }
`
const StyledEdit = styled.td`
  border-right: 1px solid;
  border-left: 1px solid;
  padding-left: 10px;
  @media (max-width: 500px) {
    border-left: 0;
  }
`

const StyledCellName = styled.td`
  border: 1px solid black;
  padding-left: 10px;
  font-size: 18px;
`
const StyledAll = styled.td`
  text-align: center;
  font-size: 25px;
`
const StyledMain = styled.td`
  padding-bottom: 10px;
  font-size: 20px;
  border: 1px solid black;
  text-align: center;
  font-size: 21px;
  padding: 5px 19px 11px 20px;
  @media (max-width: 415px) {
    display: none;
  }
`
const StyledMainIcons = styled.td`
  border: 1px solid;
  display: none;
  @media (max-width: 415px) {
    display: table-cell;
    padding: 0 10px 0 10px;
  }
`
const StyledMainNumber = styled.td`
  padding-bottom: 10px;
  font-size: 20px;
  border: 1px solid black;
  text-align: center;
  font-size: 21px;
  padding: 5px 19px 11px 20px;
`

const StyledMainName = styled.td`
  padding-bottom: 10px;
  font-size: 25px;
  border: 1px solid black;
  text-align: center;
  padding: 5px 19px 11px 20px;
`
const Container = styled.div`
  overflow: auto;
`
const Border = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  width: 951px;
  background: #ffffff;
  @media (max-width: 415px) {
    width: max-content;
    font-size: 15px;
    border: none;
  }
`
const StyledEditIcon = styled.img`
  width: 25px;
  cursor: pointer;
  margin-top: 5px;
`
