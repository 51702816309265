export const english = {
  header: {
    title: 'Official website of the Kochkor-Ata City Hall',
  },
  sidebar: {
    main: 'Main page',
    news: 'News and announcement',
    faq: 'FAQ',
    about: 'About of city hall',
    appeals: 'Appeals from citizens',
    tourists: 'For tourists',
    contacts: 'Contacts',
    calendar: 'Calendar',
    municipal: 'Municipal programs',
  },
  buttons: {
    add: 'Add',
    more: 'More',
    appeals: 'Appeals',
    send: 'Send',
  },
  textcard: {
    hotels: 'Hotels',
    attractions: 'Attractions',
    cafes: 'Reustaurants and cafes',
  },
  table: {
    fullname: 'First name & Last Name',
    number: 'Number',
    email: 'Email address',
    post: 'Post',
  },
  errorpage: {
    title: 'Page not found',
    back: 'Go to previous page',
  },
  snackBar: {
    messageSaccess: 'Data added successfully!',
    dataSentSaccess: 'The data has been sent successfully!',
    photoAddSaccess: 'Photo added successfully!',
    messageEditSaccess: 'Data changed successfully!',
    photoEditSaccess: 'Photo successfully changed!',
    messageDeleteSaccess: 'Data deleted successfully!',
    photoDeleteSaccess: 'Photo successfully deleted!',
    messageError: 'An error has occurred. Please try again later!',
  },
  dataNotFound: {
    dataNotFound: 'There`s nothing here at the moment.',
    newsNotFound: 'There is no news here yet.',
    contactsNotFound: 'The contact list is empty.',
    touristsPhotoNotFound: 'There are no photos here yet',
  },
  modalContent: {
    deletedText: 'Are you sure to delete?',
    deletedPhoto: 'Are you sure you want to delete this photo?',
    deletedFAQ: 'Are you sure you want to delete this question?',
    deletedDatas: 'Are you sure you want to delete this data?',
    deletedContact: 'Are you sure you want to delete this contact?',
    deletedEvent: 'Are you sure you want to delete this event?',
    deleted: 'Delete',
    cancellation: 'Cancel',
  },
  footer: {
    info: 'Government sites',
    cityHall: 'About City Hall',
    republic: 'The State website',
    contacts: 'Contacts',
    job: 'Job openings',
    network: 'Social networks',
    security: '© 2023 All rights reserved',
  },
  descriptionDatas: {
    title: 'Name:',
    description: 'Description:',
    date: 'Show release date?',
    save: 'Save',
    add: 'Add',
    more: 'More',
    show: 'Show',
    price: 'per day',
    places: 'Places',
    wifi: {
      yes: 'Yes',
      no: 'No',
      both: 'Yes / No',
    },
    appeals: 'Appeals',
    job_title: 'Job title:',
    mail: 'Mail:',
    number: 'Number:',
    numberWhatsApp: 'WhatsApp number',
    complaint: 'Description of your complaint',
    surname: 'Surname:',
    name: 'Name:',
    question: 'Question',
    answer: 'Your answer',
    upload: {
      video: 'Upload a video',
      photo: 'Upload a Photo',
      button: 'Send',
    },
    citizenSend: 'Send and add FAQ',
    citizenIgnor: 'Ignore',
    archive: {
      title: 'Archive of questions',
      clear: 'Clear',
    },
    userquestion: 'Appeals',
  },
}
