// eslint-disable-next-line consistent-return
const LocalStorageFunc = ({ method, key, body }) => {
  if (method === 'get') {
    return JSON.parse(localStorage.getItem(key))
  }
  if (method === 'set') {
    return localStorage.setItem(key, JSON.stringify(body))
  }
  if (method === 'remove') {
    localStorage.removeItem(key)
  }
}

export default LocalStorageFunc
