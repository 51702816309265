/* eslint-disable no-unused-vars */
import { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import TranslatorDescription from './TranslatorContent/TranslatorDescription'

const Card = ({
  variant,
  img,
  title,
  name,
  description,
  date,
  senderText,
  sender,
  onDelete,
  onEdit,
  onAttractions,
  id,
}) => {
  const [show, setShow] = useState(false)
  const showHandler = () => {
    if (description.length > 420) {
      setShow((prev) => !prev)
    }
  }
  return (
    <StyledCard key={id} variant={variant}>
      <Block variant={variant}>
        {img?.includes('/') && (
          <CardImg variant={variant} src={img} alt='none' />
        )}
        {variant === 'main-page' || variant === 'main-page-admin' ? (
          <div>
            <TextBlock>
              <EventTitle variant={variant}>{title}</EventTitle>
              <EventComment variant={variant}>{description}</EventComment>
            </TextBlock>
            <SenderBlock>
              <SenderText>{senderText}</SenderText>
              <Sender>{sender}</Sender>
            </SenderBlock>
          </div>
        ) : (
          ''
        )}
        {variant === 'main-page-admin' && (
          <ButtonsBlock>
            <Button onClick={onDelete} variant='delete-button-icon' />
            <Button onClick={onEdit} variant='edit-button' />
          </ButtonsBlock>
        )}
        {variant === 'news-page' || variant === 'news-page-admin' ? (
          <NewsBlock>
            <EventTitle variant={variant}>{title}</EventTitle>
            <EventComment variant={variant}>{description}</EventComment>
            <DateOfPublicaton>
              {date && `${date?.slice(0, 1)}.${date?.slice(1, 2)}.`}
              {date?.slice(2, 3)}
            </DateOfPublicaton>
            {variant === 'news-page-admin' && (
              <ButtonsBlock>
                <Button onClick={onDelete} variant='delete-button-icon' />
                <Button onClick={onEdit} variant='edit-button' />
              </ButtonsBlock>
            )}
          </NewsBlock>
        ) : (
          ''
        )}
        {variant === 'tourists-page' || variant === 'tourists-page-admin' ? (
          <TouristsBlock>
            <TouristsLeftBlock variant={variant}>
              <EventTitle variant={variant}>{title}</EventTitle>
              <TouristsResponsiveBlock>
                <ShowButton>
                  <Button onClick={onAttractions} variant='show-button'>
                    <TranslatorDescription variant='show' />
                  </Button>
                </ShowButton>
                {variant === 'tourists-page-admin' && (
                  <ButtonsBlock>
                    <Button onClick={onDelete} variant='delete-button-icon' />
                    <Button onClick={onEdit} variant='edit-button' />
                  </ButtonsBlock>
                )}
              </TouristsResponsiveBlock>
            </TouristsLeftBlock>
            <TouristsResponsiveBlock>
              <Line />
            </TouristsResponsiveBlock>
            <EventComment variant={variant}>{description}</EventComment>
            <Buttons>
              <ShowButton>
                <Button onClick={onAttractions} variant='show-button'>
                  <TranslatorDescription variant='show' />
                </Button>
              </ShowButton>
              {variant === 'tourists-page-admin' && (
                <ButtonsBlock>
                  <Button onClick={onDelete} variant='delete-button-icon' />
                  <Button onClick={onEdit} variant='edit-button' />
                </ButtonsBlock>
              )}
            </Buttons>
          </TouristsBlock>
        ) : (
          ''
        )}
        {variant === 'municipal-page' || variant === 'municipal-page-admin' ? (
          <TextBlock>
            <EventTitle variant={variant}>{title}</EventTitle>
            <EventComment variant={variant}>{description}</EventComment>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {variant === 'municipal-page-admin' && (
                <ButtonsBlock>
                  <Button onClick={onDelete} variant='delete-button-icon' />
                  <Button onClick={onEdit} variant='edit-button' />
                </ButtonsBlock>
              )}
            </div>
          </TextBlock>
        ) : (
          ''
        )}
        {variant === 'about-page' || variant === 'about-page-admin' ? (
          <TextBlock>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <EventTitle variant={variant}>{title}</EventTitle>
              {variant === 'about-page-admin' && (
                <ButtonsBlock>
                  <Button onClick={onDelete} variant='delete-button-icon' />
                  <Button onClick={onEdit} variant='edit-button' />
                </ButtonsBlock>
              )}
            </div>
            <EventComment variant={variant}>
              {show ? description : description.slice(0, 420)}
            </EventComment>
            {description.length > 420 && (
              <IconBlock>
                {show ? (
                  <HideIcon
                    onClick={showHandler}
                    src='https://cdn-icons-png.flaticon.com/256/32/32213.png'
                    alt='none'
                  />
                ) : (
                  <ShowIcon
                    onClick={showHandler}
                    src='https://cdn-icons-png.flaticon.com/256/32/32213.png'
                    alt='none'
                  />
                )}
              </IconBlock>
            )}
          </TextBlock>
        ) : (
          ''
        )}
        {variant === 'calendar-page' || variant === 'calendar-page-admin' ? (
          <TextBlock>
            <CalendarTitle>{name}</CalendarTitle>
            <EventComment variant={variant}>{description}</EventComment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: 'max-content',
              }}
            >
              {variant === 'calendar-page-admin' && (
                <ButtonsBlock>
                  <Button onClick={onDelete} variant='delete-button-icon' />
                  <Button onClick={onEdit} variant='edit-button' />
                </ButtonsBlock>
              )}
            </div>
          </TextBlock>
        ) : (
          ''
        )}
      </Block>
    </StyledCard>
  )
}

export default Card

const mainPage = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:450px;padding: 10px 10px 10px 10px;'
    : ''
}
const mainPageResponsive = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:350px;padding: 10px 10px 10px 10px;'
    : ''
}
const mainPageResponsiveIpad = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:370px;padding: 10px 10px 10px 10px;'
    : ''
}

const mainPageResponsiveForPhone = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:350px;padding: 10px 10px 10px 10px;'
    : ''
}
const mainPageResponsiveForPhoneSecond = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:320px;padding: 10px 10px 10px 10px;'
    : ''
}
const newsPage = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:900px;border:1px solid;border-radius: 10px;'
    : ''
}
const newsPageNestHubMax = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:800px;border:1px solid;border-radius: 10px;'
    : ''
}
const newsPageNestHub = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:600px;border:1px solid;border-radius: 10px;'
    : ''
}
const newsPageResponsive = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:400px;border:1px solid;border-radius: 10px;'
    : ''
}
const newsPageResponsiveForPhone = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:350px;border:1px solid;border-radius: 10px;'
    : ''
}
const touristsPage = (props) => {
  return props.variant === 'tourists-page' ||
    props.variant === 'tourists-page-admin'
    ? 'width:900px;border:1px solid;border-radius: 10px;'
    : ''
}
const touristsPageResponsiveNesHubMax = (props) => {
  return props.variant === 'tourists-page' ||
    props.variant === 'tourists-page-admin'
    ? 'width:800px;'
    : ''
}
const touristsPageResponsive = (props) => {
  return props.variant === 'tourists-page' ||
    props.variant === 'tourists-page-admin'
    ? 'width:400px;padding:5px;border:1px solid;border-radius: 10px;'
    : ''
}
const touristsPageResponsiveForPhone = (props) => {
  return props.variant === 'tourists-page' ||
    props.variant === 'tourists-page-admin'
    ? 'width:360px;padding:5px;border:1px solid;border-radius: 10px;'
    : ''
}
const municpalPage = (props) => {
  return props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
    ? 'width:450px;padding: 10px 10px 10px 10px;'
    : ''
}
const municpalPageResponsiveForPhone = (props) => {
  return props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
    ? 'width:360px;padding: 10px 10px 10px 10px;'
    : ''
}
const municpalPageResponsiveForPhoneSecond = (props) => {
  return props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
    ? 'width:370px;padding: 10px 10px 10px 10px;'
    : ''
}
const aboutPage = (props) => {
  return props.variant === 'about-page' || props.variant === 'about-page-admin'
    ? 'width:1000px;padding:10px;border:1px solid;border-radius: 10px;'
    : ''
}
const aboutPageResponsive = (props) => {
  return props.variant === 'about-page' || props.variant === 'about-page-admin'
    ? 'width:600px;border:1px solid;border-radius: 10px;'
    : ''
}

const aboutPageResponsiveIpad = (props) => {
  return props.variant === 'about-page' || props.variant === 'about-page-admin'
    ? 'width:480px;border:1px solid;border-radius: 10px;'
    : ''
}
const aboutPageResponsiveIpadMini = (props) => {
  return props.variant === 'about-page' || props.variant === 'about-page-admin'
    ? 'width:400px;border:1px solid;border-radius: 10px;'
    : ''
}
const aboutPageResponsivePhone = (props) => {
  return props.variant === 'about-page' || props.variant === 'about-page-admin'
    ? 'width:350px;'
    : ''
}
const calendarPage = (props) => {
  return props.variant === 'calendar-page' ||
    props.variant === 'calendar-page-admin'
    ? 'width: 900px;padding:10px;'
    : ''
}
const calendarPageResponsive = (props) => {
  return props.variant === 'calendar-page' ||
    props.variant === 'calendar-page-admin'
    ? 'width:95%;'
    : ''
}

const calendarPageResponsiveIpad = (props) => {
  return props.variant === 'calendar-page' ||
    props.variant === 'calendar-page-admin'
    ? 'width:95%;'
    : ''
}
const calendarPageResponsiveIpadMini = (props) => {
  return props.variant === 'calendar-page' ||
    props.variant === 'calendar-page-admin'
    ? 'width:95%;'
    : ''
}
const calendarPageResponsivePhone = (props) => {
  return props.variant === 'calendar-page' ||
    props.variant === 'calendar-page-admin'
    ? 'width:95%;'
    : ''
}
const mainCardImg = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:100%;height:300px;border-radius:10px;'
    : ''
}
const mainCardImgResponsive = (props) => {
  return props.variant === 'main-page' || props.variant === 'main-page-admin'
    ? 'width:100%;height:250px;border-radius:10px;'
    : ''
}
const newsCardImg = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:250px;height:250px;border-top-left-radius:10px;border-bottom-left-radius:10px;'
    : ''
}
const newsCardImgNestHubMax = (props) => {
  return props.variant === 'news-page' || props.variant === 'news-page-admin'
    ? 'width:220px;height:200px;border-top-left-radius:10px;border-bottom-left-radius:10px;'
    : ''
}
const newsCardImgResponsive = (props) => {
  return props.variant === 'news-page' ||
    props.variant === 'news-page-responsive' ||
    props.variant === 'news-page-admin'
    ? 'width:100%;height:300px;border-top-left-radius:10px;border-radius:10px;padding:5px;'
    : ''
}
const touristsCardImg = (props) => {
  return props.variant === 'tourists-page' ||
    props.variant === 'tourists-page-admin'
    ? 'width:250px;height:250px;border-top-left-radius:10px;border-bottom-left-radius:10px;'
    : ''
}
const touristsCardImgResponsive = (props) => {
  return props.variant === 'tourists-page' ||
    props.variant === 'tourists-page-admin'
    ? 'width:100%;height:220px;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:0;'
    : ''
}
const municipalCardImg = (props) => {
  return props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
    ? 'width:100%;height:300px;border-radius:10px;'
    : ''
}
const municipalCardImgResponsive = (props) => {
  return props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
    ? 'width:100%;height:250px;border-radius:10px;'
    : ''
}
const StyledCard = styled.div`
  ${mainPage}
  ${newsPage}
  ${touristsPage}
  ${municpalPage}
  ${aboutPage}
  ${calendarPage}
  height: max-content;
  word-break: break-all;
  @media (max-width: 1300px) {
    ${aboutPageResponsive}
    ${calendarPageResponsive}
    ${mainPageResponsive}
    ${touristsPageResponsiveNesHubMax}
  }
  @media (max-width: 1281px) {
    ${aboutPageResponsive}
    ${calendarPageResponsive}
    ${mainPageResponsive}
    ${newsPageNestHubMax}
  }
  @media (max-width: 1025px) {
    ${touristsPageResponsive}
    ${newsPageNestHub}
    ${mainPageResponsive}
  }
  @media (max-width: 821px) {
    ${mainPageResponsiveIpad}
    ${aboutPageResponsiveIpad}
    ${calendarPageResponsiveIpad}
    ${municpalPageResponsiveForPhone}
  }
  @media (max-width: 769px) {
    ${aboutPageResponsiveIpadMini}
    ${calendarPageResponsiveIpadMini}
  }
  @media (max-width: 541px) {
    ${newsPageResponsive}
    ${mainPageResponsiveForPhone}
    ${municpalPageResponsiveForPhone}
    ${calendarPageResponsivePhone}
    ${aboutPageResponsivePhone}
  }
  @media (max-width: 391px) {
    ${mainPageResponsiveForPhoneSecond}
    ${newsPageResponsiveForPhone}
    ${touristsPageResponsiveForPhone}
    ${municpalPageResponsiveForPhoneSecond}
  }
  border-radius: 15px;
  background: #fffefe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`
const CardImg = styled.img`
  ${mainCardImg}
  ${newsCardImg}
  ${touristsCardImg}
  ${municipalCardImg}
  @media(max-width:1300px) {
    ${newsCardImgNestHubMax}
  }
  @media (max-width: 1025px) {
    ${touristsCardImgResponsive}
  }
  @media (max-width: 900px) {
    ${touristsCardImgResponsive}
  }
  @media (max-width: 541px) {
    ${newsCardImgResponsive}
    ${mainCardImgResponsive}
    ${municipalCardImgResponsive}
  }
`
const Block = styled.div`
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(197, 197, 197);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(41, 41, 98);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgb(15, 15, 62);
  }
  display: flex;
  flex-direction: ${(props) =>
    props.variant === 'main-page' ||
    props.variant === 'main-page-admin' ||
    props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
      ? 'column'
      : 'row'};
  @media (max-width: 541px) {
    flex-direction: ${(props) =>
      props.variant === 'news-page' || props.variant === 'news-page-admin'
        ? 'column'
        : ''};
  }
  @media (max-width: 1025px) {
    flex-direction: ${(props) =>
      props.variant === 'tourists-page' ||
      props.variant === 'tourists-page-admin'
        ? 'column'
        : ''};
  }
`
const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`
const EventTitle = styled.h1`
  width: 100%;
  color: #000;
  font-family: Inter, sans-serif;
  ${(props) =>
    props.variant === 'main-page' || 'main-page-admin'
      ? `
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-align: start;`
      : ''}
  ${(props) =>
    props.variant === 'news-page' || props.variant === 'news-page-admin'
      ? `
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `
      : ''}
  ${(props) =>
    props.variant === 'tourists-page' || props.variant === 'tourists-page-admin'
      ? `
  font-size: 22px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  `
      : ''}
  ${(props) =>
    props.variant === 'about-page' || props.variant === 'about-page-admin'
      ? `
  font-size: 35px;
font-style: italic;
font-weight: 700;
line-height: normal;
  `
      : ''}
  @media(max-width:1280px) {
    font-size: 20px;
  }
`
const EventComment = styled.p`
  color: #000;
  font-family: Inter, sans-serif;
  @media screen and (max-width: 450px) {
    max-height: max-content;
  }
  ${(props) =>
    props.variant === 'main-page' || 'main-page-admin'
      ? `
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  `
      : ''}
  ${(props) =>
    props.variant === 'news-page' || props.variant === 'news-page-admin'
      ? `
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow-y: auto;
  max-height: 150px;
  `
      : ''}
  ${(props) =>
    props.variant === 'tourists-page' || props.variant === 'tourists-page-admin'
      ? `
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;
  @media(max-width:450px){
   max-width:100%;
  }
  `
      : ''}
  ${(props) =>
    props.variant === 'municipal-page' ||
    props.variant === 'municipal-page-admin'
      ? `
  font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
  `
      : ''}
   ${(props) =>
    props.variant === 'about-page' || props.variant === 'about-page-admin'
      ? `
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
  `
      : ''}
  ${(props) =>
    props.variant === 'calendar-page' || props.variant === 'calendar-page-admin'
      ? `
  font-size: 25px;
font-style: italic;
font-weight: 400;
line-height: normal;
  `
      : ''}
`
const SenderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`
const Sender = styled.h6`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
  @media (max-width: 416px) {
    font-size: 15px;
  }
`
const SenderText = styled.p`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  width: 150px;
`
const ButtonsBlock = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
`
const NewsBlock = styled.div`
  padding: 20px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const DateOfPublicaton = styled.p`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: italic;
  text-align: end;
  font-weight: 400;
  line-height: normal;
`
const TouristsBlock = styled.div`
  padding: 13px 9px 6px 13px;
  display: flex;
  gap: 20px;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
`
const Line = styled.div`
  width: 1px;
  height: 150px;
  background: gray;
  margin-top: 30px;
`
const TouristsLeftBlock = styled.div`
  ${(props) =>
    props.variant === 'tourists-page'
      ? `
        display: flex;
  flex-direction: column;
  gap: 50px;
  `
      : `display: flex;
  flex-direction: column;
  justify-content: space-between;`}
`
const TouristsResponsiveBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 1025px) {
    display: none;
  }
`
const IconBlock = styled.div`
  display: flex;
  justify-content: center;
`
const ShowIcon = styled.img`
  width: 25px;
  rotate: 90deg;
  cursor: pointer;
`
const HideIcon = styled.img`
  rotate: -90deg;
  width: 25px;
  cursor: pointer;
`
const Buttons = styled.div`
  display: none;
  @media (max-width: 1025px) {
    display: block;
  }
`
const ShowButton = styled.div`
  display: flex;
  justify-content: center;
`

const CalendarTitle = styled.h3`
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
