/* eslint-disable quotes */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Arrow from '../../../../assets/DayIcon/Arrow 2.svg'
import { getAdminTouristsHotelsById } from '../../../../services/admin/AdminAction'
import { GoogleMap } from '../../../UI/Map'
import NetWifi from '../../../UI/NetWifi'
import TranslatorDescription from '../../../UI/TranslatorContent/TranslatorDescription'

const AdminCurrentHotel = () => {
  const navigate = useNavigate()
  const { hotelId } = useParams()
  const state = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminTouristsHotelsById({ id: hotelId }))
  }, [])

  return (
    <Display>
      <Container>
        <img
          onClick={() => {
            navigate(-1)
          }}
          src={Arrow}
          alt='error'
          style={{ width: '30px' }}
        />
        <Header>
          <Image src={state.touristsCurrentHotel?.image} alt='error' />
          <div>
            <Title>{state.touristsCurrentHotel?.title}</Title>
            <Price>
              <TranslatorDescription variant='price' />/
              {String(state.touristsCurrentHotel.price).replace("'", '')}$
            </Price>
          </div>
        </Header>
        <Description>{state.touristsCurrentHotel?.description}</Description>
        <Map>
          <div>
            <NetWifi
              variant={state.touristsCurrentHotel?.countReplaces && 'EmptyBed'}
              countPlace={state.touristsCurrentHotel?.countReplaces}
            />
            <NetWifi
              variant={
                (state.touristsCurrentHotel?.wiFi === 'NO' && 'No') ||
                (state.touristsCurrentHotel?.wiFi === 'YES' && 'Have')
              }
            />
          </div>
          <GoogleMap position={state.touristsCurrentHotel?.locationResponse} />
        </Map>
      </Container>
    </Display>
  )
}

export default AdminCurrentHotel

const Display = styled.div`
  margin-bottom: 20px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter, sans-serif;
  font-style: italic;
  @media screen and (max-width: 550px) {
    width: 400px;
    align-items: center;
    gap: 8px;
    padding: 10px;
  }
  @media screen and (max-width: 391px) {
    width: 360px;
    align-items: center;
    gap: 8px;
    padding: 10px;
  }
`
const Header = styled.header`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`
const Image = styled.img`
  width: 600px;
  height: 470px;
  border-radius: 15px;
  @media screen and (max-width: 550px) {
    border-radius: 15px 15px 0px 0px;
    width: 380px;
    height: 250px;
  }
  @media screen and (max-width: 391px) {
    width: 340px;
    height: 250px;
  }
`
const Title = styled.h2`
  width: 300px;
  font-weight: 500;
  @media screen and (max-width: 550px) {
    width: 100%;
    font-size: 20px;
  }
`
const Price = styled.h2`
  font-weight: 400;
  @media screen and (max-width: 550px) {
    width: 100%;
    font-size: 20px;
  }
`

const Description = styled.p`
  max-width: 900px;
  font-size: 18px;
  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
`
const Map = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;
  margin-top: 50px;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: start;
  }
`
