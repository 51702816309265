import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { getUserCityHall } from '../../services/User/UserAction'
import Card from '../UI/Card'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'

const UserCityHall = () => {
  const state = useSelector((state) => state.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserCityHall())
  }, [])
  return (
    <StyledDivContainer>
      <StyledDivContainerCard>
        {state.cityHall.length > 0 ? (
          state.cityHall?.map((element) => (
            <StyledDivCard key={element.id}>
              <Card
                variant='about-page'
                title={element.title}
                description={element.description}
              />
            </StyledDivCard>
          ))
        ) : (
          <StyledDivText>
            <StyledText>
              <DataNotFoundTranslator variant='' />
            </StyledText>
          </StyledDivText>
        )}
      </StyledDivContainerCard>
    </StyledDivContainer>
  )
}
export default UserCityHall

const StyledDivContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1300px) {
    width: 700px;
  }
  @media (max-width: 1281px) {
    width: 800px;
  }
  @media (max-width: 1025px) {
    width: 400px;
  }
  @media (max-width: 913px) {
    width: 906px;
  }
  @media (max-width: 821px) {
    width: 812px;
  }
  @media (max-width: 769px) {
    width: 760px;
  }
  @media (max-width: 541px) {
    width: 432px;
  }
  @media (max-width: 415px) {
    width: 409px;
  }
  @media (max-width: 413px) {
    width: 407px;
  }
  @media (max-width: 391px) {
    width: 382px;
  }
  @media (max-width: 394px) {
    width: 385px;
  }
  @media (max-width: 376px) {
    width: 370px;
  }
  @media (max-width: 361px) {
    width: 352px;
  }
`

const StyledDivContainerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledDivCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 0px 0px;
  @media (max-width: 1300px) {
    width: 100%;
    margin-top: 50px;
  }
  @media (max-width: 1281px) {
    width: 100%;
    margin-top: 50px;
  }
  @media (max-width: 1025px) {
    width: 100%;
    margin-top: 50px;
    margin-left: 250px;
  }
  @media (max-width: 916px) {
    width: 100%;
    margin-top: 50px;
    margin-left: 7px;
  }
  @media (max-width: 821px) {
    width: 100%;
    margin-top: 50px;
    margin-left: 7px;
  }
  @media (max-width: 769px) {
    width: 100%;
    margin-top: 50px;
    margin-left: 7px;
  }
  @media (max-width: 541px) {
    width: 100%;
    margin-top: 50px;
    margin-left: 100px;
  }
  @media (max-width: 415px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 7px;
  }
  @media (max-width: 413px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 7px;
  }
  @media (max-width: 391px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 7px;
  }
  @media (max-width: 376px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 7px;
  }
  @media (max-width: 361px) {
    width: 100%;
    margin-top: 20px;
    margin-left: 5px;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 391px) {
    margin: 0;
    div {
      margin: 0;
    }
  }
`
const StyledText = styled.h4`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 500;
`
