import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { getUserCalendar } from '../../services/User/UserAction'
import CalendarOfEvents from '../UI/CalendarOfEvents'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'

const UserMeropriyatii = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserCalendar())
  }, [])
  const state = useSelector((state) => state.user)
  const navigate = useNavigate()
  const navToCurrentEvent = ({ id }) => {
    navigate(`${id}`)
  }
  return (
    <Container>
      <DivInput>
        <div>
          <StyledText>{state.calendar[0]?.year}</StyledText>
        </div>
        {state.calendar.length > 0 ? (
          <StyledDiv>
            <Div>
              {state.calendar?.map((element) => (
                <StyledDivs>
                  <CalendarOfEvents
                    calendarOfEvents={element.month}
                    day={element.day}
                    navToCurrentEvent={() =>
                      navToCurrentEvent({
                        id: element.id,
                        title: element.day + element.month,
                      })
                    }
                  />
                </StyledDivs>
              ))}
            </Div>
          </StyledDiv>
        ) : (
          <StyledDivText>
            <DataNotFoundTranslator variant='' />
          </StyledDivText>
        )}
      </DivInput>
    </Container>
  )
}
export default UserMeropriyatii
const StyledDiv = styled.div`
  width: max-content;
  background-color: white;
  border-radius: 10px;
  display: flex;
  height: max-content;
  margin-left: 20px;
  padding: 20px 20px 40px 20px;
  @media (max-width: 1025px) {
    margin: 0;
    width: 95%;
  }
  @media (max-width: 913px) {
    margin: 20px;
    width: 100%;
  }
  @media (max-width: 865px) {
    margin: 0px;
    width: 95%;
  }
`

const Container = styled.div`
  @media (max-width: 865px) {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
`
const StyledText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 70px;
  margin-top: 25px;
  margin-left: 40px;
  @media (max-width: 375px) {
    font-size: 50px;
  }
  @media (max-width: 215px) {
    font-size: 50px;
  }
`
const StyledDivs = styled.div`
  height: 50px;
  margin-bottom: 20px;
`
const DivInput = styled.div`
  display: flex;
  flex-direction: column;
`
const Div = styled.div`
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: white;
  border-radius: 15px;
  @media (max-width: 1281px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 913px) {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  @media (max-width: 820px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 613px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 541px) {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  @media (max-width: 415px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 375px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 325px) {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 281px) {
    margin: 0;
    padding: 0;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin: 10px 0 0 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`
