import React from 'react'
import { styled } from 'styled-components'
import EmptyBed from '../../assets/netIcon/Empty Bed.svg'
import Net from '../../assets/netIcon/Vectornet.svg'
import TranslatorDescription from './TranslatorContent/TranslatorDescription'

const NetWifi = ({ variant, countPlace }) => {
  return (
    <div>
      {variant === 'Have' && (
        <Container>
          <img src={Net} alt='icon' />
          <Have>
            <TranslatorDescription variant='yes' />
          </Have>
        </Container>
      )}
      {variant === 'No' && (
        <Container>
          <Img src={Net} alt='icon' />
          <Have>
            <TranslatorDescription variant='no' />
          </Have>
        </Container>
      )}
      {variant === 'Choice' && (
        <Container>
          <Img src={Net} alt='icon' />
          <Have>
            <TranslatorDescription variant='both' />
          </Have>
        </Container>
      )}
      {variant === 'EmptyBed' && (
        <Container>
          <Img src={EmptyBed} alt='icon' />
          <Number>
            {countPlace || <TranslatorDescription variant='places' />}
          </Number>
        </Container>
      )}
    </div>
  )
}

export default NetWifi

const Container = styled.div`
  display: flex;
`
const Img = styled.img`
  @media screen and (max-width: 550px) {
    width: 30px;
  }
`
const Have = styled.p`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 25px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  @media screen and (max-width: 550px) {
    font-size: 20px;
  }
`
const Number = styled.p`
  font-size: 30px;
  margin-top: 25px;
  font-family: Inter, sans-serif;
  @media screen and (max-width: 550px) {
    font-size: 20px;
    margin-top: 8px;
  }
`
