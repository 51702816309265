/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiFetch, ApiFile } from '../../api/Fetch'
import {
  deleteAdminAboutPageUrl,
  deleteAdminFaqPageUrl,
  deleteAdminMainPageUrl,
  deleteAdminTouristsPageUrl,
  deleteAdminNewsPageUrl,
  getAdminAboutPageUrl,
  getAdminAppealsPageUrl,
  getAdminFaqPageUrl,
  getAdminMainPageUrl,
  getAdminTouristsPageUrl,
  postAdminAboutPageUrl,
  postAdminAppealsFaqPageUrl,
  postAdminAppealsPageUrl,
  postAdminMainPageUrl,
  postAdminNewsPageUrl,
  putAdminAboutPageUrl,
  putAdminAppealsPageUrl,
  putAdminMainPageUrl,
  putAdminNewsPageUrl,
  postAdminTouristsPageUrl,
  putAdminTouristsPageUrl,
  getAdminTouristsCafesPageUrl,
  postAdminTouristsCafesPageUrl,
  putAdminTouristsCafesPageUrl,
  deleteAdminTouristsCafesPageUrl,
  getAdminTouristsCurrentCafePageUrl,
  getAdminTouristsHotelsPageUrl,
  getAdminTouristsCurrentHotelPageUrl,
  postAdminTouristsHotelsPageUrl,
  putAdminTouristsHotelsPageUrl,
  deleteAdminTouristsHotelsPageUrl,
  getAdminTouristsAttractionsPageUrl,
  getAdminTouristsCurrentAttractionPageUrl,
  postAdminTouristsAttractionsPageUrl,
  putAdminTouristsAttractionsPageUrl,
  deleteAdminTouristsAttractionsPageUrl,
  getAdminMunicipalPageUrl,
  deleteAdminMunicipalPageUrl,
  putAdminMunicipalPageUrl,
  postAdminMunicipalPageUrl,
  getAdminContactsPageUrl,
  getAdminCurrentContactPageUrl,
  postAdminContactsPageUrl,
  putAdminContactsPageUrl,
  deleteAdminContactsPageUrl,
  getAdminCalendarPageUrl,
  getAdminCalendarEventPageUrl,
  putAdminCalendarPageUrl,
  deleteAdminCalendarPageUrl,
  postAdminCalendarPageUrl,
  getAdminCurrentAppealsPageUrl,
  getAdminAppealsArchivePageUrl,
  deleteAdminAppealsArchivePageUrl,
} from '../../utils/constants/url'

// MAIN
export const getAdminMain = createAsyncThunk(
  'adminMain/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminMainPageUrl + props.count,
      })
      const mainPage = []
      for (let i = 0; i < response.length; i++) {
        mainPage.push({
          id: response[i].id,
          title: response[i].title,
          description: response[i].description,
          photo: response[i].photo,
        })
      }
      return { mainPage: mainPage.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminMain = createAsyncThunk(
  'adminMain/post',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData()
      formData.append('image', props.img)
      await ApiFile({
        url: `${postAdminMainPageUrl}title=${props.title}&description=${props.description}&seeDate=${props.date}`,
        body: formData,
      })
      dispatch(getAdminMain({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminMain = createAsyncThunk(
  'adminMain/put',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${putAdminMainPageUrl}title=${props.title}&description=${props.description}&seeDate=${props.date}&id=${props.id}`,
        method: 'PUT',
        body: formData,
      })
      dispatch(getAdminMain({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminMain = createAsyncThunk(
  'adminMain/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminMainPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminMain({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// MAIN

// NEWS
export const getAdminNews = createAsyncThunk(
  'adminNews/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: `api/v1/news/for/admin/find/get/news?count=${props.count}`,
      })
      const news = []
      for (let i = 0; i < response.length; i++) {
        news.push({
          id: response[i].id,
          title: response[i].title,
          img: response[i].image,
          description: response[i].description,
          date: response[i].dateCreate,
        })
      }
      return { adminNews: news.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminNews = createAsyncThunk(
  'adminNews/post',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${postAdminNewsPageUrl}title=${props.title}&description=${props.description}&seeDate=${props.date}`,
        body: formData,
      })
      dispatch(getAdminNews({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminNews = createAsyncThunk(
  'adminNews/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminNewsPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminNews({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminNews = createAsyncThunk(
  'adminNews/put',
  async (props, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${putAdminNewsPageUrl}title=${props.title}&description=${props.description}&seeDate=${props.date}&id=${props.id}`,
        method: 'PUT',
        body: formData,
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// NEWS

// FAQ
export const getAdminFaq = createAsyncThunk(
  'adminFaq/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminFaqPageUrl,
      })
      const faq = []
      for (let i = 0; i < response.length; i++) {
        faq.push({
          id: response[i].appealId,
          title: response[i].description,
          answer: response[i].answer,
        })
      }
      return { faq }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminFaq = createAsyncThunk(
  'adminFaq/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminFaqPageUrl + props.id,
        method: 'PUT',
      })
      dispatch(getAdminFaq())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// FAQ

// ABOUT OF CITY HALL
export const getAdminAbout = createAsyncThunk(
  'adminAboutOfCityHall/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminAboutPageUrl,
      })
      return { about: response.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminAbout = createAsyncThunk(
  'adminAboutOfCityHall/post',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: postAdminAboutPageUrl,
        method: 'POST',
        body: props.body,
      })
      dispatch(getAdminAbout())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminAbout = createAsyncThunk(
  'adminAboutOfCityHall/put',
  async (props, { rejectWithValue }) => {
    try {
      await ApiFetch({
        url: putAdminAboutPageUrl + props.id,
        method: 'PUT',
        body: props.body,
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminAbout = createAsyncThunk(
  'adminAboutOfCityHall/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminAboutPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminAbout())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// ABOUT OF CITY HALL

// APPEALS OF CITIZENS
export const getAdminAppeals = createAsyncThunk(
  'adminAppealsOfCitizens/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminAppealsPageUrl,
      })
      return { appeals: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminCurrentAppeal = createAsyncThunk(
  'adminCurrentAppealsOfCitizens/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminCurrentAppealsPageUrl + props.id,
      })
      const data = {
        media: [],
        info: response,
      }
      for (let index = 0; index < response.photo.length; index++) {
        data.media.push({ img: response.photo[index] })
      }
      for (let index = 0; index < response.video.length; index++) {
        data.media.push({ video: response.video[index] })
      }
      return { currentAppeal: data }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postResponseAdminAppeals = createAsyncThunk(
  'adminAppealsOfCitizens/post',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: `${postAdminAppealsPageUrl}appealId=${props.id}&answer=${props.answer}`,
        method: 'POST',
      })
      dispatch(getAdminAppeals())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const postResponseAndAddToFaqAdminAppeals = createAsyncThunk(
  'adminAppealsOfCitiznesFaq/post',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: `${postAdminAppealsFaqPageUrl}appealId=${props.id}&answer=${props.answer}`,
        method: 'POST',
      })
      dispatch(getAdminAppeals())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putIgnoreAdminAppeals = createAsyncThunk(
  'adminAppealsOfCitizens/put',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: putAdminAppealsPageUrl + props.id,
        method: 'PUT',
      })
      dispatch(getAdminAppeals())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminAppealsArchive = createAsyncThunk(
  'adminAppealsOfCitizensArchive/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminAppealsArchivePageUrl,
      })
      return { appealsArchive: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminAppealsArchive = createAsyncThunk(
  'adminAppealsOfCitizensArchive/delete',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminAppealsArchivePageUrl,
        method: 'DELETE',
      })
      dispatch(getAdminAppealsArchive())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// APPEALS OF CITIZENS

// FOR TOURISTS
export const getAdminTouristsMain = createAsyncThunk(
  'adminForTourists/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsPageUrl,
      })
      const vertical = []
      const horizontal = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].id % 2 !== 0) {
          horizontal.push({
            id: response[i].id,
            img: response[i].image,
          })
        }
        if (response[i].id % 2 === 0) {
          vertical.push({
            id: response[i].id,
            img: response[i].image,
          })
        }
      }
      return { vertical, horizontal }
    } catch (error) {
      rejectWithValue(error.message)
    }
  }
)
export const postAdminTouristsMain = createAsyncThunk(
  'adminForTourists/post',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: postAdminTouristsPageUrl,
        body: formData,
      })
      dispatch(getAdminTouristsMain())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminTouristsMain = createAsyncThunk(
  'adminForTourists/put',
  async (props, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: putAdminTouristsPageUrl + props.id,
        method: 'PUT',
        body: formData,
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminTouristsMain = createAsyncThunk(
  'adminForTourists/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminTouristsPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminTouristsMain())
    } catch (error) {
      rejectWithValue(error.message)
    }
  }
)
// cafes       ----------------------------------------
export const getAdminTouristsCafes = createAsyncThunk(
  'adminForTouristsCafes/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsCafesPageUrl + props.count,
      })
      return { touristsCafes: response.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminTouristsCafesById = createAsyncThunk(
  'adminForTouristsCurrentCafe/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsCurrentCafePageUrl + props.id,
      })
      return { touristsCurrentCafe: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminTouristsCafes = createAsyncThunk(
  'adminForTouristsCafes/post',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${postAdminTouristsCafesPageUrl}name=${props.title}&description=${props.description}&wiFi=${props.wifi}&lat=${props.location.lat}&lng=${props.location.lng}`,
        body: formData,
      })
      dispatch(getAdminTouristsCafes({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminTouristsCafes = createAsyncThunk(
  'adminForTouristsCafes/put',
  async (props, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${putAdminTouristsCafesPageUrl}cafeId=${props.id}&name=${props.title}&description=${props.description}&wiFi=${props.wifi}&lat=${props.location.lat}&lng=${props.location.lng}'`,
        method: 'PUT',
        body: formData,
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminTouristsCafes = createAsyncThunk(
  'adminForTouristsCafes/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminTouristsCafesPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminTouristsCafes({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// hotels      ----------------------------------------
export const getAdminTouristsHotels = createAsyncThunk(
  'adminForTouristsHotels/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsHotelsPageUrl + props.count,
      })
      return { touristsHotels: response.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminTouristsHotelsById = createAsyncThunk(
  'adminForTouristsCurrentHotel/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsCurrentHotelPageUrl + props.id,
      })
      return { touristsCurrentHotel: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminTouristsHotels = createAsyncThunk(
  'adminForTouristsHotels/post',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${postAdminTouristsHotelsPageUrl}name=${props.title}&description=${props.description}&wiFi=${props.wifi}&lat=${props.location.lat}&lng=${props.location.lng}&countPlaces=${props.countPlaces}&price=${props.price}`,
        body: formData,
      })
      dispatch(getAdminTouristsHotels({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminTouristsHotels = createAsyncThunk(
  'adminForTouristsHotels/put',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${putAdminTouristsHotelsPageUrl}hotelId=${props.id}&name=${props.title}&description=${props.description}&wiFi=${props.wifi}&lat=${props.location.lat}&lng=${props.location.lng}&countPlaces=${props.countPlaces}&price=${props.price}'`,
        method: 'PUT',
        body: formData,
      })
      dispatch(getAdminTouristsHotels({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminTouristsHotels = createAsyncThunk(
  'adminForTouristsHotels/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminTouristsHotelsPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminTouristsHotels({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// attractions -----,-----------------------------------
export const getAdminTouristsAttractions = createAsyncThunk(
  'adminForTouristsAttractions/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsAttractionsPageUrl + props.count,
      })
      return { touristsAtrractions: response.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminTouristsAttractionsById = createAsyncThunk(
  'adminForTouristsCurrenttAttraction/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminTouristsCurrentAttractionPageUrl + props.id,
      })
      return { touristsCurrentAtrraction: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminTouristsAttractions = createAsyncThunk(
  'adminForTouristsAttractions/post',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${postAdminTouristsAttractionsPageUrl}name=${props.title}&description=${props.description}&wiFi=${props.wifi}&lat=${props.location.lat}&lng=${props.location.lng}`,
        body: formData,
      })
      dispatch(getAdminTouristsAttractions({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminTouristsAttractions = createAsyncThunk(
  'adminForTouristsAttractions/put',
  async (props, { rejectWithValue }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${putAdminTouristsAttractionsPageUrl}hotelId=${props.id}&name=${props.title}&description=${props.description}&wiFi=${props.wifi}&lat=${props.location.lat}&lng=${props.location.lng}'`,
        method: 'PUT',
        body: formData,
      })
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminTouristsAttractions = createAsyncThunk(
  'adminForTouristsAttractions/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminTouristsAttractionsPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminTouristsAttractions({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// FOR TOURISTS

// CONTACTS
export const getAdminContacts = createAsyncThunk(
  'adminContacts/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminContactsPageUrl,
      })
      return { adminContacts: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminCurrentContact = createAsyncThunk(
  'adminContacts/getById',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminCurrentContactPageUrl + props.id,
      })
      return { adminCurrentContact: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminContacts = createAsyncThunk(
  'adminContacts/post',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: postAdminContactsPageUrl,
        method: 'POST',
        body: props.body,
      })
      dispatch(getAdminContacts())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminContacts = createAsyncThunk(
  'adminContacts/put',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: putAdminContactsPageUrl + props.id,
        method: 'PUT',
        body: props.body,
      })
      dispatch(getAdminContacts())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminContacts = createAsyncThunk(
  'adminContacts/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminContactsPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminContacts())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// CONTACTS

// CALENDAR
export const getAdminCalendar = createAsyncThunk(
  'adminCalendar/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminCalendarPageUrl,
      })
      return { adminCalendar: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const getAdminCalendarEvent = createAsyncThunk(
  'adminCalendarEvent/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminCalendarEventPageUrl + props.id,
      })
      return { adminCalendarEvent: response }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminCalendar = createAsyncThunk(
  'adminCalendar/post',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: `${postAdminCalendarPageUrl}description=${props.description}&name=${props.name}`,
        method: 'POST',
        body: props.date,
      })
      dispatch(getAdminCalendar())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminCalendar = createAsyncThunk(
  'adminCalendar/put',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: `${putAdminCalendarPageUrl}description=${props.description}&name=${props.name}&calendarId=${props.id}`,
        method: 'PUT',
        body: props.date,
      })
      dispatch(getAdminCalendarEvent({ id: props.id }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminCalendar = createAsyncThunk(
  'adminCalendar/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminCalendarPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminCalendar())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// CALENDAR

// MUNICIPAL PROGRAMS
export const getAdminMunicipalPrograms = createAsyncThunk(
  'adminMunicipalProgram/get',
  async (props, { rejectWithValue }) => {
    try {
      const response = await ApiFetch({
        url: getAdminMunicipalPageUrl + props.count,
      })
      return { municipalPrograms: response.reverse() }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const postAdminMunicipalPrograms = createAsyncThunk(
  'adminMunicipalProgram/post',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${postAdminMunicipalPageUrl}title=${props.title}&description=${props.description}&seeDate=${props.seeDate}`,
        body: formData,
      })
      dispatch(getAdminMunicipalPrograms({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const putAdminMunicipalPrograms = createAsyncThunk(
  'adminMunicipalProgram/put',
  async (props, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('image', props.img)
    try {
      await ApiFile({
        url: `${putAdminMunicipalPageUrl}title=${props.title}&description=${props.description}&seeDate=${props.seeDate}&id=${props.id}`,
        method: 'PUT',
        body: formData,
      })
      dispatch(getAdminMunicipalPrograms({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export const deleteAdminMunicipalPrograms = createAsyncThunk(
  'adminMunicipalProgram/delete',
  async (props, { rejectWithValue, dispatch }) => {
    try {
      await ApiFetch({
        url: deleteAdminMunicipalPageUrl + props.id,
        method: 'DELETE',
      })
      dispatch(getAdminMunicipalPrograms({ count: 6 }))
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
// MUNICIPAL PROGRAMS
