import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import strelka from '../../../assets/icons/strelka.svg'
import {
  deleteAdminCalendar,
  getAdminCalendarEvent,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Card from '../../UI/Card'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../UI/SnackBar'

const AdminCalendarEvent = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const state = useSelector((state) => state.admin)

  const { CalendarSnackState, CalendarEditStatus } = useSelector(
    (state) => state.admin
  )

  const translator = useSelector((state) => state.translator)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ModalState, setModalState] = useState(false)
  const backToPrevPage = () => {
    navigate(-1)
  }
  const { id } = useParams()
  useEffect(() => {
    dispatch(getAdminCalendarEvent({ id }))
  }, [])
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.CalendarEditSnackBar({
        CalendarSnackState: false,
        CalendarEditStatus: null,
      })
    )
  }

  const editEvent = () => {
    navigate('edit')
    closeSnackBarHandler()
  }
  const deleteEvent = () => {
    dispatch(deleteAdminCalendar({ id }))
    navigate('/calendar')
    setModalState(false)
    closeSnackBarHandler()
  }
  const deletedHandler = () => {
    setModalState(true)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  return (
    <StyledDivContainer>
      {ModalState && (
        <ModalDeleteContent
          variant='Event'
          Cancellation={Cancellation}
          deletedFunction={deleteEvent}
        />
      )}
      {CalendarSnackState && (
        <SnackBar
          message={
            CalendarEditStatus === 'success'
              ? (translator.ru && ru.messageEditSaccess) ||
                (translator.en && en.messageEditSaccess) ||
                (translator.kyr && kyr.messageEditSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={CalendarEditStatus}
          open={CalendarSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      <StyledDivTextCard>
        <Img onClick={backToPrevPage} src={strelka} alt='error strelka.svg' />
      </StyledDivTextCard>
      <StyledDivContainerCard>
        <StyledDivCard key={state.calendarEvent?.id}>
          <Card
            variant='calendar-page-admin'
            description={state.calendarEvent?.description}
            onEdit={editEvent}
            onDelete={deletedHandler}
          />
        </StyledDivCard>
      </StyledDivContainerCard>
    </StyledDivContainer>
  )
}

export default AdminCalendarEvent

const StyledDivContainer = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1300px) {
    width: 800px;
  }
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 1100px) {
    width: 500px;
  }
  @media (max-width: 912px) {
    width: 780px;
  }
  @media (max-width: 800px) {
    width: 680px;
  }
  @media (max-width: 700px) {
    width: 580px;
  }
  @media (max-width: 600px) {
    width: 480px;
  }
  @media (max-width: 500px) {
    width: 380px;
  }
  @media (max-width: 400px) {
    width: 100%;
    margin-left: 20px;
  }
`
const StyledDivTextCard = styled.div`
  margin-left: 40px;
  margin-top: 25px;
`
const Img = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`

const StyledDivContainerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const StyledDivCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 55px;
  margin-top: 18px;
  @media (max-width: 451px) {
    margin-left: 20px;
  }
  @media (max-width: 391px) {
    margin-left: 0px;
  }
`
