import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getUserContacts } from '../../services/User/UserAction'
import Table from '../UI/Table'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'

const UserTable = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.user)
  useEffect(() => {
    dispatch(getUserContacts())
  }, [])
  return (
    <StyledDivContainer>
      <StyledDivContainerCard>
        <StyledDivCard>
          {state?.contacts.length > 0 ? (
            <Table clickvariant='disabled' tableDatas={state?.contacts} />
          ) : (
            <StyledDivText>
              <DataNotFoundTranslator variant='contacts' />
            </StyledDivText>
          )}
        </StyledDivCard>
      </StyledDivContainerCard>
    </StyledDivContainer>
  )
}

export default UserTable

const StyledDivContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  @media (max-width: 913px) {
    margin: 0;
    width: 880px;
  }
  @media (max-width: 821px) {
    margin: 0;
    width: 790px;
  }
  @media (max-width: 769px) {
    margin: 0;
    width: 725px;
  }
  @media (max-width: 541px) {
    width: 400px;
  }
  @media (max-width: 431px) {
    margin: 0;
    width: 400px;
  }
  @media (max-width: 415px) {
    margin: 0;
    width: 390px;
  }
  @media (max-width: 394px) {
    margin: 0;
    width: 360px;
  }
  @media (max-width: 391px) {
    margin: 0;
    width: 360px;
  }
  @media (max-width: 376px) {
    margin: 0;
    width: 300px;
  }
  @media (max-width: 361px) {
    margin: 0;
    width: 350px;
  }
`

const StyledDivContainerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledDivCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledDivText = styled.div`
  padding: 50px;
`
