import React from 'react'
import { useSelector } from 'react-redux'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'

const TranslatorDescription = ({ variant }) => {
  const ru = russian
  const en = english
  const kyr = kyrgyz

  const translator = useSelector((state) => state.translator)

  return (
    <>
      {variant === 'title' &&
        ((translator.ru && ru.descriptionDatas.title) ||
          (translator.en && en.descriptionDatas.title) ||
          (translator.kyr && kyr.descriptionDatas.title))}
      {variant === 'description' &&
        ((translator.ru && ru.descriptionDatas.description) ||
          (translator.en && en.descriptionDatas.description) ||
          (translator.kyr && kyr.descriptionDatas.description))}
      {variant === 'date' &&
        ((translator.ru && ru.descriptionDatas.date) ||
          (translator.en && en.descriptionDatas.date) ||
          (translator.kyr && kyr.descriptionDatas.date))}
      {variant === 'save' &&
        ((translator.ru && ru.descriptionDatas.save) ||
          (translator.en && en.descriptionDatas.save) ||
          (translator.kyr && kyr.descriptionDatas.save))}
      {variant === 'add' &&
        ((translator.ru && ru.descriptionDatas.add) ||
          (translator.en && en.descriptionDatas.add) ||
          (translator.kyr && kyr.descriptionDatas.add))}
      {variant === 'more' &&
        ((translator.ru && ru.descriptionDatas.more) ||
          (translator.en && en.descriptionDatas.more) ||
          (translator.kyr && kyr.descriptionDatas.more))}
      {variant === 'show' &&
        ((translator.ru && ru.descriptionDatas.show) ||
          (translator.en && en.descriptionDatas.show) ||
          (translator.kyr && kyr.descriptionDatas.show))}
      {variant === 'price' &&
        ((translator.ru && ru.descriptionDatas.price) ||
          (translator.en && en.descriptionDatas.price) ||
          (translator.kyr && kyr.descriptionDatas.price))}
      {variant === 'places' &&
        ((translator.ru && ru.descriptionDatas.places) ||
          (translator.en && en.descriptionDatas.places) ||
          (translator.kyr && kyr.descriptionDatas.places))}
      {variant === 'yes' &&
        ((translator.ru && ru.descriptionDatas.wifi.yes) ||
          (translator.en && en.descriptionDatas.wifi.yes) ||
          (translator.kyr && kyr.descriptionDatas.wifi.yes))}
      {variant === 'no' &&
        ((translator.ru && ru.descriptionDatas.wifi.no) ||
          (translator.en && en.descriptionDatas.wifi.no) ||
          (translator.kyr && kyr.descriptionDatas.wifi.no))}
      {variant === 'both' &&
        ((translator.ru && ru.descriptionDatas.wifi.both) ||
          (translator.en && en.descriptionDatas.wifi.both) ||
          (translator.kyr && kyr.descriptionDatas.wifi.both))}
      {variant === 'appeals' &&
        ((translator.ru && ru.descriptionDatas.appeals) ||
          (translator.en && en.descriptionDatas.appeals) ||
          (translator.kyr && kyr.descriptionDatas.appeals))}
      {variant === 'job-title' &&
        ((translator.ru && ru.descriptionDatas.job_title) ||
          (translator.en && en.descriptionDatas.job_title) ||
          (translator.kyr && kyr.descriptionDatas.job_title))}
      {variant === 'mail' &&
        ((translator.ru && ru.descriptionDatas.mail) ||
          (translator.en && en.descriptionDatas.mail) ||
          (translator.kyr && ru.descriptionDatas.mail))}
      {variant === 'number' &&
        ((translator.ru && ru.descriptionDatas.number) ||
          (translator.en && en.descriptionDatas.number) ||
          (translator.kyr && ru.descriptionDatas.number))}
      {variant === 'number-WhatsApp' &&
        ((translator.ru && ru.descriptionDatas.numberWhatsApp) ||
          (translator.en && en.descriptionDatas.numberWhatsApp) ||
          (translator.kyr && kyr.descriptionDatas.numberWhatsApp))}
      {variant === 'complaint' &&
        ((translator.ru && ru.descriptionDatas.complaint) ||
          (translator.en && en.descriptionDatas.complaint) ||
          (translator.kyr && kyr.descriptionDatas.complaint))}
      {variant === 'upload-video' &&
        ((translator.ru && ru.descriptionDatas.upload.video) ||
          (translator.en && en.descriptionDatas.upload.video) ||
          (translator.kyr && kyr.descriptionDatas.upload.video))}
      {variant === 'upload-photo' &&
        ((translator.ru && ru.descriptionDatas.upload.photo) ||
          (translator.en && en.descriptionDatas.upload.photo) ||
          (translator.kyr && kyr.descriptionDatas.upload.photo))}
      {variant === 'upload-button' &&
        ((translator.ru && ru.descriptionDatas.upload.button) ||
          (translator.en && en.descriptionDatas.upload.button) ||
          (translator.kyr && kyr.descriptionDatas.upload.button))}
      {variant === 'surname' &&
        ((translator.ru && ru.descriptionDatas.surname) ||
          (translator.en && en.descriptionDatas.surname) ||
          (translator.kyr && ru.descriptionDatas.surname))}
      {variant === 'names' &&
        ((translator.ru && ru.descriptionDatas.name) ||
          (translator.en && en.descriptionDatas.name) ||
          (translator.kyr && kyr.descriptionDatas.name))}
      {variant === 'question' &&
        ((translator.ru && ru.descriptionDatas.question) ||
          (translator.en && en.descriptionDatas.question) ||
          (translator.kyr && kyr.descriptionDatas.question))}
      {variant === 'answer' &&
        ((translator.ru && ru.descriptionDatas.answer) ||
          (translator.en && en.descriptionDatas.answer) ||
          (translator.kyr && kyr.descriptionDatas.answer))}
      {variant === 'citizenSend' &&
        ((translator.ru && ru.descriptionDatas.citizenSend) ||
          (translator.en && en.descriptionDatas.citizenSend) ||
          (translator.kyr && kyr.descriptionDatas.citizenSend))}
      {variant === 'citizenIgnor' &&
        ((translator.ru && ru.descriptionDatas.citizenIgnor) ||
          (translator.en && en.descriptionDatas.citizenIgnor) ||
          (translator.kyr && kyr.descriptionDatas.citizenIgnor))}
      {variant === 'archiveTitle' &&
        ((translator.ru && ru.descriptionDatas.archive.title) ||
          (translator.en && en.descriptionDatas.archive.title) ||
          (translator.kyr && kyr.descriptionDatas.archive.title))}
      {variant === 'archiveClear' &&
        ((translator.ru && ru.descriptionDatas.archive.clear) ||
          (translator.en && en.descriptionDatas.archive.clear) ||
          (translator.kyr && kyr.descriptionDatas.archive.clear))}
      {variant === 'userquestion' &&
        ((translator.ru && ru.descriptionDatas.userquestion) ||
          (translator.en && en.descriptionDatas.userquestion) ||
          (translator.kyr && kyr.descriptionDatas.userquestion))}
      {variant === 'delete' &&
        ((translator.ru && ru.modalContent.deleted) ||
          (translator.en && en.modalContent.deleted) ||
          (translator.kyr && kyr.modalContent.deleted))}
      {variant === 'footer-info' &&
        ((translator.ru && ru.footer.info) ||
          (translator.en && en.footer.info) ||
          (translator.kyr && kyr.footer.info))}
      {variant === 'footer-cityHall' &&
        ((translator.ru && ru.footer.cityHall) ||
          (translator.en && en.footer.cityHall) ||
          (translator.kyr && kyr.footer.cityHall))}
      {variant === 'footer-republic' &&
        ((translator.ru && ru.footer.republic) ||
          (translator.en && en.footer.republic) ||
          (translator.kyr && kyr.footer.republic))}
      {variant === 'footer-contacts' &&
        ((translator.ru && ru.footer.contacts) ||
          (translator.en && en.footer.contacts) ||
          (translator.kyr && kyr.footer.contacts))}
      {variant === 'footer-job' &&
        ((translator.ru && ru.footer.job) ||
          (translator.en && en.footer.job) ||
          (translator.kyr && kyr.footer.job))}
      {variant === 'footer-network' &&
        ((translator.ru && ru.footer.network) ||
          (translator.en && en.footer.network) ||
          (translator.kyr && kyr.footer.network))}
      {variant === 'footer-security' &&
        ((translator.ru && ru.footer.security) ||
          (translator.en && en.footer.security) ||
          (translator.kyr && kyr.footer.security))}
    </>
  )
}

export default TranslatorDescription
