import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { putAdminTouristsHotels } from '../../../../services/admin/AdminAction'
import Button from '../../../UI/Button'
import CardPhoto from '../../../UI/CardPhoto'
import Input from '../../../UI/Inputs'
import { GoogleMap } from '../../../UI/Map'
import NetWifi from '../../../UI/NetWifi'
import TranslatorDescription from '../../../UI/TranslatorContent/TranslatorDescription'

const AdminEditHotel = () => {
  const [change, setChange] = useState(false)
  const [position, setPosition] = useState(null)
  const [files, setFiles] = useState(null)
  const [format, setFormat] = useState(null)
  const changeHandler = () => {
    setChange((prev) => !prev)
  }
  const [inputs, setInputs] = useState({
    title: '',
    description: '',
    countPlace: 0,
    price: 0,
  })
  const titleInputHandler = (event) => {
    setInputs({
      title: event.target.value,
      description: inputs.description,
      countPlace: inputs.countPlace,
      price: inputs.price,
    })
  }
  const descriptionInputHandler = (event) => {
    setInputs({
      title: inputs.title,
      description: event.target.value,
      countPlace: inputs.countPlace,
      price: inputs.price,
    })
  }
  const countPlaceInputHandler = (event) => {
    setInputs({
      title: inputs.title,
      description: inputs.description,
      countPlace: event.target.value,
      price: inputs.price,
    })
  }
  const priceInputHandler = (event) => {
    setInputs({
      title: inputs.title,
      description: inputs.description,
      countPlace: inputs.countPlace,
      price: event.target.value,
    })
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hotelId } = useParams()
  const editTouristsHotel = () => {
    if (
      inputs.title.trim() !== '' &&
      inputs.description.trim() !== '' &&
      inputs.countPlace > 0 &&
      inputs.price > 0 &&
      format &&
      position
    ) {
      dispatch(
        putAdminTouristsHotels({
          id: hotelId,
          title: inputs.title,
          description: inputs.description,
          countPlaces: inputs.countPlace,
          price: inputs.price,
          img: format,
          wifi: change ? 'YES' : 'NO',
          location: position,
        })
      )
      navigate(-1)
    }
  }
  return (
    <Container>
      <ContMa>
        <StyledBackIcon
          src='https://icones.pro/wp-content/uploads/2022/05/icone-fermer-et-x-noir.png'
          alt='none'
          onClick={() => navigate(-1)}
        />

        <CardPhoto
          setFiles={setFiles}
          files={files}
          setFormat={setFormat}
          variant='logo'
        />
        <StyledMap>
          <Maap>
            <GoogleMap
              position={position}
              setPosition={setPosition}
              variant='mini'
            />
          </Maap>
        </StyledMap>
      </ContMa>
      <ContInput>
        <Description>
          <div>
            <Label>
              <TranslatorDescription variant='title' />
            </Label>
            <Input
              onChange={titleInputHandler}
              value={inputs.title}
              tipe='input'
              variant='nameAdmin'
            />
          </div>
          <Maap>
            <Label>
              <TranslatorDescription variant='description' />
            </Label>
            <Input
              onChange={descriptionInputHandler}
              value={inputs.description}
              tipe='textarea'
              variant='textAdmin'
            />
          </Maap>
          <StyledGoogle>
            <Maap>
              <GoogleMap variant='mini' />
            </Maap>
          </StyledGoogle>
        </Description>

        <StyleDiv>
          <Block>
            <NetWifi variant='Choice' />
          </Block>
          <None onClick={changeHandler}>
            {change ? (
              <TranslatorDescription variant='yes' />
            ) : (
              <TranslatorDescription variant='no' />
            )}
          </None>
        </StyleDiv>
        <StyleDiv>
          <Block>
            <NetWifi variant='EmptyBed' />
          </Block>
          <NoneInput
            onChange={countPlaceInputHandler}
            value={inputs.countPlace}
            type='number'
          />
        </StyleDiv>
        <StyleDiv>
          <Block>
            <StyleDollar>$</StyleDollar>
            <Price>
              <TranslatorDescription variant='price' />
            </Price>
          </Block>
          <NoneInput
            onChange={priceInputHandler}
            value={inputs.price}
            variant='price'
            type='number'
          />
        </StyleDiv>

        <ShowButton>
          <Button onClick={editTouristsHotel} variant='button1'>
            <TranslatorDescription variant='save' />
          </Button>
        </ShowButton>
      </ContInput>
    </Container>
  )
}

export default AdminEditHotel
const StyledBackIcon = styled.img`
  width: 20px;
  cursor: pointer;
  user-select: none;
  position: relative;
  right: 10px;
  @media (max-width: 450px) {
    right: 180px;
  }
  @media (max-width: 391px) {
    right: 170px;
  }
`
const Container = styled.div`
  display: flex;
  height: max-content;
  width: 800px;
  padding: 10px 0px 10px 15px;
  border-radius: 20px;
  background: #fff;
  margin-left: 13px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 20px;
  margin-top: 10px;
  @media (max-width: 1300px) {
    width: 850px;
  }
  @media (max-width: 1100px) {
    width: 600px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 821px) {
    width: 600px;
  }
  @media (max-width: 550px) {
    width: 95%;
  }
`
const Label = styled.label`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
`
const ContMa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 850px) {
    align-items: center;
  }
`
const ContInput = styled.div``
const None = styled.div`
  border: 1px solid;
  font-family: Inter, sans-serif;
  font-size: 25px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
  border-radius: 10px;
  background: #fff;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`
const NoneInput = styled.input`
  border: 1px solid;
  font-family: Inter, sans-serif;
  width: ${(props) => (props.variant === 'price' ? '100px' : '70px')};
  font-size: 25px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
  border-radius: 10px;
  background: #fff;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`
const StyleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`
const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 550px) {
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
`
const ShowButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 50px;
  @media (max-width: 1024px) {
    margin-top: 55px;
  }
`
const Maap = styled.div`
  @media (max-width: 376px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
const StyleDollar = styled.h1`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 33px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
`
const Price = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 33px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
const StyledMap = styled.div`
  @media (max-width: 550px) {
    display: none;
  }
`
const StyledGoogle = styled.div`
  display: none;
  @media (max-width: 550px) {
    display: flex;
  }
`

const Block = styled.div`
  width: 200px;
  display: flex;
  gap: 10px;
`
