import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { getUserTouristAttractons } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Card from '../UI/Card'
import TextCard from '../UI/TextCard'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserAttraction = () => {
  const state = useSelector((state) => state.user)
  const navigate = useNavigate()
  const [count, setCount] = useState(6)
  const dispatch = useDispatch()
  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getUserTouristAttractons({ count }))
  }, [count])
  const attractions = (el) => {
    navigate(`${el.id}`)
  }
  return (
    <StyledDiv>
      <StyledText>
        <TextCard variant='attractions' colorVariant='blue' />
      </StyledText>
      {state.attractions.length > 0 ? (
        <>
          {state.attractions?.map((element) => (
            <StyledDivCard key={element}>
              <Card
                variant='tourists-page'
                img={element.img}
                title={element.title}
                description={element.description}
                date={element.date}
                senderText={element.senderText}
                sender={element.sender}
                onAttractions={() => attractions(element)}
              />
            </StyledDivCard>
          ))}
          {state.attractions.length >= 6 && (
            <Btn>
              <Button variant='button1' onClick={countHandler}>
                <TranslatorDescription variant='more' />
              </Button>
            </Btn>
          )}
        </>
      ) : (
        <StyledDivText>
          <StyledText2>
            <DataNotFoundTranslator variant='' />
          </StyledText2>
        </StyledDivText>
      )}
    </StyledDiv>
  )
}
export default UserAttraction

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1250px) {
    width: 100%;
  }
  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 912px) {
    width: 100%;
  }
  @media (max-width: 821px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 415px) {
    width: 100%;
    display: flex;
  }
`
const StyledText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 913px) and (max-width: 1025px) {
    margin-top: 30px;
  }
  @media (min-width: 400px) and (max-width: 912px) {
    margin-top: 40px;
  }
  @media (max-width: 399px) {
    margin-top: 30px;
  }
  @media (min-width: 1025px) and (max-width: 1299px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`
const StyledDivCard = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-left: 90px;
  @media (max-width: 541px) {
    margin: 0;
    margin-top: 20px;
  }
  @media (max-width: 415px) {
    margin: 0;
    margin-top: 20px;
    margin-left: 10px;
  }
  @media (max-width: 413px) {
    margin: 0;
    margin-top: 20px;
  }
  @media (max-width: 394px) {
    margin: 0;
    margin-top: 20px;
  }
  @media (max-width: 376px) {
    margin: 0;
    margin-left: -3px;
    margin-top: 20px;
  }
  @media (max-width: 361px) {
    margin: 0;
    margin-top: 20px;
    margin-left: 5px;
  }
`
const Btn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledText2 = styled.h4`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 500;
`
