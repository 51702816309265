/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminTouristsCafes,
  getAdminTouristsCafes,
} from '../../../../services/admin/AdminAction'
import { adminAction } from '../../../../services/admin/AdminSlice'
import { english } from '../../../../utils/constants/lang/english'
import { kyrgyz } from '../../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../../utils/constants/lang/russian'
import Button from '../../../UI/Button'
import Card from '../../../UI/Card'
import ModalDeleteContent from '../../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../../UI/SnackBar'
import TextCard from '../../../UI/TextCard'
import DataNotFoundTranslator from '../../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../../UI/TranslatorContent/TranslatorDescription'

const AdminCafe = () => {
  const ru = russian.snackBar
  const en = english.snackBar
  const kyr = kyrgyz.snackBar

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [count, setCount] = useState(6)
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const countHandler = () => {
    setCount((prev) => prev + 6)
  }
  const state = useSelector((state) => state.admin)

  const {
    CafesSnackState,
    CafesAddStatus,
    CafesEditStatus,
    CafesDeleteStatus,
  } = useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  useEffect(() => {
    dispatch(getAdminTouristsCafes({ count }))
  }, [])
  const deleteCafe = () => {
    dispatch(deleteAdminTouristsCafes({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.CafesAddSnackBar({
        CafesSnackState: false,
        CafesAddStatus: null,
      })
    )
    dispatch(
      adminAction.CafesEditSnackBar({
        CafesSnackState: false,
        CafesEditStatus: null,
      })
    )
    dispatch(
      adminAction.CafesDeleteSnackBar({
        CafesSnackState: false,
        CafesDeleteStatus: null,
      })
    )
  }

  const navToAddCagePage = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  const navToEditPage = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  const navToCurrentCafe = (el) => {
    navigate(`${el.title}/${el.id}`)
    closeSnackBarHandler()
  }
  return (
    <StyledDiv>
      {ModalState && (
        <ModalDeleteContent
          variant=''
          Cancellation={Cancellation}
          deletedFunction={deleteCafe}
        />
      )}
      {CafesSnackState && (
        <SnackBar
          message={
            CafesAddStatus === 'success'
              ? (translator.ru && ru.messageSaccess) ||
                (translator.en && en.messageSaccess) ||
                (translator.kyr && kyr.messageSaccess)
              : CafesEditStatus === 'success'
              ? (translator.ru && ru.messageEditSaccess) ||
                (translator.en && en.messageEditSaccess) ||
                (translator.kyr && kyr.messageEditSaccess)
              : CafesDeleteStatus === 'success'
              ? (translator.ru && ru.messageDeleteSaccess) ||
                (translator.en && en.messageDeleteSaccess) ||
                (translator.kyr && kyr.messageDeleteSaccess)
              : (translator.ru && ru.messageError) ||
                (translator.en && en.messageError) ||
                (translator.kyr && en.messageError)
          }
          variant={CafesAddStatus || CafesEditStatus || CafesDeleteStatus}
          open={CafesSnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      <StyledText>
        <TextCard variant='cafes' colorVariant='blue' />
      </StyledText>
      <div>
        {state.touristsCafes?.map((element) => (
          <StyledDivCard key={element}>
            <Card
              variant='tourists-page-admin'
              img={element.image}
              title={element.title}
              description={element.description}
              onEdit={() => navToEditPage(element.id)}
              onDelete={() => deletedHandler(element.id)}
              onAttractions={() => navToCurrentCafe(element)}
            />
          </StyledDivCard>
        ))}
        {state.touristsCafes?.length <= 0 && (
          <StyledDivText>
            <DataNotFoundTranslator variant='' />
          </StyledDivText>
        )}
        <StyledDivButton>
          <Button onClick={navToAddCagePage} variant='button1'>
            <TranslatorDescription variant='add' />
          </Button>
          {state.touristsCafes?.length >= 6 && (
            <Button onClick={countHandler} variant='button1'>
              <TranslatorDescription variant='more' />
            </Button>
          )}
        </StyledDivButton>
      </div>
    </StyledDiv>
  )
}
export default AdminCafe

const StyledDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
const StyledText = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  @media (max-width: 1025px) {
    margin: 0;
    margin-top: 30px;
    margin-left: 100px;
  }
  @media (max-width: 540px) {
    margin: 0;
    margin-top: 30px;
  }
  @media (max-width: 391px) {
    margin: 0;
    margin-top: 30px;
    margin-left: -3px;
  }
`
const StyledDivCard = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`
const StyledDivButton = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 50px;
  margin-top: 30px;
  gap: 20px;
  @media (max-width: 415px) {
    margin-right: 7px;
  }
  @media (max-width: 394px) {
    margin-right: 15px;
  }
  @media (max-width: 376px) {
    margin-right: 7px;
  }
  @media (max-width: 361px) {
    margin-right: 10px;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
