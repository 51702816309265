import { createSlice } from '@reduxjs/toolkit'
import {
  getUserTouristAttractonsById,
  getUserTouristCurrentCafe,
  getUserTurists,
  getUserTouristAttractons,
  getUserCalendarEvent,
  getUserCityHall,
  getUserContacts,
  getUserCalendar,
  getUserHotels,
  getUserFaqs,
  getUserMainPage,
  getUserMunicipal,
  getUserNews,
  getUserTouristCafes,
  getUserCurrentHotels,
  postUserAppealOfCitizen,
} from './UserAction'

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    status: null,
    // mainPage
    mainPageStatus: null,
    mainPage: [],
    // news
    newsStatus: null,
    news: [],
    // faqs
    faqsStatus: null,
    faqs: [],
    // cityHall
    cityHall: [],
    AppealSnackState: false,
    AppealUploadStatus: null,
    // tourists
    touristsStatus: null,
    touristCafes: [],
    vertical: [],
    horizontal: [],
    // calendars
    calendarStatus: null,
    calendar: [],
    calendarEvent: [],
    // hotels
    hotelStatus: null,
    hotels: [],
    currenthotels: {},
    // atractions
    attractionsStatus: null,
    attractions: [],
    currentAttractions: [],
    // current Cafes
    currentCafeStatus: null,
    currentCafe: {},
    // contacts
    contactsStatus: null,
    contacts: [],
    // municipal
    municipalStatus: null,
    municipal: [],
  },
  reducers: {
    // appealsCitizen
    AppealUploadSnackBar(state, action) {
      state.AppealUploadStatus = action.payload.AppealUploadStatus
      state.AppealSnackState = action.payload.AppealSnackState
    },
    // appealsCitizen
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserNews.pending, (state) => {
        state.newsStatus = 'pending'
      })
      .addCase(getUserNews.fulfilled, (state, action) => {
        state.newsStatus = 'success'
        state.news = action.payload?.userNews
      })
      .addCase(getUserNews.rejected, (state) => {
        state.newsStatus = 'error'
      })
      // get user hotels
      .addCase(getUserHotels.pending, (state) => {
        state.hotelStatus = 'pending'
      })
      .addCase(getUserHotels.fulfilled, (state, action) => {
        state.hotelStatus = 'success'
        state.hotels = action.payload?.hotels
      })
      .addCase(getUserHotels.rejected, (state) => {
        state.hotelStatus = 'error'
      })
      // get user current hotels
      .addCase(getUserCurrentHotels.pending, (state) => {
        state.currentAttractions = 'pending'
      })
      .addCase(getUserCurrentHotels.fulfilled, (state, action) => {
        state.currentAttractions = 'success'
        state.currenthotels = action.payload?.userCurrentHotel
      })
      .addCase(getUserCurrentHotels.rejected, (state) => {
        state.currentAttractions = 'error'
      })
      // user calendar
      .addCase(getUserCalendar.pending, (state) => {
        state.calendarStatus = 'pending'
      })
      .addCase(getUserCalendar.fulfilled, (state, action) => {
        state.calendarStatus = 'success'
        state.calendar = action.payload?.calendar
      })
      .addCase(getUserCalendar.rejected, (state) => {
        state.calendarStatus = 'error'
      })
      // calendar
      .addCase(getUserCalendarEvent.pending, (state) => {
        state.calendarStatus = 'pending'
      })
      .addCase(getUserCalendarEvent.fulfilled, (state, action) => {
        state.calendarStatus = 'success'
        state.calendarEvent = action.payload?.calendarEvent
      })
      .addCase(getUserCalendarEvent.rejected, (state) => {
        state.calendarStatus = 'error'
      })
      // attraction
      .addCase(getUserTouristAttractons.pending, (state) => {
        state.attractionsStatus = 'pending'
      })
      .addCase(getUserTouristAttractons.fulfilled, (state, action) => {
        state.attractionsStatus = 'success'
        state.attractions = action.payload?.attractions
      })
      .addCase(getUserTouristAttractons.rejected, (state) => {
        state.attractionsStatus = 'error'
      })
      // currentAttraction
      .addCase(getUserTouristAttractonsById.pending, (state) => {
        state.attractionsStatus = 'pending'
      })
      .addCase(getUserTouristAttractonsById.fulfilled, (state, action) => {
        state.attractionsStatus = 'success'
        state.currentAttractions = action.payload?.currentAttractions
      })
      .addCase(getUserTouristAttractonsById.rejected, (state) => {
        state.attractionsStatus = 'error'
      })
      // tourists
      .addCase(getUserTouristCafes.pending, (state) => {
        state.touristsStatus = 'pending'
      })
      .addCase(getUserTouristCafes.fulfilled, (state, action) => {
        state.touristsStatus = 'success'
        state.touristCafes = action.payload?.cafes
      })
      .addCase(getUserTouristCafes.rejected, (state) => {
        state.touristsStatus = 'error'
      })
      // currentCafes
      .addCase(getUserTouristCurrentCafe.pending, (state) => {
        state.currentCafeStatus = 'pending'
      })
      .addCase(getUserTouristCurrentCafe.fulfilled, (state, action) => {
        state.currentCafeStatus = 'success'
        state.currentCafe = action.payload?.currentCafes
      })
      .addCase(getUserTouristCurrentCafe.rejected, (state) => {
        state.currentCafeStatus = 'error'
      })
      // user contacts
      .addCase(getUserContacts.pending, (state) => {
        state.contactsStatus = 'pending'
      })
      .addCase(getUserContacts.fulfilled, (state, action) => {
        state.status = 'success'
        state.contacts = action.payload?.contactsData
      })
      .addCase(getUserContacts.rejected, (state) => {
        state.contactsStatus = 'error'
      })
      // user faqs
      .addCase(getUserFaqs.pending, (state) => {
        state.faqsStatus = 'pending'
      })
      .addCase(getUserFaqs.fulfilled, (state, action) => {
        state.faqsStatus = 'success'
        state.faqs = action.payload?.faqsData
      })
      .addCase(getUserFaqs.rejected, (state) => {
        state.faqsStatus = 'error'
      })
      // municipal
      .addCase(getUserMunicipal.pending, (state) => {
        state.municipalStatus = 'pending'
      })
      // user  turists
      .addCase(getUserTurists.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getUserTurists.fulfilled, (state, action) => {
        state.status = 'success'
        state.vertical = action.payload?.vertical
        state.horizontal = action.payload?.horizontal
      })
      .addCase(getUserTurists.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(getUserMunicipal.fulfilled, (state, action) => {
        state.municipalStatus = 'success'
        state.municipal = action.payload?.userMunicipal
      })
      .addCase(getUserMunicipal.rejected, (state) => {
        state.municipalStatus = 'error'
      })
      // mainPage
      .addCase(getUserMainPage.pending, (state) => {
        state.mainPageStatus = 'pending'
      })
      .addCase(getUserMainPage.fulfilled, (state, action) => {
        state.mainPageStatus = 'success'
        state.mainPage = action.payload?.mainPageData
      })
      .addCase(getUserMainPage.rejected, (state) => {
        state.mainPageStatus = 'error'
      })
      // City Hall
      .addCase(getUserCityHall.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getUserCityHall.fulfilled, (state, action) => {
        state.status = 'success'
        state.cityHall = action.payload?.city
      })
      .addCase(getUserCityHall.rejected, (state) => {
        state.status = 'error'
      })
      // appealsCitizen
      .addCase(postUserAppealOfCitizen.pending, (state) => {
        state.AppealUploadStatus = 'pending'
      })
      .addCase(postUserAppealOfCitizen.fulfilled, (state) => {
        state.AppealUploadStatus = 'success'
        state.AppealSnackState = true
      })
      .addCase(postUserAppealOfCitizen.rejected, (state) => {
        state.AppealUploadStatus = 'error'
        state.AppealSnackState = true
      })
  },
})
export const userAction = userSlice.actions
