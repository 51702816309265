import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from 'styled-components'
import LoginPhoto from '../../assets/LoginPhoto/Jalal-Abad 1.svg'
import { postLogin } from '../../services/auth/AuthSlice'
import Button from '../UI/Button'
import Input from '../UI/Inputs'

export const Authorization = () => {
  const dispatch = useDispatch()
  const [type, setType] = useState(false)
  const typeHandler = () => {
    setType((prev) => !prev)
  }
  const [user, setUser] = useState({
    email: '',
    password: '',
  })
  const emailHandler = (event) => {
    setUser({
      email: event.target.value,
      password: user.password,
    })
  }
  const passwordHandler = (event) => {
    setUser({
      email: user.email,
      password: event.target.value,
    })
  }
  const Login = (e) => {
    e.preventDefault()
    if (user.email.trim() !== '' && user.password.trim() !== '') {
      const body = {
        email: user.email,
        password: user.password,
      }
      dispatch(postLogin({ body }))
    }
  }

  return (
    <ContainerMain>
      <Bg src={LoginPhoto} alt='none' />
      <Container>
        <form onSubmit={Login}>
          <LoginTitle>LOGIN</LoginTitle>
          <ConInput>
            <LabelCont>Username</LabelCont>
            <Input
              tipe='input'
              iconAdd='usernameIcon'
              variant='username'
              placeholder='username'
              onChange={emailHandler}
              type='email'
            />
          </ConInput>
          <LabelCont>Password</LabelCont>
          <ConInputt>
            <Input
              tipe='input'
              iconPassword='passwordIcon'
              variant='password'
              placeholder='Password'
              type={type ? 'text' : 'password'}
              maxLength={18}
              onChange={passwordHandler}
              autoComplete='password'
            />
            {type ? (
              <ShowIcon
                onClick={typeHandler}
                src='https://icons.veryicon.com/png/o/miscellaneous/hekr/action-hide-password.png'
                alt='none'
              />
            ) : (
              <ShowIcon
                onClick={typeHandler}
                src='https://static.thenounproject.com/png/777494-200.png'
                alt='none'
              />
            )}
          </ConInputt>
          <ButtonCon>
            <Button variant='login-button'>Login</Button>
          </ButtonCon>
        </form>
      </Container>
    </ContainerMain>
  )
}

const ContainerMain = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`
const Bg = styled.img`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  @media (max-width: 1300px) {
    width: max-content;
  }
`

const Container = styled.div`
  width: 480px;
  height: 450px;
  display: flex;
  justify-content: center;
  z-index: 10;
  background: #f1f0f0dd;
  border-radius: 10px;
  @media (max-width: 414px) {
    width: 355px;
    height: 415px;
  }
`

const LoginTitle = styled.h2`
  margin-bottom: 35px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-weight: 800;
  @media (max-width: 414px) {
    font-family: Poppins, sans-serif;
  }
`
const ButtonCon = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins, sans-serif;
`
const ConInput = styled.div`
  margin-bottom: 45px;
`
const ConInputt = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`

const LabelCont = styled.label`
  display: none;
  @media (max-width: 414px) {
    font-family: Poppins, sans-serif;
    display: flex;
    font-weight: 1000;
  }
`
const ShowIcon = styled.img`
  width: 30px;
  position: absolute;
  margin-top: 12px;
  margin-right: 5px;
  cursor: pointer;
`
