import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ShowIcon from '../../../assets/quickIcons/Frame 131.svg'
import {
  deleteAdminAppealsArchive,
  getAdminAppealsArchive,
} from '../../../services/admin/AdminAction'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AppealsFormCitizensArchive = () => {
  const [show, setShow] = useState(null)
  const showHandler = (i) => {
    setShow(i)
    if (show === i) {
      setShow(null)
    }
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector((state) => state.admin)
  useEffect(() => {
    dispatch(getAdminAppealsArchive())
  }, [])
  const clearArchive = () => {
    dispatch(deleteAdminAppealsArchive())
    navigate('/appeals-of-citizens')
  }
  return state.appealsArchive?.length >= 1 ? (
    <Container>
      <StyledTitle>
        <TranslatorDescription variant='archiveTitle' />
      </StyledTitle>
      <StyledBg>
        {state.appealsArchive?.map((el, i) => (
          <StyledArchiveBlock>
            <QuestionBlock>
              <StyledQuestion>{`${i + 1}.${el.name}`}</StyledQuestion>
              {show === i && <StyledAnswer>{el.description}</StyledAnswer>}
            </QuestionBlock>
            <StyledShowIcon
              onClick={() => showHandler(i)}
              src={ShowIcon}
              alt='none'
              isShow={show === i}
            />
          </StyledArchiveBlock>
        ))}
        <ClearButton>
          <StyledButton onClick={clearArchive}>
            <TranslatorDescription variant='archiveClear' />
          </StyledButton>
        </ClearButton>
      </StyledBg>
    </Container>
  ) : (
    <StyledDivText>
      <DataNotFoundTranslator />
    </StyledDivText>
  )
}

export default AppealsFormCitizensArchive

const Container = styled.div`
  margin: 50px 100px 100px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 450px) {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
`
const StyledBg = styled.div`
  width: 1100px;
  height: max-content;
  background: white;
  background: #fff;
  border-radius: 20px;
  border: 0.5px solid #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 20px 20px 20px;
  @media (max-width: 1300px) {
    width: 800px;
  }
  @media (max-width: 1100px) {
    width: 600px;
  }
  @media (max-width: 913px) {
    width: 800px;
  }
  @media (max-width: 850px) {
    width: 750px;
  }
  @media (max-width: 600px) {
    width: 450px;
  }
  @media (max-width: 450px) {
    width: 400px;
  }
  @media (max-width: 391px) {
    width: 350px;
  }
`
const StyledArchiveBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledTitle = styled.h1`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 50px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  user-select: none;
  @media (max-width: 450px) {
    font-size: 30px;
  }
`
const QuestionBlock = styled.div`
  margin-bottom: 10px;
`
const StyledQuestion = styled.p`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
const StyledAnswer = styled.p`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-top: 10px;
`
const StyledShowIcon = styled.img`
  width: 20px;
  cursor: pointer;
  transition: 0.3s;
  transform: ${(props) => props.isShow && 'rotate(180deg)'};
  user-select: none;
  margin-bottom: 10px;
`
const ClearButton = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`
const StyledButton = styled.button`
  padding: 10px 25px 10px 25px;
  border: none;
  border-radius: 6px;
  background: #f00;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
