import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from 'styled-components'
import { getUserMainPage } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Card from '../UI/Card'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

const UserMainPage = () => {
  const state = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [count, setCount] = useState(6)

  const countHandler = () => {
    setCount((prevState) => prevState + 6)
  }
  useEffect(() => {
    dispatch(getUserMainPage({ count }))
  }, [count])
  return (
    <CardDiv states={state.mainPage}>
      {state.mainPage.length > 0 ? (
        <DivCard>
          {state.mainPage?.map((element) => (
            <div key={element.id}>
              <Card
                variant='main-page'
                key={element.id}
                img={element.image}
                title={element.title}
                description={element.description}
                date={element.date}
                senderText={element.senderText}
                sender={element.sender}
              />
            </div>
          ))}
          {state.mainPage.length >= 6 && (
            <DIVbtn>
              <Btn>
                <Button variant='button1' onClick={countHandler}>
                  <TranslatorDescription variant='more' />
                </Button>
              </Btn>
            </DIVbtn>
          )}
        </DivCard>
      ) : (
        <StyledDivText>
          <StyledText>
            <DataNotFoundTranslator variant='' />
          </StyledText>
        </StyledDivText>
      )}
    </CardDiv>
  )
}

export default UserMainPage

const CardDiv = styled.div`
  width: 100%;
  padding: 20px 0px 0px 40px;
  @media (max-width: 450px) {
    padding-left: 0;
  }
`
const DivCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  @media (max-width: 1025px) {
    gap: 20px;
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`
const DIVbtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Btn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledText = styled.h4`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 500;
`
