import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getUserHotels } from '../../services/User/UserAction'
import Button from '../UI/Button'
import Card from '../UI/Card'
import TextCard from '../UI/TextCard'
import DataNotFoundTranslator from '../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../UI/TranslatorContent/TranslatorDescription'

export const UserHotelPage = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.user)
  const navigate = useNavigate()

  const [visibleCardCount, setVisibleCardCount] = useState(6)
  useEffect(() => {
    dispatch(getUserHotels({ count: visibleCardCount }))
  }, [visibleCardCount])

  const loadMoreCards = () => {
    setVisibleCardCount(visibleCardCount + 6)
  }
  const navToCurrentHotelPage = (el) => {
    navigate(`${el.id}`)
  }
  return (
    <Container>
      <DivTextCard>
        <TextCard variant='hotels' colorVariant='blue' />
      </DivTextCard>
      {state.hotels.length > 0 ? (
        <DivContainerCard>
          {state.hotels?.map((element) => (
            <StyledDivCard key={element.id}>
              <Card
                variant='tourists-page'
                img={element.img}
                title={element.title}
                description={element.description}
                date={element.date}
                senderText={element.senderText}
                sender={element.sender}
                onAttractions={() => navToCurrentHotelPage(element)}
              />
            </StyledDivCard>
          ))}
          {state.hotels.length >= 6 && (
            <LeftButtonContainer>
              <Button variant='button1' onClick={loadMoreCards}>
                <TranslatorDescription variant='more' />
              </Button>
            </LeftButtonContainer>
          )}
        </DivContainerCard>
      ) : (
        <StyledDivText>
          <DataNotFoundTranslator variant='' />
        </StyledDivText>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1250px) {
    width: 100%;
  }
  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 912px) {
    width: 100%;
  }
  @media (max-width: 821px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 415px) {
    width: 100%;
    display: flex;
  }
`
const DivTextCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 913px) and (max-width: 1025px) {
    margin-top: 30px;
  }
  @media (min-width: 400px) and (max-width: 912px) {
    margin-top: 40px;
  }
  @media (max-width: 399px) {
    margin-top: 30px;
  }
  @media (min-width: 1025px) and (max-width: 1299px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

const DivContainerCard = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 1145px) and (max-width: 1400px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 1025px) and (max-width: 1144px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 600px) and (max-width: 913px) {
    display: flex;
    flex-direction: row;
  }
`

const StyledDivCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  @media (min-width: 1145px) and (max-width: 1300px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.7);
    margin-top: -45px;
  }
  @media (min-width: 1025px) and (max-width: 1144px) {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.7);
    margin-top: -45px;
  }
  @media (min-width: 600px) and (max-width: 913px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.9);
  }
`
const LeftButtonContainer = styled.div`
  width: 89%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-bottom: 20px;
  /* margin-top: 20px; */
`
const StyledDivText = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
