/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  getAdminCurrentAppeal,
  postResponseAdminAppeals,
  postResponseAndAddToFaqAdminAppeals,
  putIgnoreAdminAppeals,
} from '../../../services/admin/AdminAction'
import Button from '../../UI/Button'
import Input from '../../UI/Inputs'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminAppealOfCitizenAdd = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.admin)
  useEffect(() => {
    dispatch(getAdminCurrentAppeal({ id }))
  }, [])
  const [answer, setAnswer] = useState('')
  const answerInputHandler = (event) => {
    setAnswer(event.target.value)
  }
  const navigate = useNavigate()
  const responseToAppeal = () => {
    if (answer !== '') {
      dispatch(postResponseAdminAppeals({ id, answer }))
      if (state.appealsStatus) {
        navigate('/appeals-of-citizens')
      }
    }
  }
  const responseToAppealAndAddToFaq = () => {
    if (answer !== '') {
      dispatch(postResponseAndAddToFaqAdminAppeals({ id, answer }))
    }
    if (state.appealsSendFAQStatus) {
      navigate('/appeals-of-citizens')
    }
  }
  const ignoreAppeal = () => {
    dispatch(putIgnoreAdminAppeals({ id }))
    if (state.appealsStatus) {
      navigate('/appeals-of-citizens')
    }
  }
  const data = state.currentAppeal
  const [index, setIndex] = useState(1)
  const forward = () => {
    setIndex((prev) => prev + 1)
  }
  const back = () => {
    setIndex((prev) => prev - 1)
  }
  return (
    <StyledDiv>
      {data?.media?.map(
        (el, i) =>
          i === index - 1 && (
            <ImgBlock>
              <StyledCounter>{`${index} / ${data?.media?.length}`}</StyledCounter>
              {index > 1 && (
                <BackIconBlock>
                  <BackIcon
                    src='https://cdn-icons-png.flaticon.com/512/32/32213.png'
                    alt='none'
                    className='backIcon'
                    onClick={back}
                  />
                </BackIconBlock>
              )}
              {el.img && <StyledImg alt='none' src={el.img} />}
              {el.video && <StyledVideo controls src={el.video} />}
              {index < data?.media?.length && (
                <ForwardIconBlock>
                  <ForwardIcon
                    src='https://cdn-icons-png.flaticon.com/512/32/32213.png'
                    alt='none'
                    className='forwardIcon'
                    onClick={forward}
                  />
                </ForwardIconBlock>
              )}
            </ImgBlock>
          )
      )}
      <StyledDivInput>
        <div>
          <StyledInput>
            <P>
              <TranslatorDescription variant='number' /> {}
            </P>
            <a
              target='_blank'
              href={`https://wa.me/${data?.info?.email}`}
              rel='noreferrer'
            >
              <StyledQuestion>{data?.info?.email}</StyledQuestion>
            </a>
          </StyledInput>
          <StyledInput>
            <P>
              <TranslatorDescription variant='question' />
            </P>
            <StyledQuestion>{data?.info?.description}</StyledQuestion>
          </StyledInput>
          <StyledInput2>
            <P>
              <TranslatorDescription variant='answer' />
            </P>
            <Input
              onChange={answerInputHandler}
              tipe='textarea'
              variant='youranswer'
            />
          </StyledInput2>
        </div>
      </StyledDivInput>
      <StyledDivButton>
        <Button variant='add-faq' onClick={responseToAppealAndAddToFaq}>
          <TranslatorDescription variant='citizenSend' />
        </Button>
        <StyledButton>
          <Button variant='button1' onClick={ignoreAppeal}>
            <TranslatorDescription variant='citizenIgnor' />
          </Button>
        </StyledButton>
        <StyledButton2>
          <Button variant='button1' onClick={responseToAppeal}>
            <TranslatorDescription variant='upload-button' />
          </Button>
        </StyledButton2>
      </StyledDivButton>
    </StyledDiv>
  )
}
export default AdminAppealOfCitizenAdd

const StyledDiv = styled.div`
  width: 850px;
  height: max-content;
  border-radius: 20px;
  margin: 10px 0px 20px 100px;
  border: 1px solid#0A0082;
  background: #fff;
  padding: 20px 0px 20px 0px;
  @media (max-width: 1281px) {
    width: 800px;
    margin: 0;
    margin-top: 20px;
    margin-left: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 1025px) {
    width: 600px;
    margin: 0;
    margin-top: 20px;
    margin-left: 30px;
  }
  @media (max-width: 913px) {
    width: 860px;
    margin: 0;
    margin-top: 30px;
    margin-left: 30px;
  }
  @media (max-width: 821px) {
    width: 800px;
    margin: 0;
    margin-top: 20px;
    margin-left: 10px;
  }
  @media (max-width: 769px) {
    width: 750px;
  }
  @media (max-width: 541px) {
    width: 520px;
    margin: 0;
    margin-top: 20px;
    margin-left: 10px;
  }
  @media (max-width: 415px) {
    width: 400px;
    margin: 0;
    margin-top: 20px;
    margin-left: 6px;
  }
  @media (max-width: 394px) {
    width: 385px;
  }
  @media (max-width: 391px) {
    width: 370px;
    margin-left: 10px;
  }
  @media (max-width: 376px) {
    width: 350px;
    margin-left: 15px;
  }
  @media (max-width: 360px) {
    width: 345px;
    margin: 0;
    margin-top: 20px;
    margin-left: 7px;
  }
`
const StyledDivInput = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 415px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 541px) {
    margin: 0;
  }
`
const StyledInput = styled.div`
  margin-top: 20px;
  @media (max-width: 415px) {
    margin: 0;
    margin-left: 10px;
    margin-top: 8px;
  }
`
const StyledInput2 = styled.div`
  margin-top: 10px;
  @media (max-width: 415px) {
    margin: 0;
    margin-top: 10px;
    margin-left: 10px;
  }
`
const StyledQuestion = styled.p`
  margin-top: 5px;
  font-size: 17px;
  border-radius: 10px;
  border: 0.5px solid #000;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: max-content;
  font-family: Inter, sans-serif;
  padding: 5px;
  user-select: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  word-break: break-all;
  max-width: 700px;
`
const ImgBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`
const StyledImg = styled.img`
  width: 700px;
  height: 400px;
  border-radius: 20px;
  border: 0.5px solid #000;
  pointer-events: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 1300px) {
    width: 95%;
  }
`
const StyledVideo = styled.video`
  width: 700px;
  height: 400px;
  border-radius: 20px;
  border: 0.5px solid #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
  @media (max-width: 1300px) {
    width: 95%;
  }
`
const StyledDivButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  @media (max-width: 415px) {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
  }
  @media (max-width: 376px) {
    margin: 0;
    margin-left: 70px;
    margin-top: 30px;
  }
  @media (max-width: 361px) {
    margin: 0;
    margin-top: 20px;
    margin-left: 80px;
  }
`
const StyledButton = styled.div`
  @media (max-width: 415px) {
    margin-top: 10px;
    margin-left: 30px;
  }
`
const StyledButton2 = styled.div`
  margin-right: 30px;
  @media (max-width: 415px) {
    margin-top: 10px;
    margin-left: 30px;
  }
`
const P = styled.p`
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
`
const StyledCounter = styled.p`
  font-size: 25px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  font-style: italic;
  position: absolute;
  margin-bottom: 350px;
  color: white;
  mix-blend-mode: difference;
  font-weight: 600;
  margin-left: 600px;
  z-index: 1;
  @media (max-width: 1025px) {
    margin-left: 500px;
  }
  @media (max-width: 913px) {
    margin-left: 700px;
  }
  @media (max-width: 821px) {
    margin-left: 680px;
  }
  @media (max-width: 769px) {
    margin-left: 630px;
  }
  @media (max-width: 600px) {
    margin-left: 400px;
  }
  @media (max-width: 415px) {
    margin-left: 290px;
  }
  @media (max-width: 376px) {
    margin-left: 260px;
  }
`
const BackIconBlock = styled.div`
  height: 350px;
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    .backIcon {
      opacity: 1;
    }
  }
`
const ForwardIconBlock = styled.div`
  height: 350px;
  position: relative;
  right: 50px;
  display: flex;
  align-items: center;
  &:hover {
    .forwardIcon {
      opacity: 1;
    }
  }
`
const BackIcon = styled.img`
  width: 50px;
  height: 50px;
  transform: rotate(180deg);
  position: absolute;
  transition: 0.5s;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  @media (max-width: 1300px) {
    opacity: 1;
  }
`
const ForwardIcon = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  transition: 0.5s;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  @media (max-width: 1300px) {
    opacity: 1;
  }
`
