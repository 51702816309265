export const info = {
  contacts: {
    numbers: ['+996 705 705 705', '+996 705 705 705', '+996 705 705 705'],
  },
  socialNetworks: {
    whatsapp: 'WhatsApp',
    telegram: 'Telegram',
    email: 'email@gmail.com',
  },
}
