/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  deleteAdminMain,
  getAdminMain,
} from '../../../services/admin/AdminAction'
import { adminAction } from '../../../services/admin/AdminSlice'
import { english } from '../../../utils/constants/lang/english'
import { kyrgyz } from '../../../utils/constants/lang/kyrgyz'
import { russian } from '../../../utils/constants/lang/russian'
import Button from '../../UI/Button'
import Card from '../../UI/Card'
import ModalDeleteContent from '../../UI/ModalContent/ModalDeleteContent'
import { SnackBar } from '../../UI/SnackBar'
import DataNotFoundTranslator from '../../UI/TranslatorContent/DataNotFoundTranslator'
import TranslatorDescription from '../../UI/TranslatorContent/TranslatorDescription'

const AdminMainPage = () => {
  const ru = russian
  const en = english
  const kyr = kyrgyz

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector((state) => state.admin)

  const { SnackState, mainAddStatus, mainEditStatus, MainDeleteStatus } =
    useSelector((state) => state.admin)

  const translator = useSelector((state) => state.translator)

  const [count, setCount] = useState(6)
  const [ModalState, setModalState] = useState(false)
  const [id, setId] = useState(null)
  const addCount = () => {
    setCount((prev) => prev + 6)
  }
  useEffect(() => {
    dispatch(getAdminMain({ count }))
  }, [count])
  const deleteAdminMainHandler = () => {
    dispatch(deleteAdminMain({ id }))
    setModalState(false)
  }
  const deletedHandler = (id) => {
    setModalState(true)
    setId(id)
  }
  const Cancellation = () => {
    setModalState(false)
  }
  const closeSnackBarHandler = () => {
    dispatch(
      adminAction.mainPageAddSnackBar({
        SnackState: false,
        mainAddStatus: null,
      })
    )
    dispatch(
      adminAction.mainPageEditSnackBar({
        SnackState: false,
        mainEditStatus: null,
      })
    )
    dispatch(
      adminAction.mainPageDeleteSnackBar({
        SnackState: false,
        MainDeleteStatus: null,
      })
    )
  }

  const navToEditPage = (id) => {
    navigate(`edit/${id}`)
    closeSnackBarHandler()
  }
  const navToAddPage = () => {
    navigate('add')
    closeSnackBarHandler()
  }
  return (
    <CardDiv>
      {ModalState && (
        <ModalDeleteContent
          variant=''
          Cancellation={Cancellation}
          deletedFunction={deleteAdminMainHandler}
        />
      )}
      {SnackState && (
        <SnackBar
          message={
            mainAddStatus === 'success'
              ? (translator.ru && ru.snackBar.messageSaccess) ||
                (translator.en && en.snackBar.messageSaccess) ||
                (translator.kyr && kyr.snackBar.messageSaccess)
              : mainEditStatus === 'success'
              ? (translator.ru && ru.snackBar.messageEditSaccess) ||
                (translator.en && en.snackBar.messageEditSaccess) ||
                (translator.kyr && kyr.snackBar.messageEditSaccess)
              : MainDeleteStatus === 'success'
              ? (translator.ru && ru.snackBar.messageDeleteSaccess) ||
                (translator.en && en.snackBar.messageDeleteSaccess) ||
                (translator.kyr && kyr.snackBar.messageDeleteSaccess)
              : (translator.ru && ru.snackBar.messageError) ||
                (translator.en && en.snackBar.messageError) ||
                (translator.kyr && en.snackBar.messageError)
          }
          variant={mainAddStatus || mainEditStatus || MainDeleteStatus}
          open={SnackState}
          handleClose={closeSnackBarHandler}
        />
      )}
      {state.main?.map((element) => (
        <DivCard key={element}>
          <Card
            variant='main-page-admin'
            img={element.photo}
            title={element.title}
            description={element.description}
            date={element.date}
            senderText={element.senderText}
            sender={element.sender}
            onDelete={() => deletedHandler(element.id)}
            onEdit={() => navToEditPage(element.id)}
          />
        </DivCard>
      ))}
      <DIVbtn>
        <Btn>
          {state.main?.length >= 6 && (
            <Button variant='button1' onClick={addCount}>
              <TranslatorDescription variant='more' />
            </Button>
          )}
          {state.main?.length <= 0 && (
            <StyledDivText>
              <DataNotFoundTranslator variant='' />
            </StyledDivText>
          )}
          <Button variant='button1' onClick={navToAddPage}>
            <TranslatorDescription variant='add' />
          </Button>
        </Btn>
      </DIVbtn>
    </CardDiv>
  )
}

export default AdminMainPage

const DivCard = styled.div`
  margin-left: 28px;
  margin-top: 25px;
  @media (max-width: 912px) {
    margin-left: 5px;
  }
  @media (max-width: 820px) {
    margin-left: 28px;
  }
  @media (max-width: 770px) {
    margin-left: 10px;
  }
  @media (max-width: 550px) {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
  }
`
const CardDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
`
const DIVbtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 25px 20px;
`
const Btn = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 150px;
  @media (max-width: 415px) {
    gap: 50px;
  }
`
const StyledDivText = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
